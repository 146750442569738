<template>
  <div>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center">
        <div class="vx-card sm:p-4 p-2">
          <!-- 資料載入中 -->
          <h5 v-if="loadingFlag" class="my-2 text-center">{{$t('loading')}}</h5>
          <!-- 資料 -->
          <!-- {{lifeHealthData[2]}} -->
          <vs-tabs class="tabs-shadow-none" v-if="!loadingFlag && tabs">
            <vs-tab class="flex" :label="geti18n(items.i18n)" v-for="(items,index) in tabs" :key="index">
              <!-- 螢幕寬度大於等於768px -->
              <vs-list v-if="windowWidth>=768">
                <vs-list-item :title="item.cn" :subtitle="item.name" v-for="(item,i) in lifeHealthData[index]" :key="i">
                  <vs-row>
                    <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
                      <p class="cd-date">{{ (item.time=='') ? '' : item.time }}</p>
                      <p class="ml-2" :style="{color: (item.color=='true') ? 'red': ''}">{{ (item.value=='' || item.value==null) ? '-' : item.value }}</p>
                      <vs-button class="ml-2" icon="show_chart" :disabled="item.value==''" @click="viewCurChart(item)"></vs-button>
                      <vs-button class="ml-2" icon="add" @click="addPersonalSign(item.type)" v-if="items.plus"></vs-button>
                    </vs-col>
                  </vs-row>
                </vs-list-item>
                <!-- fake start -->
                <!-- <vs-list-item :title="item.cn" :subtitle="item.name" v-for="(item,i) in fake" :key="i">
                  <vs-row>
                    <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
                      <p class="cd-date">{{ (item.time=='') ? '' : item.time }}</p>
                      <p class="ml-2" :style="{color: (item.color=='true') ? 'red': ''}">{{ (item.value=='' || item.value==null) ? '-' : item.value }}</p>
                      <vs-button class="ml-2" icon="show_chart" :disabled="item.value==''" @click="viewCurChart(item.type)"></vs-button>
                      <vs-button class="ml-2" icon="add" @click="addPersonalSign(item.type)" v-if="items.plus"></vs-button>
                    </vs-col>
                  </vs-row>
                </vs-list-item> -->
                <!-- fake end -->
              </vs-list>
              <!-- 螢幕寬度小於768px -->
              <vs-list v-else-if="windowWidth<768">
                <vs-list-item class="cd-list-item-sm w-full" :title="item.cn" :subtitle="item.name" v-for="(item,i) in lifeHealthData[index]" :key="i">
                  <vs-row>
                    <vs-col class="mb-2" vs-type="flex" vs-justify="flex-end" vs-align="center">
                      <p :style="{color: (item.color=='true') ? 'red': ''}">{{ (item.value=='' || item.value==null) ? '-' : item.value }}</p>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="space-between" vs-align="center">
                      <p class="cd-date">{{ (item.time=='') ? '' : item.time }}</p>
                      <div class="flex">
                        <vs-button class="ml-2" icon="show_chart" :disabled="item.value==''" @click="viewCurChart(item)"></vs-button>
                        <vs-button class="ml-2" icon="add" @click="addPersonalSign(item.type)" v-if="items.plus"></vs-button>
                      </div>
                    </vs-col>
                  </vs-row>
                </vs-list-item>
              </vs-list>
              <!-- 沒資料 -->
              <h2 v-else class="mt-5 flex center">{{$t('nodata')}}</h2>
            </vs-tab>
          </vs-tabs>
          <!-- 圖表 視窗 -->
          <el-dialog
            :title="chartTitle"
            :visible.sync="popupActivChart"
            width="75%"
            append-to-body>
            <vs-row>
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <div class="w-full" style=" padding-top: 1em">
                  <apexchart type="area" height="400vh" ref="lineChart" :options="chartOptions" :series="series"></apexchart>
                </div>
              </vs-col>
              <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                <VuePerfectScrollbar class="w-full" style="height: 400px; text-align:center; padding-top: 1em;" :settings="settings">
                  <div class="flex whitespace-no-wrap">
                    <div class="mb-2 w-24">
                      <span>數值</span>
                    </div>
                    <div class="mb-2 w-48">
                      <span>量測時間</span>
                    </div>
                  </div>
                  <div class="flex whitespace-no-wrap" v-for="(item,index) in tableData" :key="index">
                    <div class="w-24 truncate" :class="item.color == true ? 'text-danger' : ''" style="font-size:1rem;">
                      <span>{{ item.value }}</span>
                    </div>
                    <div class="w-48 truncate" >
                      <span>{{ item.time }}</span>
                    </div>
                  </div>
                </VuePerfectScrollbar>
              </vs-col>
            </vs-row>
          </el-dialog>
        </div>
      </vs-col>
    </vs-row>
    <!-- 新增數據 視窗 -->
    <vs-popup classContent="popup-example" :title="$t('AddData')" :active.sync="popupAddSignDig">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <vs-input class="inputx" placeholder="" v-model="signValue"/>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <vs-button @click="updateSign" color="primary" type="filled">{{$t('AddBtn')}}</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { saveSignByType,updateHealthdataFromEmr } from '@/api/user'
import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
export default {
  components: {
    apexchart: VueApexCharts,
    VuePerfectScrollbar,
  },
  props: {
    userPid: {
      type: String,
      required: true
    },
    loadingFlag: {
      type: Boolean,
      required: true
    },
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth
    },
    partnerid() {
        return this.$store.getters.partner
    },
    lifeHealthData: {
      get() {
        return this.$store.getters.lifeHealthData
      },
      set(val) {
        this.$store.commit("SET_LIFE_HEALTH_DATA", val);
      },
    },
    tabs() {
      return JSON.parse(localStorage.getItem('his_config')).emr.life.contents
    },
    lang() {
      return this.$i18n.locale
    },
  },
  data() {
    return {
      selectType:'',
      signValue: 0,
      popupAddSignDig: false,
      popupActivChart: false,
      series: [{
        name: this.$t('Height'),
        data: [94, 90, 91, 135, 133, 95]
      }],
      chartOptions: {
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        // colors: this.themeColors,
        yaxis: {
          forceNiceScale: true
        },
        xaxis: {
          type: 'datetime',
          categories: ["2020-01-06", "2020-03-26", "2020-06-29", "2020-08-04", "2020-08-06", "2020-11-27"],
        },
        tooltip: {
          x: {
            format: 'yyyy-MM-dd'
          },
        },
        // 每個點的設定
        // markers: {
        //   size: 2, // 設置點的大小
        //   // strokeColors: '#36bf36', // 設置點的邊框顏色，此例中設置為白色
        //   strokeWidth: 0.5, // 設置點的邊框寬度
        //   fillOpacity: 1, // 設置點的填充透明度為 0，即空心點
        //   hover: {
        //     size: 2 // 設置當滑鼠懸停在點上時的大小
        //   }
        // },
        // line 線
        stroke: {
          show: true,
          colors: ['#003f8e'],
          width:1
        },
        fill: {
          // colors: ['#003f8e'], // 這將設定線條下方區域的顏色
          type: 'gradient', // 這將設定填充類型，可以是 'solid'、'gradient' 等
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
            colorStops: [
              {
                offset: 0,
                color: '#003f8e', // 开始颜色 (藍色)
                opacity: 1
              },
              {
                offset: 100,
                color: '#889db8', // 结束颜色 (灰色)
                opacity: 1
              }
            ]
          }
        }
      },
      chartTitle: '',
      tableData: [],
      settings : {
        maxScrollbarLength : 60,
        wheelSpeed         : 0.30,
      },
    }
  },
  watch: {
    popupActivChart(v){
      if(v==false){
        this.series[0].data = []
        this.chartOptions = {
          xaxis: {
            categories : ["2020-01-06", "2020-03-26", "2020-06-29", "2020-08-04", "2020-08-06", "2020-11-27"],
          }
        }
      }
    }
  },
  methods: {
    // 新增數據 修改寫health data
    // saveSign(){
    //   var _self = this
    //   if(_self.signValue==0){
    //     _self.AddFailedNotify()
    //     return false
    //   }
    //   saveSignByType({ spid: _self.userPid, type: _self.selectType, value: _self.signValue }).then((res) => {
    //     if(res.data.status=='OK'){
    //       _self.addSuccessNotify()
    //       var array = _.cloneDeep(_self.lifeHealthData)
    //       array.forEach(function(v){
    //         if(_.find(v, ['type', _self.selectType])){
    //           _.set(_.find(v, ['type', _self.selectType]), 'value', _self.signValue)
    //           _.set(_.find(v, ['type', _self.selectType]), 'time', _self.getTime())
    //         }
    //       })
    //       _self.lifeHealthData = array
    //     }else{
    //       _self.AddFailedNotify()
    //     }
    //   }).then(() => {
    //     _self.selectType = ""
    //     _self.signValue = 0
    //     _self.popupAddSignDig=false
    //   })
    // },
    // updateHealthdataFromEmr 新增數據 修改寫health data
    updateSign(){
      var _self = this
      if(_self.signValue==0){
        _self.AddFailedNotify()
        return false
      }
      updateHealthdataFromEmr({ spid: _self.userPid, type: _self.selectType, value: _self.signValue, partner_id:_self.partnerid }).then((res) => {
        if(res.data.status=='OK'){
          _self.addSuccessNotify()
          var array = _.cloneDeep(_self.lifeHealthData)
          array.forEach(function(v){
            if(_.find(v, ['type', _self.selectType])){
              _.set(_.find(v, ['type', _self.selectType]), 'value', _self.signValue)
              _.set(_.find(v, ['type', _self.selectType]), 'time', _self.getTime())
            }
          })
          _self.lifeHealthData = array
        }else{
          _self.AddFailedNotify()
        }
      }).then(() => {
        _self.selectType = ""
        _self.signValue = 0
        _self.popupAddSignDig=false
      })
    },
    // 資料新增成功提醒
    addSuccessNotify(){
      this.$vs.notify({
        color: 'grey',
        // title: this.$t('AddSuccess'),
        text: this.$t('AddSuccess'),
        position:'top-center'
      })
    },
    // 資料新增失敗提醒
    AddFailedNotify(){
      this.$vs.notify({
        color: 'danger',
        title: this.$t('AddFailed'),
        text: this.$t('EnterCorrectValue'),
        position:'top-center'
      })
    },
    // 開啟 新增數據 視窗
    addPersonalSign(type){
      this.selectType = type
      this.popupAddSignDig = true
    },
    // 開啟 圖表 視窗
    viewCurChart(item){
      console.log("item",item)
      this.chartTitle = this.lang == 'en' ? item.name : item.cn;
      this.series[0].name = item.type
      this.updateCurChart(item.type)
    },
    // 產生圖表
    updateCurChart(type){
      var _self = this
      var data = null
      var temp_s_time = ''
      _self.$vs.loading()
      // 心律 或 焦慮
      if(type=='pulse_rate' || type=='fatigue'){
        temp_s_time = this.$moment(new Date(), 'YYYY-MM-DD').add(-6, 'month').format('YYYY-MM-DD')
      }else{
        temp_s_time = this.$moment(new Date(), 'YYYY-MM-DD').add(-1, 'y').format('YYYY-MM-DD')
      }
      data = {
        "spid": _self.userPid,
        "type": type,
        "s_time": temp_s_time,
        "e_time": this.$moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
      }
      axios.post('/api/personalsignlist', data).then(res => {
        if(res.data.status=='OK'){
          var rsdata = res.data.list
          _self.tableData = rsdata
          var newData = rsdata.map((item) => {
            return Math.floor(item.value)
          })
          var newOption = rsdata.map((item) => {
            return item.time
          })
          var rNewData = newData.reverse()
          var rOption = newOption.reverse()
          _self.$nextTick(function(){
            setTimeout(() => {
              var maxValue = Math.max(...rNewData.flat());
              var minValue = Math.min(...rNewData.flat());
              _self.chartOptions = {
                xaxis: {
                  categories : rOption
                },
                yaxis: {
                  min: minValue*0.9,
                  max: maxValue*1.1
                }
              }
              _self.series[0].data = rNewData
            }, 500)
          })
        }else{
          _self.$vs.dialog({
              color: 'danger',
              title: _self.$t('WrongData'),
              text: _self.$t('DataErrCannotDraw'),
              accept:_self.acceptAlert
          })
        }
      }).catch((e) => {
        console.log('personal 2 error ', e)
        _self.$vs.loading.close()
      }).then(() => {
        _self.popupActivChart = true
        _self.$vs.loading.close()
      })
    },
    // 取現在時間
    getTime(){
      var dt = new Date();
      return dt.getFullYear()+'-'+(dt.getMonth()+1)+'-'+dt.getDate()+' '+dt.getHours()+':'+dt.getMinutes()+':'+dt.getSeconds()
    },
    // 判斷語系
    geti18n(items){
      if(this.$i18n.locale == 'tw'){
        return items.tw
      }else if(this.$i18n.locale == 'cn'){
        return items.cn
      }else if(this.$i18n.locale == 'en'){
        return items.en
      }
    }
  },
}
</script>
