<template>
  <vs-popup fullscreen class="text-center" :title="title" :active.sync="popupPdfCommon" id="generalpopup" style="overflow:hidden">
      <vs-row>
        <!-- 戴克 -->
        <vs-col   style="padding:1em">
          <vs-row v-if="analysis">
            <vs-col style="max-width: 30rem;padding: 1em 2em;">
              <h4 style="text-align: left;">{{ "1.心臟功能評估" }}</h4>
              <div  v-for="(item,index) in analysis[1]" :key="index">
                    <div class="w-72 cd-list-item-flex" style="justify-content: space-between">
                        <span v-if="item.text">{{ item.text}}</span>
                        <span v-if="item.value">{{ item.value }}</span>
                    </div>
              </div>
            </vs-col>
            <vs-col style="max-width: 30rem;padding: 1em 2em;">
              <h4  style="text-align: left;">{{ "2.風險評估" }}</h4>
              <div  v-for="(item,index) in analysis[2]" :key="index">
                    <div class="w-72 cd-list-item-flex" style="justify-content: space-between">
                        <span v-if="item.text">{{ item.text}}</span>
                        <span v-if="item.value">{{ item.value }}</span>
                    </div>
              </div>
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col v-if="!showCht" v-loading="loading">
          <!-- pdf -->
          <iframe :src="pdfurl" width="100%" style="overflow:hidden;height:100vh;width:100%" frameborder="0" id="pdfframe" ref="iframe"></iframe>
        </vs-col>
        <!-- 心電圖 -->
        <vs-col v-else vs-lg="6" vs-sm="12" vs-xs="12">
          <line-chart :chart-data="ECGData" :time="date" :spid="selectpid"></line-chart>
        </vs-col>
      </vs-row>
  </vs-popup>
</template>
<script>
import { medicalFileSync } from '@/api/user'
import LineChart from '@/api/LineChart.js'
export default {
    components: {
      LineChart
    },
    props: ['generaltemplatebutton'],
    watch: {
      generaltemplatebutton(n,o){
        console.log(n,'0000000')
        this.selectpid = Number(n.spid)
        this.title = n.title
        this.date =n.date
        this.analysis= n.analysis

        if(n.subtitle==""||n.subtitle==null){
          this.subtitle ="--"
        }else{
          this.subtitle = n.subtitle
        }
        if(n.cht==true){
          this.showCht = true
          this.popupPdfCommon = n.status
        
        // }else if(n.type=='application/pdf'){
        //   var _self = this
        //   const blob = new Blob([new Uint8Array([...n.pdfurl].map((char) => char.charCodeAt(0)))], {
        //     type: n.type,
        //   });
        //   // 创建一个Blob URL
        //   this.pdfurl = URL.createObjectURL(blob);
        //   this.pdfurl=this.pdfurl.substring(5, this.pdfurl.length)
        //   this.pdfurl = encodeURIComponent(this.pdfurl)
        //   this.pdfurl = 'https://patient.curdoctor.com.tw/PDFviewer/web/viewer.html?file'+this.pdfurl+'&embedded=true'
        //   _self.popupPdfCommon = n.status
          }else{
            //判斷pdf是否為大陸與台灣伺服器
            if(n.pdfurl.indexOf('.PDF')>-1||n.pdfurl.indexOf('.pdf')>-1){
              var url = n.pdfurl
              var url_cn = 'https://www.curdoctor.cn/upload/report/'+n.pdfurl+'&embedded=true'
              var request = new XMLHttpRequest();
              var _self = this
              request.open('GET', url, true);
              request.onreadystatechange = function(){
                if (request.readyState === 4){
                  if(request.status==200){
                    _self.pdfurl = url
                    url = encodeURIComponent(url)
                    _self.pdfurl = 'https://patient.curdoctor.com.tw/PDFviewer/web/viewer.html?file='+url+'&embedded=true'
                  }else{
                    _self.pdfurl = url_cn
                  }
                  _self.popupPdfCommon = n.status
                }
              };
              request.send();
            }else if(n.ext =="JPG"||n.ext=="jpg"){
              var _self = this
              _self.pdfurl = n.pdfurl
              _self.popupPdfCommon = n.status
            }else{
              var _self = this
              _self.pdfurl = 'https://patient.curdoctor.com.tw/PDFviewer/web/viewer.html?file='+n.url+'&embedded=true'
              _self.popupPdfCommon = n.status
            }
          }
      },
      // 醫療影像同步 關閉視窗
      syncTriggle(v) {
        if(v=='closedialog'){
          this.popupPdfCommon = false
        }
      },
      // 開關 popup視窗
      popupPdfCommon(v){
        if(v==false){
          this.popupPdfCommon = false
          this.showCht = false
          if(this.syncSwitch==true){
            medicalFileSync({qid: this.qid, trigger: 'closedialog'})
          }
        }else{
          this.showCht == true ? this.layoutSetting = 12 : this.layoutSetting = 6
          setTimeout(() => {
            // var popupid = document.getElementById("generalpopup");
            var iframe = document.getElementById("pdfframe");
            // if (iframeid && popupid){
            //   console.log(popupid.clientHeight)
            //   iframeid.height = popupid.clientHeight-190
            // }
            // iframe.style.height = screenHeight + 'px';
				  }, 100)
        }
      },
    },
    data(){
      return{
        selectpid: 0,
        title:null,
        date:null,
        subtitle:null,
        pdfurl:null,
        popupPdfCommon: false,
        showCht: false,
        ECGData:  {
          labels: [],
          datasets: [{
            data: [],
            fill: false,
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 2,
            pointStyle:'line'
          }],
        },
        layoutSetting: '',
        loading: true,
        analysis:[]
      }
    },
    mounted(){
      const { iframe } = this.$refs;
      const that = this;
      if(iframe.attachEvent){
        // IE
        iframe.attachEvent('onload', () => {
          that.stateChange();
        })
      }else{
        // 非IE
        iframe.onload = function(){
          that.stateChange();
        }
      }
    },
    methods: {
      stateChange(){
        this.loading = false;
      },
    },
    computed: {
      syncSwitch() {
        return this.$store.getters.syncSwitch
      },
      syncTriggle(){
        return this.$store.getters.syncTriggle
      },
      windowWidth() {
        return this.$store.state.windowWidth
      },
      device() {
        return this.$store.getters.device
      },
      qid: {
        get() {
          return this.$store.getters.qid
        },
        set(val) {
          this.$store.commit('SET_QUEUE_ID', val)
        }
      },
    },
}
</script>
