<template>
    <div>
      <!-- 健檢報告同步 -->
      <div v-if="calling && qid!=0">
        <label class="con-s mr-3">{{$t('healthReportSync')}}</label>
        <vs-switch v-model="syncSwitch" @click="switchsync"/>
      </div>
      <vs-tabs>
        <div v-for="(item,index) in config" :key="index">
          <!-- <vs-tab :label="geti18n(item[1].i18n)" :id="item[0]" :name="item[0]" >
            <HRV :userPid="userPid" ></HRV>
          </vs-tab> -->
          <vs-tab :label="geti18n(item[1].i18n)" :id="item[0]" :name="item[0]" @click="syncTriggleF(item[0])" v-if="tabAuth(item[1].role) && item[1].show">
            <general :userPid="userPid" :tabName="item[0]" :i18n="geti18n(item[1].i18n)" v-if="item[1].type=='HRV'||item[1].type=='pdf'"></general>
          </vs-tab>
        </div>
      </vs-tabs>
    </div>
  </template>
  
  <script>
  // import HRV from './HRV.vue'
  import { medicalFileSync } from '@/api/user'
  import General from './apps/emrcomponents/general.vue'
  import 'chartjs-plugin-zoom'
  export default {
    components: {
      General,
      // HRV
    },
    props: {
      userPid: {
        type: String,
        required: true
      },
    },
    watch: {
      // 醫療影像同步
      syncTriggle(v) {
        if(v !='healthReport' && v !='closeimg' && v !='closedicom' && v !='closedialog' && v !='closevideo' && v !='closequestion' && v.substring(0,10)!='pageChange'){
          // 設立旗子以防止收到socket後，無限迴圈執行程式(開關圖檔視窗時不執行)
          if( v.substring(0,8)  !='userimg_' &&
              v.substring(0,7)  !='report_' &&
              v.substring(0,13) !='viewDicomBtn_' &&
              v.substring(0,20) !='ectrocarDiogram_' &&
              v.substring(0,6)  !='video_' &&
              v.substring(0,9)  !='question_'){
            this.socketFlag = 1
            console.log('syncTriggle flag 1',v)
          }
            console.log('syncTriggle flag 0',v)
          document.getElementById(v).click();
        }
      }
    },
    computed: {
      doctortype() {
              return this.$store.state.doctortype
          },
      device() {
        return this.$store.getters.device
      },
      roomid(){
        return this.$store.getters.roomid
      },
      windowWidth() {
        return this.$store.state.windowWidth
      },
      calling() {
        return this.$store.getters.calling
      },
      qid: {
        get() {
          return this.$store.getters.qid
        },
        set(val) {
          this.$store.commit('SET_QUEUE_ID', val)
        }
      },
      config() {
        // 第一層tabs
        let data = JSON.parse(localStorage.getItem('his_config')).emr.healthReport.contents
        _.pull(data, _.find(data, { 'show': false }))
        let map = new Map()
        for(let k in data){
          map.set(k, data[k])
        }
        let ArrayObj = Array.from(map)
        ArrayObj.sort((a,b) => {
          return a[1]["order"] - b[1]["order"]
        })
        return ArrayObj
      },
      syncSwitch: {
        get() {
            return this.$store.getters.syncSwitch
          },
        set(val) {
          if(this.roomid!=0){
            // this.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'dicomData'})
            this.$store.commit('SET_SYNC_SWITCH', val)
            this.$store.commit('SET_SYNC_STATUS', val)
          }
        }
      },
      syncTriggle: {
        get() {
          return this.$store.getters.syncTriggle
        },
        set(val) {
          this.$store.commit('SET_SYNC_TRIGGLE', val)
        }
      },
    },
    data() {
      return {
      }
    },
    methods: {
      // 判斷語系
      geti18n(items){
        if(this.$i18n.locale == 'tw'){
          return items.tw
        }else if(this.$i18n.locale == 'cn'){
          return items.cn
        }else if(this.$i18n.locale == 'en'){
          return items.en
        }
      },
      // 醫療影像同步 switch trigger
      switchsync() {
        if(this.roomid!=0){
          console.log("發送socket，開啟同步")
          this.$store.commit('SET_SYNC_SWITCH', this.syncSwitch)
          // this.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'dicomData'})
          medicalFileSync({qid: this.qid, trigger: 'healthReport'})
        }else{
          this.$vs.dialog({
            color: 'danger',
            title: this.$t('NoConnection'),
            text: this.$t('ConnectFirst')
          })
        }
      },
      // 醫療影像同步 點擊每個tab傳送socket
      syncTriggleF(v) {
        console.log("發送socket，點擊頁籤",v)
        var _self = this
        _self.$store.commit('SET_SYNC_SELECTEDTAB', v)
        // 醫療影像
        if(v=='healthReport'){
          document.getElementById(v).click();
        }
        // 點所有tab都會推socket
        if(_self.syncSwitch==true && this.socketFlag==0){
          // _self.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: v})
          medicalFileSync({qid: this.qid, trigger: v})
        }else{
          this.socketFlag = 0
        }
      },
      // 判斷醫療影像各頁籤的權限
      tabAuth(role){
        var _self = this
        console.log('tabauth',_self.doctortype)
        if(role=="all"){ return true }
        else{
          let temp = _self.doctortype
          let rs = false
          role.forEach(function(v){
            if(v==temp){ rs = true }
          })
          return rs
        }
      },
    },
  }
  </script>
  