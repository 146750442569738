<template>
  <div>
    <!-- 一般影片 -->
    <vs-row>
      <vs-col v-for="(item, index) in datalist" :key="index" vs-lg="4" vs-sm="6" vs-xs="12">
        <div class="cd-card-group text-center">
          <vs-card>
            <vs-row class="cd-card">
              <vs-col vs-type="flex" vs-justify="flex-end">
                <p>{{ item.createdata }}</p>
              </vs-col>
              <vs-divider />
              <vs-col>
                <img
                  class="cursor-pointer"
                  style="margin: auto;"
                  :style="windowWidth <= 375 ? 'width: '+(windowWidth-155)+'px; height: '+(windowWidth-225)+'px;' : 'width: 220px; height: 150px;'"
                  src="@/assets/images/video_play.png"
                  :id="'video_'+index"
                  @click="showimg(item.imagefile, index)" />
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" style="overflow: hidden;">
                <p class="cd-p-normal">{{ item.remark | remark }}</p>
              </vs-col>
              <vs-divider />
              <vs-col vs-type="flex" vs-justify="space-around">
                <vs-button size="small" @click="consultdoctor(item.id)">{{$t('ConsultBtn')}}</vs-button>
              </vs-col>
            </vs-row>
          </vs-card>
        </div>
      </vs-col>
    </vs-row>
    <!-- 沒資料 -->
    <div class="cd-nodata" v-show="videoempty==true">
      {{$t('nodata')}}
    </div>
    <!-- 分頁 -->
    <vs-row v-show="datalist.length!=0">
      <vs-col vs-type="flex" vs-justify="center">
        <vs-pagination :max="windowWidth<768?5:7" :total="Math.ceil(this.getvideototal/12)" v-model="videopage" @change="getVideoPage(videopage)"></vs-pagination>
      </vs-col>
    </vs-row>
    <!-- 影片播放 -->
    <el-dialog
      :title="$t('videoplay')"
      @closed="handleDialogClose"
      :visible.sync="dialogVideoVisible"
      :width="windowWidth< 768 ? '90%' :'42%'"
      append-to-body>
      <video width="100%" height="350px" controls="controls" autoplay="autoplay" :src="videofile" />
    </el-dialog>
    <!-- 邀請會診醫師 視窗 -->
    <consult-doctor :consultdoctorbutton="consultdoctorbutton"></consult-doctor>
  </div>
</template>

<script>
import { medicalFileSync } from '@/api/user'
import ConsultDoctor from './Consultdoctor.vue'
export default {
  components: {
    ConsultDoctor
  },
  props: {
    userPid: {
      type: String,
      required: true
    },
    consulttype:{
      type: Number,
      required: true
    }
  },
  watch:{
    // 醫療影像同步 關閉視窗
    syncTriggle(v) {
      if(v=='closevideo'){
        this.dialogVideoVisible = false
      }
      else if(v.substring(0,10)=='pageChange'){
        var stringValue = v.split('_')
        // 一般影片
        if(stringValue[1]=='uservideo'){
          try{
            var intValue = parseInt(stringValue[2])
          }catch(e){
            console.log(e)
          }
          this.getVideoPage(intValue,1)
        }
      }
    },
    videopage(v) {
      this.getVideoList(11)
    }
  },
  computed: {
    syncSwitch(){
      return this.$store.getters.syncSwitch
    },
    syncTriggle(){
      return this.$store.getters.syncTriggle
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
    qid: {
      get() {
        return this.$store.getters.qid
      },
      set(val) {
        this.$store.commit('SET_QUEUE_ID', val)
      }
    },
  },
  mounted() {
    var _self = this
    _self.getVideoList(11)
  },
  data() {
    return {
      videofile: '',
      dialogVideoVisible: false,
      datalist: [],
      videoempty: false,
      videopage:1,
      getvideototal:null,
      consultdoctorbutton: null,
    }
  },
  filters:{
    //備註說明
    remark(remark){
      if(remark==""||remark==null){
        return "--"
      }
      else{
        return remark
      }
    }
  },
  methods: {
    // 換頁 (設立旗子以防止收到socket後，無限迴圈執行程式)
    getVideoPage(page = 1, flag = 0) {
      var _self = this
      // 醫療影像同步
      if(_self.syncSwitch==true && flag == 0){
        medicalFileSync({qid: this.qid, trigger: 'pageChange_uservideo_'+page})
        // _self.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'pageChange_uservideo_'+page})
      }
      _self.videopage = page
      window.scrollTo(0,0)
    },
    // 關閉影片撥放視窗
    handleDialogClose(){
      var _self = this
      _self.dialogVideoVisible = false
      // 醫療影像同步
      if(_self.syncSwitch==true){
        medicalFileSync({qid: this.qid, trigger: 'closevideo'})
        // _self.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'closevideo'})
      }
    },
    // 開啟影片
    showimg(msg, index) {
      var _self = this
      _self.dialogVideoVisible = true
      _self.videofile = process.env.VUE_APP_UPLOAD+'/video/'+msg
      // 醫療影像同步
      if(this.syncSwitch==true){
        medicalFileSync({qid: this.qid, trigger: 'video_'+index})
        // _self.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'video_'+index})
      }
    },
    // 拿取影片list
    getVideoList(type){
      var _self = this
      let payload = {
        'spid': _self.userPid,
        'type': type,
        'page': _self.videopage
      }
      if(type!=11){
        console.log('video no data')
        return
      }
      _self.$store.dispatch('fetchEmrImage', payload).then((res) => {
        if(res.data.status!='OK') return
        _self.datalist = res.data.items
        _self.getvideototal = res.data.total
        if(_self.datalist.length==0){
          _self.videoempty = true
        }
      })
    },
    // 開啟會診醫生視窗
    consultdoctor(typeid){
      let data = {
        tabName: "video",
        typeid: typeid,
        spid: this.userPid,
        status: true
      }
      console.log("data", data)
      this.consultdoctorbutton = data
    },
  },
}
</script>