<template>
  <vs-popup title="身心指數報告" :active.sync="popupcheck" @close="popupcheck=false">
    <vs-row style="margin-bottom: 0;">
      <vs-col style="padding: 0 1rem">
        <vs-card >
          <div style="background-color:#fff;text-align: center;justify-content:space-around; display: flex;padding:0.5em 1em;font-size: 1rem;">
            <div>
              <div>
                <span style="font-weight:bold;">SDNN</span>
              </div>
              <div>
                <span >{{ strJsonHRVMetrics['SDNN'] }}</span>
              </div>
              <span >({{ strJsonHRVMetrics['SDNNRatio'] }})</span>
            </div>
            <div>
              <div>
                <span style="font-weight:bold;">LF</span>
              </div>
              <div>
                <span >{{ strJsonHRVMetrics['LF'] }}</span>
              </div>
              <span >({{ strJsonHRVMetrics['LFRatio'] }})</span>
            </div>
            <div>
              <div>
                <span style="font-weight:bold;">LF%</span>
              </div>
              <div>
                <span >{{ strJsonHRVMetrics['LFPercent'] }}</span>
              </div>
              <span >({{ strJsonHRVMetrics['LFPercentRatio'] }})</span>
            </div>
            <div>
              <div>
                <span style="font-weight:bold;">HF</span>
              </div>
              <div>
                <span >{{ strJsonHRVMetrics['HF'] }}</span>
              </div>
              <span >({{ strJsonHRVMetrics['HFRatio'] }})</span>
            </div>
            <div>
              <div>
                <span style="font-weight:bold;">LF/HF</span>
              </div>
              <div>
                <span >{{ strJsonHRVMetrics['LFHF'] }}</span>
              </div>
              <span >({{ strJsonHRVMetrics['LFHFRatio'] }})</span>
            </div>
            <div>
              <div>
                <span style="font-weight:bold;">心跳</span>
              </div>
              <div>
                <span >{{  strJsonHRVMetrics['HeartRate'] }}</span>
              </div>
            </div>
            <div>
              <div>
                <span style="font-weight:bold;">活力年齡</span>
              </div>
              <div>
                <span >{{ strJsonHRVMetrics['VitalityAge']  }}</span>
              </div>
            </div>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>
    <vs-row v-for="(data,index) in indexResult" :key="index" style="margin-bottom: 0;">
      <vs-col style="padding:0 1rem;">
        <vs-card scrollable style="background-color:#fff;text-align: center;padding:1em 3em;" v-if="data['IndexIsShow']==true" >
          <div style="font-size: 1rem;padding-bottom: 0.5em;">
            <!-- <span class="vs-icons" style="color:orange;font-size:15px;">circle</span> -->
            <span style="padding-left:10px;font-weight:bold;min-width: 20em;">{{ $t('HRV.golden_hrv.'+data['IndexParamTypeID']+'.title') }}</span>
            <span style="padding-left:10px;"> {{ $t('HRV.golden_hrv.'+data['IndexParamTypeID']+'.options.'+data['IndexLevelID']) }}</span>
          </div>
          <div class="colorful-line" style="display:inline-flex" v-if="$t('HRV.golden_hrv.'+data['IndexParamTypeID']+'.graph')==1">
            <div :style="getColorStyle(1,data['IndexParamTypeID'],'#f96d6d',data['IndexLevelID'])"></div>
            <div :style="getColorStyle(2,data['IndexParamTypeID'],'orange',data['IndexLevelID'])"></div>
            <div :style="getColorStyle(3,data['IndexParamTypeID'],'#7dd87d',data['IndexLevelID'])"></div>
            <div :style="getColorStyle(4,data['IndexParamTypeID'],'orange',data['IndexLevelID'])"></div>
            <div :style="getColorStyle(5,data['IndexParamTypeID'],'#f96d6d',data['IndexLevelID'])"></div>
          </div>
          <div class="colorful-line" style="display:inline-flex" v-if="$t('HRV.golden_hrv.'+data['IndexParamTypeID']+'.graph')==2">
            <div :style="getColorStyle(1,data['IndexParamTypeID'],'#7dd87d',data['IndexLevelID'])"></div>
            <div :style="getColorStyle(2,data['IndexParamTypeID'],'#f1b24b',data['IndexLevelID'])"></div>
            <div :style="getColorStyle(3,data['IndexParamTypeID'],'orange',data['IndexLevelID'])"></div>
            <div :style="getColorStyle(4,data['IndexParamTypeID'],'#df9114',data['IndexLevelID'])"></div>
            <div :style="getColorStyle(5,data['IndexParamTypeID'],'#f96d6d',data['IndexLevelID'])"></div>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>
  </vs-popup>
</template>
  
<script>
import { medicalFileSync } from '@/api/user'
  export default {
    components: {
    },
    props: {
      strJsonHRVMetrics: {
        type: Object,
        required: true
      },
      indexResult: {
        type: Array,
        required: true
      }
    },
    data() {
        return {
          popupcheck:false
        }
    },
    created() {
    },
    watch: {
      strJsonHRVMetrics(n,o){
        this.popupcheck = true;
      },
      popupcheck(v){
        if(v==false){
          if(this.syncSwitch==true){
            medicalFileSync({qid: this.qid, trigger: 'closedialog'})
          }
        }
      },
      // 醫療影像同步 關閉視窗
      syncTriggle(v) {
        if(v=='closedialog'){
          this.popupcheck = false
        }
      },
    },
    computed: {
      windowWidth() {
        return this.$store.state.windowWidth
      },
      syncSwitch() {
        return this.$store.getters.syncSwitch
      },
      syncTriggle(){
        return this.$store.getters.syncTriggle
      },
      qid: {
        get() {
          return this.$store.getters.qid
        },
        set(val) {
          this.$store.commit('SET_QUEUE_ID', val)
        }
      },
    },
    methods: {
      getColorStyle(index,column_index,color,key){
        let keyOrder =this.$t('HRV.golden_hrv.'+column_index+'.order')
        const order = keyOrder.indexOf(key) + 1;
        if(index==order)
          return "background-color:"+color+";height: 30px;"
        else
          return "border:1px solid "+color+";background:hsla("+color+",100%,50%,0.7);"
      }
    },
  }
  </script>
  <style>
  .red{
    color:red;
  }
  .orange{
    color:rgb(253, 173, 23);
  }
  .green{
    color:green;
  }
  .colorful-line {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px; /* Adjust the width of the line */
    height: 30px; /* Adjust the height of the line */
    background-color: transparent; /* Set the background color of the container */
  }
  .colorful-line div {
    height: 100%;
    flex: 1;
  }
  </style>
  