<template>
  <div>
    <!-- 健檢報告 -->
    <vs-row>
      <vs-col v-for="(item, index) in reportDatalist" :key="index" vs-lg="4" vs-sm="6" vs-xs="12">
        <div class="cd-card-group text-center">
          <vs-card>
            <vs-row class="cd-card">
              <vs-col class="cd-form-group" vs-type="flex" vs-justify="flex-end">
                <p>{{ item.date }}</p>
              </vs-col>
              <vs-col>
                <p class="cd-p-normal">{{ item.text | note }}</p>
              </vs-col>
              <vs-col v-if="item.name" vs-type="flex" vs-justify="center" style="overflow: hidden;">
                <p class="" @click="openReportPdfmobile(item)">{{ item.name }}</p>
              </vs-col>
              <vs-divider />
              <vs-col vs-type="flex" vs-justify="space-around">
                <vs-button size="small" class="px-8" @click="openReportPdfmobile(item)" :id="tabName+'_'+item.typeid">{{ $t('OpenBtn') }}</vs-button>
                <vs-button size="small" @click="consultdoctor(item)" v-if="tabName!='k2Report'">{{$t('ConsultBtn')}}</vs-button>
              </vs-col>
            </vs-row>
          </vs-card>
        </div>
      </vs-col>
    </vs-row>
    <div class="cd-nodata" v-show="dataloading">
      {{$t('loading')}}
    </div>
    <!-- 沒資料 -->
    <div class="cd-nodata" v-show="reportDatalist.length==0 && !dataloading">
      {{$t('nodata')}}
    </div>
    <!-- 分頁 -->
    <vs-row v-show="reportDatalist.length!=0">
      <vs-col vs-type="flex" vs-justify="center">
        <vs-pagination :max="windowWidth<768?5:7" :total="Math.ceil(this.getreporttotal/12)" v-model="reportpage" @change="getReportPage(reportpage)"></vs-pagination>
      </vs-col>
    </vs-row>
    <!-- 邀請會診醫師 視窗 -->
    <consult-doctor :consultdoctorbutton="consultdoctorbutton"></consult-doctor>
    <!-- 開啟圖片 視窗 -->
    <general-report-template :generaltemplatebutton="generaltemplatebutton"></general-report-template>
  </div>
</template>

<script>
import { medicalFileSync, getK2Report } from '@/api/user'
import ConsultDoctor from './Consultdoctor.vue'
import GeneralReportTemplate from './Generalreporttemplate'
export default {
  components: {
    ConsultDoctor,
    GeneralReportTemplate
  },
  props: {
    userPid: {
      type: String,
      required: true
    },
    tabName: {
      type: String,
      required: true
    },
    i18n: {
      type: String,
      required: true
    },
  },
  watch: {
    // 醫療影像同步
    syncTriggle(v) {
      console.log("醫療影像同步", v)
      if(v.substring(0,10)=='pageChange'){
        var stringValue = v.split('_')
        if(stringValue[1]==this.tabName){
          try{
            var intValue = parseInt(stringValue[2])
          }catch(e){
            console.log(e)
          }
          this.getReportPage(intValue,1)
        }
      }
    },
    reportpage() {
      this.getReport()
    },
  },
  computed: {
    syncSwitch(){
      return this.$store.getters.syncSwitch
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
    syncTriggle: {
      get() {
          return this.$store.getters.syncTriggle
        },
      set(val) {
        this.$store.commit('SET_SYNC_TRIGGLE', val)
      }
    },
    qid: {
      get() {
        return this.$store.getters.qid
      },
      set(val) {
        this.$store.commit('SET_QUEUE_ID', val)
      }
    },
  },
  data() {
    return {
      reportpage:1,
      getreporttotal:null,
      reportDatalist: [],
      consultdoctorbutton:null,
      generaltemplatebutton:null,
      dataloading:false,
      imagefile:''
    }
  },
  filters:{
    //備註說明
    note(note){
      if(note==""||note==null){
        return "--"
      }
      else{
        return note
      }
    }
  },
  mounted() {
    this.getReport()
  },
  methods: {
    // 換頁 (設立旗子以防止收到socket後，無限迴圈執行程式)
    getReportPage(page = 1, flag = 0){
      // 醫療影像同步
      if(this.syncSwitch==true && flag == 0){
        medicalFileSync({qid: this.qid, trigger: 'pageChange_'+this.tabName+'_'+page})
      }
      this.reportpage = page
      window.scrollTo(0,0);
    },
    // 開啟健檢報告pdf
    openReportPdfmobile(item){
      var data
      if(this.syncSwitch==true){
        medicalFileSync({qid: this.qid, trigger: this.tabName+'_'+item.typeid})
      }
      if(this.tabName=="report"){
        data = {
          title:this.i18n,
          subtitle:item.text,
          type :item.minetype,
          date:item.date,
          pdfurl:item.url,
          status:true
        }
      }else if(this.tabName=="ectrocarDiogram"){
        data = {
          ext :item.ext,
          title:this.i18n,
          subtitle:item.text,
          date:item.date,
          pdfurl:item.url,
          status:true,
          spid: item.spid,
          cht: (item.ext=='cha') ? true : false,
          analysis: item.analysis
        }
      }else if(this.tabName=="k2Report"){
        data = {
          title:    this.i18n,
          subtitle: item.text,
          date:     item.date,
          pdfurl:   item.url,
          status:   true,
          spid:     item.spid,
        }
      }
      this.generaltemplatebutton = data
    },
    // 取照片list
    getReport(){
      if(this.tabName=="report"){
        // 健檢報告(PDF)
        var url = process.env.VUE_APP_UPLOAD+'/report/'
        this.getReportList(1,url)
      }else if(this.tabName=="ectrocarDiogram"){
        // 心電圖
        var url = process.env.VUE_APP_UPLOAD+'/electrocardiogram/'
        this.getReportList(5,url)
      }else if(this.tabName=="k2Report"){
        // K2
        getK2Report(this.userPid).then((res) => {
          if(res.data.status == "OK"){
            var _self = this
            res.data.items.forEach((v, i) => {
              _self.reportDatalist.push({
                'spid': _self.userPid,
                'typeid': i,
                'url': v.url,
                'text': "",
                'date': v.createdata
              })
            })
          }
        })
      }
    },
    // 取健檢報告List
    getReportList(type,url){
      if(this.tabName!="k2Report"){
        this.dataloading = true
      }
      var _self = this
      let payload = {
        'spid': _self.userPid,
        'type': type,
        'page': _self.reportpage
      }
      _self.reportDatalist = []
      _self.$store.dispatch('fetchEmrImage', payload).then((res) => {
        var imagepath = res.data.items
        _self.getreporttotal = res.data.total
        imagepath.forEach((element,i) => {
          var slist = []
          // if(element.ext =='pdf' || element.ext=='PDF'){
          //   this.imagefile=atob(element.imagefile)
          // }
          slist.push(element.imagefile)
          _self.reportDatalist.push({
            'minetype':element.minetype,
            'spid': _self.userPid,
            'typeid':element.id,
            'url': element.imagefile,
            'text': element.remark,
            'date': element.createdata,
            'name':element.imagefile_origin
          })
          if(element.ext =='pdf' || element.ext=='PDF'){
            _self.reportDatalist[i].url = url+element.imagefile
            _self.reportDatalist[i].type = element.minetype
            if(element.analysis && type==5 ){
              _self.reportDatalist[i].analysis =  element.analysis
            }
          }else if(type==5 ){
            _self.reportDatalist[i].status = element.status
            _self.reportDatalist[i].ext = element.ext
            _self.reportDatalist[i].url = url+element.imagefile
            _self.reportDatalist[i].type = element.minetype
          }
        });
        this.dataloading = false
      })
    },
    // 開啟會診醫生視窗
    consultdoctor(item){
      let data = {
        tabName: this.tabName,
        typeid: item.typeid,
        spid: this.userPid,
        status: true,
        ext: item.ext
      }
      console.log("data_consultdoctor", data)
      this.consultdoctorbutton = data
    },
  },
}
</script>
