
<template>
  <div>
    <!-- 藥歷查詢表格 -->
    <vs-table 
    class="cd-medicationcard" 
    pagination 
    max-items="12" 
    search 
    v-if="medicationRecord"
    :data="medicationRecord">
      <template slot="header">
        <span>{{ $t('medicationHistory') }}</span>
      </template>
      <template slot="thead">
        <vs-th :sort-key="index==0 ? 'date' : ''" v-for="(data,index) in emrMedicationArray[0].contents" :key="index">{{data.name}}</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr]">
            {{data[indextr].date}}
          </vs-td>
          <vs-td :data="data[indextr]">
            {{data[indextr].item_desc}}
          </vs-td>
          <vs-td :data="data[indextr]">
            {{data[indextr].days}}
          </vs-td>
          <vs-td :data="data[indextr]">
            {{data[indextr].freq}}
          </vs-td>
           <vs-td :data="data[indextr]">
            {{data[indextr].dose}}
          </vs-td>
          <vs-td :data="data[indextr]">
            {{data[indextr].total_dose}}
          </vs-td>
          <vs-td :data="data[indextr]">
            {{data[indextr].route}}
          </vs-td>
          <vs-td :data="data[indextr]">
            {{data[indextr].subject_desc}}
          </vs-td>
          <vs-td :data="data[indextr]">
            {{data[indextr].physician_name}}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>
<script>
import { GetmedicationRecord } from "@/api/user"
import emrMedication from '../json/emrmedication.json' //藥歷設定檔
export default {
  components: {
    emrMedication
  },
  created(){
    // 取藥歷設定檔
    this.$store.commit('SET_EMRMEDICATION', emrMedication)
  },
  data(){
    return {
      test : "T120642752", //測試id
      medicationRecord: [],
    }
  },
  props: {
    // 身分證
    patientAccount:{
      type: String,
      required: true
    },
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth
    },
    emrMedicationArray() {
      return this.$store.getters.emrMedication
    },
  },
  mounted() {
    //載入頁面帶出藥歷資料
    GetmedicationRecord({'citizen_id':this.patientAccount}).then((res) => {
      this.medicationRecord = res.data.items.data
    })
  }
}
</script>
