<template>
    <div>
        <vs-row v-if="reports">
            <vs-col class="cd-form-group" v-for="(data,index) in reports" :key="index">
                <div class="cd-form-group">
                    <span class="cd-p-large">{{ data.name }}</span>
                </div>
                <div class="flex justify-between w-56 cd-form-group" v-for="(items,index) in data.reports" :key="index">
                    <div>
                        <span class="mr-2">●</span>
                        <span class="text-primary cd-p-pointer" style="white-space: nowrap;" @click="openIframe(items.link, data.name)">{{ items.title }}</span>
                    </div>
                    <div>
                        <span style="white-space: nowrap;">{{ items.date }}</span>
                    </div>
                </div>
            </vs-col>
        </vs-row>
        <vs-row v-else>
            <vs-col vs-type="flex" vs-justify="center">
                <span class=" mt-8 cd-p-large">查無資料</span>
            </vs-col>
        </vs-row>
        <vs-popup fullscreen :title="popupTitle" :active.sync="popupIframe">
            <iframe :src="link" @close="closeIframe" frameborder="0" :style="'overflow:hidden;width:100%;height:'+(windowHeight-140)+'px;'" />
        </vs-popup>
    </div>
</template>
<script>
export default {
    props: {
        reports: {
            type: Array,
            required: false
        },
    },
    computed: {
        windowHeight() {
            return window.innerHeight
        },
    },
    data() {
        return {
            popupIframe: false,
            link: "",
            popupTitle: "",
        }
    },
    methods: {
        openIframe(link, name){
            this.link = link
            this.popupTitle = name
            this.popupIframe = true
        },
        closeIframe(){
            this.link = ""
            this.popupTitle = ""
            this.popupIframe = false
        }
    }
}
</script>
