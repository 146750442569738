<template>
  <div>
    <history-edit-sidebar :isSidebarActive="historyEditSidebar" @closeSidebar="toggleDataSidebar" :disease_type="this.disease_type" :type="selectType" :userPid="this.userPid" />
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" :vs-w="'12'">
        <vx-card class="p-2" v-if="userBaseData">
          <!-- 會員資料 -->
          <vs-col class="flex-wrap" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12" vs-xs="12">
            <vs-button class="cd-button-2w" icon="icon-file-plus" icon-pack="feather" type="border" @click="openNewCasePopup" style="white-space:nowrap">{{$t('crm.addCase')}}</vs-button>
          </vs-col>
          <vs-row>
            <vs-col class="flex-wrap cd-form-group" vs-type="flex" vs-align="center">
              <!-- 頭貼 -->
              <div>
                <vs-avatar src="" size="70px" class="mr-4" />
              </div>
              <!-- 資料 -->
              <div>
                <p>{{ partnerid==32 ? $t('SciNo') : $t('ID') }}：{{ userBaseData.id>''? userBaseData.id:(userBaseData.account_type==0||userBaseData.account_type==1?userBaseData.account:'') }}</p>
                <p>{{ $t('AccountNoTxt') }}：{{ userBaseData.account }}</p>
                <p>{{$t('CardID')}}：{{userBaseData.insurance_id}}</p>
                <p>{{$t('PName3')}}：{{userBaseData.name}}</p>
                <p>{{$t('Birthday')}}：{{userBaseData.birthday}}</p>
                <div class="flex">
                  <div class="flex" v-if="userBaseData.e_name">
                    <!-- 合作夥伴單位 -->
                    <p class="cd-button-group" v-for="(item, index) in userBaseData.e_name.split(',')" :key="index">
                      <vx-tooltip :text="$t('partnerWhichMemberBelong')">
                        <feather-icon svgClasses="h-3 w-3" icon="HashIcon"></feather-icon>
                        {{item}}
                      </vx-tooltip>
                    </p>
                  </div>
                  <!-- 加入單位 下拉式選單 測試 -->
                  <vs-dropdown class="text-primary cursor-pointer" vs-trigger-click v-if="(doctortype==2 || doctortype==4) && addEnterpriseAuth && enterpriseList.length>0">
                    <p class="cd-button-group">
                      <feather-icon svgClasses="h-3 w-3" icon="PlusIcon"></feather-icon>
                      {{$t('joinEnterprise')}}
                    </p>
                    <vs-dropdown-menu>
                      <vs-dropdown-item v-for="(item, index) in enterpriseList" :key="index" @click="patientAddEnterprise(item.id)">
                        {{item.name}}
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                  <!-- 高級會員 checkbox 測試 -->
                  <vs-checkbox v-model="seniorMemberStatus" v-if="(doctortype==2 || doctortype==4) && seniorMemberAuth" @click="updateSeniorMemberStatus">{{$t('advancedMember')}}</vs-checkbox>
                </div>
              </div>
              <!-- 聯絡資訊按鈕 -->
              <div class="sm:ml-2 sm:mt-0 mt-3">
                <vs-button :size="windowWidth > 976 ? '' :'small'" color="primary" type="flat" icon-pack="feather" icon="icon-info" @click="showUrgentDig=true">{{$t('ContactTxt')}}</vs-button>
              </div>
              <!-- 聯絡資訊 視窗 -->
              <vs-popup class="holamundo" width= "100vw" :title="$t('ContactTxt')" :active.sync="showUrgentDig">
                <vs-divider class="mx-1 mt-0"><h5>{{$t('Profile')}}</h5></vs-divider>
                <p>{{$t('PName3')}}：{{userBaseData.name}}</p>
                <p>{{$t('accountPhone')}}：{{userBaseData.country_code}} {{userBaseData.mobile}}</p>
                <p>{{$t('Email')}}：{{userBaseData.email}}</p>
                <p>{{$t('BankAddressTxt')}}：{{userBaseData.home_address}}</p>
                <vs-divider class="mx-1 mb-1"><h5>{{$t('EmergencyCI')}}</h5></vs-divider>
                <p>{{$t('EmergencyRelation')}}：{{userBaseData.urgent_relationship}}</p>
                <p>{{$t('EmergencyContact')}}：{{userBaseData.urgent_contact}}</p>
                <p>{{$t('EmergencyContactNum')}}：{{userBaseData.urgent_country_code}} {{userBaseData.urgent_phone}}</p>
              </vs-popup>
            </vs-col>
          </vs-row>
          <!-- 病史及敏感事項 -->
          <vs-row>
            <vs-col>
              <div class="block px-2">
                <!-- 個人病史 -->
                <vs-col class="cd-form-group" vs-type="flex" vs-justify="space-between" vs-align="flex-end">
                  <div>
                    <p class="cd-text-muted">{{ $t('personalSick') }}</p>
                    <p>                      {{ userEMRPersonSickData }}</p>
                  </div>
                  <div>
                    <vs-button radius color="dark" type="flat" icon-pack="feather" icon="icon-edit-2" @click="editHistory(1,'person_sick')"></vs-button>
                  </div>
                </vs-col>
                <vs-divider />
                <!-- 家族慢性病史 -->
                <vs-col class="cd-form-group" vs-type="flex" vs-justify="space-between" vs-align="flex-end">
                  <div>
                    <p class="cd-text-muted">{{ $t('famleSick') }}</p>
                    <p>                      {{ userEMRParentsSickData }}</p>
                  </div>
                  <div>
                    <vs-button radius color="dark" type="flat" icon-pack="feather" icon="icon-edit-2" @click="editHistory(2,'parents_sick')"></vs-button>
                  </div>
                </vs-col>
                <vs-divider />
                <!-- 家族癌症病史 -->
                <vs-col class="cd-form-group" vs-type="flex" vs-justify="space-between" vs-align="flex-end">
                  <div>
                    <p class="cd-text-muted">{{ $t('famCacel') }}</p>
                    <p>                      {{ userEMRParentsCancerData }}</p>
                  </div>
                  <div>
                    <vs-button radius color="dark" type="flat" icon-pack="feather" icon="icon-edit-2" @click="editHistory(3,'parents_cancer')"></vs-button>
                  </div>
                </vs-col>
                <vs-divider />
                <!-- 過敏事項 -->
                <vs-col class="cd-form-group" vs-type="flex" vs-justify="space-between" vs-align="flex-end">
                  <div>
                    <p class="cd-text-muted">{{ $t('famCacel2') }}</p>
                    <p>                      {{ userEMRSensitiveMattersData }}</p>
                  </div>
                  <div>
                    <vs-button radius color="dark" type="flat" icon-pack="feather" icon="icon-edit-2" @click="editHistory(4,'sensitive_matters')"></vs-button>
                  </div>
                </vs-col>
                <vs-divider />
              </div>
            </vs-col>
          </vs-row>
          <!-- 新增案件視窗 -->
          <add-case :spid="parseInt(userPid)" :spname="userBaseData.name" :newCasePopupActive="newCaseActive" @fetchData="doNothing" @closePopup="newCaseClose"></add-case>
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import HistoryEditSidebar from '../../emrsidebar/emrhistoryEdit.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import addCase from '@/components/addCase'
import FeatherIcon from '../../../components/FeatherIcon.vue'
import { hisUpdateMemberSeniorMember, hisPatientAddPartnerAndEnterprise, checkEmrBaseAuthThenGetList } from '@/api/user'
export default {
  components: {
    HistoryEditSidebar,
    VuePerfectScrollbar,
    FeatherIcon,
    addCase,
  },
  props: {
    userPid: {
      type: String,
      required: true
    },
  },
  watch: {
    // 解決computed先啟動，mount後啟動問題
    userBaseData(){
      if(this.doctortype==2 || this.doctortype==4){
        this.checkAuthThenGetList()
      }
    }
  },
  computed: {
    // 會員資料
    userBaseData() {
      return this.$store.getters.userEMRBaseData[0]
    },
    // 個人病史
    userEMRPersonSickData() {
      if(this.$i18n.locale=='en'){
        return this.$store.getters.userEMRPersonSickDataE
      }else{
        return this.$store.getters.userEMRPersonSickData
      }
    },
    // 家族慢性病史
    userEMRParentsSickData() {
      if(this.$i18n.locale=='en'){
        return this.$store.getters.userEMRParentsSickDataE
      }else{
        return this.$store.getters.userEMRParentsSickData
      }
    },
    // 家族癌症病史
    userEMRParentsCancerData() {
      if(this.$i18n.locale=='en'){
        return this.$store.getters.userEMRParentsCancerDataE
      }else{
        return this.$store.getters.userEMRParentsCancerData
      }
    },
    // 過敏事項
    userEMRSensitiveMattersData() {
      if(this.$i18n.locale=='en'){
        return this.$store.getters.userEMRSensitiveMattersDataE
      }else{
        return this.$store.getters.userEMRSensitiveMattersData
      }
    },
    // 合作夥伴id
    partnerid() {
      return this.$store.getters.partner
    },
    // 醫事人員類型
    doctortype(){
      return this.$store.state.doctortype
    },
    // 螢幕寬度
    windowWidth() {
      return this.$store.state.windowWidth
    },
    isDev() {
      return this.$store.getters.isDev
    },
  },
  data() {
    return {
      showUrgentDig: false,
      historyEditSidebar: false,
      selectType: 1,
      disease_type:'',
      sidebarEMRData: [],
      addEnterpriseAuth: false,
      seniorMemberAuth: false,
      seniorMemberStatus: false,
      enterpriseList: [],
      newCaseActive: false,
    }
  },
  methods: {
    // sidebar 開關
    toggleDataSidebar(val=false) {
      this.historyEditSidebar = val
    },
    // 更新 病史及敏感事項
    editHistory(type,disease_type) {
      this.selectType = type
      this.disease_type = disease_type
      this.sidebarEMRData = {
        type: 1,
        data: ''
      }
      this.toggleDataSidebar(true)
    },
    // 取 加入單位權限和清單 & 高級會員權限和狀態
    checkAuthThenGetList(){
      checkEmrBaseAuthThenGetList({partnerid: this.partnerid, spid: this.userPid}).then(res => {
        if(res.data.status=="OK"){
          // 高級會員功能的權限
          this.seniorMemberAuth = res.data.data.seniorMemberAuth
          // 加入單位功能的權限
          this.addEnterpriseAuth = res.data.data.addAuth
          // 病人的高級會員狀態
          if(this.seniorMemberAuth==1 && res.data.data.seniorMemberStatus){
            this.seniorMemberStatus = res.data.data.seniorMemberStatus[0].is_senior_member
          }
          // 所有此合作夥伴的單位
          if(res.data.data.enterpriseList){
            this.enterpriseList = res.data.data.enterpriseList
            if(this.userBaseData.e_name){
              this.userBaseData.e_name.split(',').forEach(v => {
                _.pull(this.enterpriseList, _.filter(this.enterpriseList, ['name', v])[0])
              });
            }
          }
        }
      })
    },
    // 修改會員 高級暢打狀態
    updateSeniorMemberStatus() {
      hisUpdateMemberSeniorMember({partner_id: this.partnerid, pid: this.userPid})
    },
    // 加入合作夥伴單位功能
    async patientAddEnterprise(eid) {
      await hisPatientAddPartnerAndEnterprise({partner_id: this.partnerid, eid: eid, pid: this.userPid})
      this.checkAuthThenGetList()
      this.$store.dispatch('fetchEMRDATA', {'userPid': this.userPid, 'partnerid': this.partnerid})
    },
    // 視窗通知
    notify(color, title, text){
      this.$vs.notify({
        color: color,
        title: title,
        text: text,
        position:'top-center'
      })
    },
    // 開啟新增客服案件視窗
    openNewCasePopup(){
      document.body.style.overflow = 'hidden';
      this.newCaseActive = true
    },
    // 關閉新增客服案件視窗
    newCaseClose(){
      document.body.style.overflow = 'visible';
      this.newCaseActive = false
    },
    // 什麼也不做
    doNothing(){
      return
    }
  },
  beforeDestroy() {
    this.historyEditSidebar = false
  }
}
</script>
