<template>
    <div>
        <!-- 視訊邀請  -->
        <vs-button
            :class="propClass"
            :style="getStyle()"
            :type="type"
            :color="color"
            :icon="icon"
            icon-pack="feather"
            :disabled="disabled"
            @click.stop="showInvitePopup">
            {{ $t('reservation.invite') }}
        </vs-button>
        <!-- 提示視窗 - 您將發送會議連結，請病人進入診間。病人進入診間後，您也會收到提醒通知。 -->
        <vs-popup :title="$t('popup.alert')" :active.sync="popupInvite">
            <p class="cd-form-group">{{ $t('reservation.smsText') }}</p>
            <vs-button class="mr-2" type="border" @click="popupInvite=false">{{$t('popup.cancel')}}</vs-button>
            <vs-button class="ml-2" type="filled" @click="sendlink">{{$t('popup.confirm')}}</vs-button>
        </vs-popup>
    </div>
</template>
<script>
import { inviteVideo } from '@/api/user'
export default {
    props: {
        propClass: {
            type: String,
            default: "cd-button-sm-2w-icon",
            required: false
        },
        type: {
            type: String,
            default: "filled",
            required: false
        },
        color: {
            type: String,
            default: "success",
            required: false
        },
        icon: {
            type: String,
            default: "icon-link",
            required: false
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        },
        qid: {
            type: Number,
            required: true
        },
        pid: {
            type: Number,
            required: true
        },
        pmid: {
            type: Number,
            required: true
        },
    },
    data(){
        return {
            popupInvite: false,
        }
    },
    methods: {
        // 簡訊 視窗
        showInvitePopup() {
            this.popupInvite = true
        },
        // 發送簡訊連結
        sendlink(){
            this.$vs.loading()
            let payload = {
                "qid": this.qid,
                "type": 'video',
            }
            // 發送視訊邀請(含通知)
            inviteVideo(payload).then((res) => {
                this.popupInvite = false
                this.notify('success', this.$t('message.invite_success'), '')
                // this.$store.dispatch('updateDoctorStatus', 2000) 20240603發送視訊邀請後，不變更醫生狀態
                this.$vs.loading.close()
            }).catch((e) => {
                console.log('send sms error ', e)
                this.notify('danger', this.$t('message.invite_error'), '')
            })
            // 將該筆掛號加入定期檢查房間狀態的行列
            var mcudata = _.cloneDeep(this.$store.state.mcudata)
            if(!_.find(mcudata,{ 'mid': this.pmid, 'qid': this.qid})){
                mcudata.push({ 'mid': this.pmid, 'qid': this.qid })
            }
            this.$store.commit('SET_MCUDATA', mcudata)
        },
        // 視窗通知
        notify(color, title, text){
            this.$vs.notify({
                color: color,
                title: title,
                text: text,
                time: 8000,
                position:'top-center'
            })
        },
        // 取邊框顏色
        getStyle(){
            if(this.type!="filled") return ''
            if(this.color=='danger'){
                return 'border: 1px #e7441c solid;'
            }else if(this.color=='success'){
                return 'border: 1px #104f2c solid;'
            }else if(this.color=='primary'){
                return 'border: 1px #003f8e solid;'
            }
        },
    }
}
</script>