<template>
  <div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
      <div class="max-w-52" v-for="(item, index) in datalist" :key="index">
        <vs-card>
          <vs-row class="cd-card">
            <vs-col vs-type="flex" vs-justify="flex-end">
              <p>{{ item.date }}</p>
            </vs-col>
            <vs-divider />
            <vs-col>
              <!-- image/* -->
              <el-image v-if="check_ext(item.ext,'img')"
                class="cursor-pointer"
                :style="windowWidth <= 375 ? 'width: '+(windowWidth-155)+'px; height: '+(windowWidth-155)+'px;' : 'width: 210px; height: 220px;'"
                style="display: grid;align-content: center;margin: 0 auto; "
                :id="'userimg_'+item.typeid"
                @click="showfile(item.typeid, item.srcList)"
                :src="file_path(item)"
                :close-on-click-modal="closefile">
              </el-image>
              <!-- pdf -->
              <el-image v-if="check_ext(item.ext,'pdf')||check_ext(item.ext,'cha')"
                class="cursor-pointer"
                :style="windowWidth <= 375 ? 'width: '+(windowWidth-155)+'px; height: '+(windowWidth-155)+'px;' : 'width: 210px; height: 220px;'"
                style="display: grid;align-content: center;margin: 0 auto; "
                :id="'report_'+item.typeid"
                @click.stop="openReportPdfmobile(item)"
                :src="file_path(item)"
                alt="hangup"
                :close-on-click-modal="closefile"
              />
              <!-- HRV GOLDEN -->
              <el-image v-if="check_ext(item.ext,'golden')"
                class="cursor-pointer"
                :style="windowWidth <= 375 ? 'width: '+(windowWidth-155)+'px; height: '+(windowWidth-155)+'px;' : 'width: 210px; height: 220px;'"
                style="display: grid;align-content: center;margin: 0 auto; "
                :id="tabName+'_'+item.typeid"
                @click.stop="openReportHRV(item)"
                :src="file_path(item)"
                alt="hangup"
                :close-on-click-modal="closefile"
              />
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" style="overflow: hidden;">
              <p class="cd-p-normal" v-html="formatText(item.text)" ></p>
            </vs-col>
            <vs-col v-if="item.name" class="text-center break-words">
              <p v-if="check_ext(item.ext,'img')" class=""  @click="showfile(item.typeid, item.srcList)">{{ item.name }}</p>
              <p v-if="check_ext(item.ext,'pdf')||check_ext(item.ext,'cha')" class="" @click="openReportPdfmobile(item)">{{ item.name }}</p>
              <p v-if="check_ext(item.ext,'golden')" class=""  @click="openReportHRV(item)">{{ item.name }}</p>
            </vs-col>
            <vs-divider />
            <vs-col vs-type="flex" vs-justify="space-around">
              <vs-button v-if="check_ext(item.ext,'img')" size="small" class="px-8" @click="showfile(item.typeid, item.srcList)" :id="tabName+'_'+item.typeid">{{ $t('OpenBtn') }}</vs-button>
              <vs-button v-if="check_ext(item.ext,'pdf')||check_ext(item.ext,'cha')" size="small" class="px-8" @click="openReportPdfmobile(item)" :id="tabName+'_'+item.typeid">{{ $t('OpenBtn') }}</vs-button>
              <vs-button size="small" @click="consultdoctor(item.typeid)">{{$t('ConsultBtn')}}</vs-button>
            </vs-col>
          </vs-row>
        </vs-card>
      </div>
    </div>
    <!-- 分頁 -->
    <vs-row v-show="datalist.length!=0">
      <vs-col vs-type="flex" vs-justify="center">
        <vs-pagination :max="windowWidth<768?5:7" :total="Math.ceil(this.getgeneraltotal/12)" v-model="Generalpage" @change="getPage(Generalpage)"></vs-pagination>
      </vs-col>
    </vs-row>
    <!-- 沒資料 -->
    <div class="cd-nodata" v-show="empty==true">
      {{$t('nodata')}}
    </div>
    <!-- 邀請會診醫師 視窗 -->
    <consult-doctor :consultdoctorbutton="consultdoctorbutton"></consult-doctor>
    <!-- 開啟圖片 視窗 -->
    <general-photo-template :generalphototemplatebutton="generalphototemplatebutton"></general-photo-template>
    <!-- 開啟報告 視窗 -->
    <general-report-template :generaltemplatebutton="generaltemplatebutton"></general-report-template>
    <!-- 身心指數 視窗 -->
    <HRV_chart :strJsonHRVMetrics="strJsonHRVMetrics" :indexResult="indexResult"></HRV_chart>

  </div>
</template>

<script>
import { medicalFileSync } from '@/api/user'
import ConsultDoctor from './Consultdoctor.vue'
import GeneralPhotoTemplate from './Generalphototemplate'
import GeneralReportTemplate from './Generalreporttemplate'
import HRV_chart from './HRVChart.vue'

export default {
  components: {
    ConsultDoctor,
    GeneralPhotoTemplate,
    GeneralReportTemplate,
    HRV_chart
  },
  props: {
    userPid: {
      type: String,
      required: true
    },
    tabName: {
      type: String,
      required: true
    },
    i18n: {
      type: String,
      required: true
    },
  },
  watch: {
    // 醫療影像同步
    syncTriggle(v) {
      if(v.substring(0,10)=='pageChange'){
        var stringValue = v.split('_')
        if(stringValue[1]==this.tabName){
          try{
            var intValue = parseInt(stringValue[2])
          }catch(e){
            console.log(e)
          }
          this.getPage(intValue,1)
        }
      }
    },
    Generalpage() {
      this.getGeneral()
    }
  },
  computed: {
    syncSwitch() {
      return this.$store.getters.syncSwitch
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
    syncTriggle: {
      get() {
          return this.$store.getters.syncTriggle
        },
      set(val) {
        this.$store.commit('SET_SYNC_TRIGGLE', val)
      }
    },
    qid: {
      get() {
        return this.$store.getters.qid
      },
      set(val) {
        this.$store.commit('SET_QUEUE_ID', val)
      }
    },
  },
  data() {
    return {
      generalphototemplatebutton: null,
      Generalpage: 1,
      getgeneraltotal: null,
      datalist: [],
      empty: false,
      consultdoctorbutton: false,
      generaltemplatebutton:null,
      data_loading: false,
      indexResult:[],
      strJsonHRVMetrics:{},
    }
  },
  filters:{
    // 備註說明
    note(note){
      if(note==""||note==null){
        return "--"
      }
      else{
        return note
      }
    }
  },
  mounted() {
    this.getGeneral()
  },
  methods: {
    // 換頁 (設立旗子以防止收到socket後，無限迴圈執行程式)
    getPage(page = 1, flag = 0) {
      // 醫療影像同步
      if(this.syncSwitch==true && flag == 0){
        medicalFileSync({qid: this.qid, trigger: 'pageChange_'+this.tabName+'_'+page})
        // this.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'pageChange_'+this.tabName+'_'+page})
      }
      this.Generalpage = page
      window.scrollTo(0,0);
    },
    // 開啟會診醫生視窗
    consultdoctor(typeid){
      let data = {
        tabName: this.tabName,
        typeid: typeid,
        spid: this.userPid,
        status: true
      }
      this.consultdoctorbutton = data
    },
    // 開啟檔案
    showfile(msg, src) {
      this.$vs.loading();
      var _self = this
      // 醫療影像同步 開啟視窗
      if(_self.syncSwitch==true){
        // _self.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'userimg_'+msg})
        medicalFileSync({qid: this.qid, trigger: 'userimg_'+msg})
      }
      let data = {
        title: _self.i18n,
        subtitle: '',
        date: '',
        pdfurl: src,
        status: true
      }
      _self.generalphototemplatebutton= data
      setTimeout( ()=> {
        this.$vs.loading.close()
      }, 500);
    },
    // 關閉視窗
    closefile() {
      // 醫療影像同步 關閉視窗
      if(this.syncSwitch==true){
        // this.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'closedialog'})
        medicalFileSync({qid: this.qid, trigger: 'closedialog'})
      }
    },
    // 取照片list
    getGeneral(){
      console.log('getGeneral',this.tabName)
      if(this.tabName=="userimg"){
        // 患部照片
        var url = process.env.VUE_APP_UPLOAD+'/generalimage/'
        this.getGeneralList(0, url)
      }else if(this.tabName=="report"){
        // 健檢報告(PDF)
        var url = process.env.VUE_APP_UPLOAD+'/report/'
        this.getGeneralList(1,url)
      }else if(this.tabName=="funduscopy"){
        // 眼底鏡
        var url = process.env.VUE_APP_UPLOAD+'/funduscopy/'
        this.getGeneralList(3, url)
      }else if(this.tabName=="ultraSound"){
        // 超音波照片
        var url = process.env.VUE_APP_UPLOAD+'/ultrasound/'
        this.getGeneralList(4, url)
      }else if(this.tabName=="ectrocarDiogram"){
        // 心電圖
        var url = process.env.VUE_APP_UPLOAD+'/electrocardiogram/'
        this.getGeneralList(5,url)
      }else if(this.tabName=="earTab"){
        // 耳道
        var url = process.env.VUE_APP_UPLOAD+'/ear/'
        this.getGeneralList(6, url)
      }else if(this.tabName=="noseTab"){
        // 鼻腔
        var url = process.env.VUE_APP_UPLOAD+'/nasal/'
        this.getGeneralList(7, url)
      }else if(this.tabName=="throatTab"){
        // 咽喉
        var url = process.env.VUE_APP_UPLOAD+'/throat/'
        this.getGeneralList(8, url)
      }else if(this.tabName=="mouthTab"){
        // 口腔
        var url = process.env.VUE_APP_UPLOAD+'/oral/'
        this.getGeneralList(9, url)
      }else if(this.tabName=="skinTab"){
        // 皮膚
        var url = process.env.VUE_APP_UPLOAD+'/skin/'
        this.getGeneralList(10, url)
      }else if(this.tabName=="skinTab"){
        // 皮膚
        var url = process.env.VUE_APP_UPLOAD+'/skin/'
        this.getGeneralList(10, url)
      }else if(this.tabName=="HRV"){
        // 心電心音
        var url = process.env.VUE_APP_UPLOAD+'/comgo/'
        this.getGeneralList(15, url)
      }
    },
    // 取患部照片list
    getGeneralList(type, url){
      var _self = this
      let payload = {
        'spid': _self.userPid,
        'type': type,
        'page': _self.Generalpage
      }
      _self.datalist = []
      _self.$store.dispatch('fetchEmrImage', payload).then((res) => {
        _self.$vs.loading();
        var imagepath = res.data.items
        _self.getgeneraltotal = res.data.total
        if(imagepath.length==0){
          _self.empty = true
        }
        imagepath.forEach(function(v,i){
          var slist = []
          slist.push(url+v.imagefile)
          _self.datalist.push({
            'spid': _self.userPid,
            'url': url+v.imagefile,
            'text': v.remark,
            'date': v.createdata,
            'srcList': slist[0],
            'typeid': v.id,
            'name':v.imagefile_origin,
            'ext':v.ext
          })
          if(v.ext =='pdf' || v.ext=='PDF'){
            _self.datalist[i].url = url+v.imagefile
            _self.datalist[i].type = v.minetype
            if(v.analysis && type==5 ){
              _self.datalist[i].analysis =  v.analysis
            }
          }else if(type==5 ){
            _self.datalist[i].status = v.status
            _self.datalist[i].url = url+v.imagefile
            _self.datalist[i].type = v.minetype
          }else if(type==15 ){
            _self.datalist[i].more = v.more
          }
        });
      }).then(()=>{
        _self.$vs.loading.close()
      })
    },

    // 開啟健檢報告pdf
    openReportPdfmobile(item){
      console.log('openReportPdfmobile',item)
      this.$vs.loading();
      var data
      if(this.syncSwitch==true){
        medicalFileSync({qid: this.qid, trigger: this.tabName+'_'+item.typeid})
      }
      if(this.tabName=="report"||this.tabName=="userimg"||this.tabName=="funduscopy"||this.tabName=="earTab"||this.tabName=="noseTab"||this.tabName=="throatTab"||this.tabName=="mouthTab"||this.tabName=="skinTab"||this.tabName=="HRV"){
        data = {
          title:this.i18n,
          subtitle:item.text,
          type :item.minetype,
          date:item.date,
          pdfurl:item.url,
          status:true
        }
      }else if(this.tabName=="ectrocarDiogram"){
        data = {
          ext :item.ext,
          title:this.i18n,
          subtitle:item.text,
          date:item.date,
          pdfurl:item.url,
          status:true,
          spid: item.spid,
          cht: (item.ext=='cha') ? true : false,
          analysis: item.analysis
        }
      }else if(this.tabName=="k2Report"){
        data = {
          title:    this.i18n,
          subtitle: item.text,
          date:     item.date,
          pdfurl:   item.url,
          status:   true,
          spid:     item.spid,
        }
      }
      console.log("report open", data)
      this.generaltemplatebutton = data
      setTimeout( ()=> {
        this.$vs.loading.close()
      }, 500);
    },
    openReportHRV(report){
      console.log('openReportHRV',report)
      this.hrv_data = JSON.parse(report.more)
      let indexResult_list = JSON.parse(this.hrv_data.strJsonIndexResult)
      let strJsonHRVMetrics_list = JSON.parse(this.hrv_data.strJsonHRVMetrics)
      this.strJsonHRVMetrics = strJsonHRVMetrics_list
      this.indexResult = indexResult_list
      if(this.syncSwitch==true){
        medicalFileSync({qid: this.qid, trigger: this.tabName+'_'+report.typeid})
      }
      this.report_popup=true
    },
    check_ext(ext,form){
      // console.log('check ext',ext,form)
      if(form=='all'){
        if(ext=='jpg'||ext=='jpeg'||ext=='png'||ext=='JPG'||ext=='JPEG'||ext=='PNG'||ext=='PDF'||ext=='pdf'||ext=='cha'){
          return true
        }else{
          return false
        }
      }else if(form=='img'){
        if(ext=='jpg'||ext=='jpeg'||ext=='png'||ext=='JPG'||ext=='JPEG'||ext=='PNG'){
          return true
        }else{
          return false
        }
      }else if(form=='pdf'){
        if(ext=='PDF'||ext=='pdf'){
          return true
        }else{
          return false
        }
      }else if(form=='cha'){
        if(ext=='cha'){
          return true
        }else{
          return false
        }
      }else if(form=='golden'){
        if(ext=='golden'){
          return true
        }else{
          return false
        }
      }
    },
    file_path(data){
      // console.log(data)
      var file_path = ''
      if (data.ext=='pdf'||data.ext=='PDF') {
        file_path = '/images/file/pdf.png'
      } else if (data.ext=='cha'||data.ext=='golden') {
        file_path = '/images/file/ecg.png'
      } else {
        file_path = data.url
      }
      return file_path
    },
    formatText(text) {
      return text.replace(/\n/g, "<br>");
    }
  },
}
</script>

<style lang="scss">
.my-icon {
  color: red;
  width: 48px;
  height: 48px;
}
</style>
