<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" spacer v-model="isSidebarActiveLocal">
    <vs-divider class="mt-24 mb-0"></vs-divider>
    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div>
        <!-- 清單 -->
        <ul class="ml-2 con-s mt-2 cd-form-group">
          <li class="flex" v-for="(item, index) in listData" :key="index">
            <vs-switch vs-icon-on="check" v-model="item.active"/>
            <span class="ml-2" slot="off">{{($i18n.locale=='en') ? item.ename : item.note}}</span>
          </li>
          <li class="flex" v-for="(item, index) in listDataOther" :key="index">
            <vs-switch vs-icon-on="check" v-model="showOther" @click="othertext = ''"/>
            <span class="ml-2" slot="off">{{($i18n.locale=='en') ? 'other' : '其他'}}</span>
          </li>
        </ul>
        <!-- 其他欄位輸入框 -->
        <vs-textarea v-show="showOther==true" :label="$t('OtherTxt')" v-model="othertext" />
      </div>
    </VuePerfectScrollbar>
    <vs-col class="py-4" vs-type="flex" vs-justify="space-around" slot="footer">
      <vs-button @click="updateData">{{$t('Update')}}</vs-button>
      <vs-button type="border" color="dark" @click="isSidebarActiveLocal = false">{{$t('Cancel')}}</vs-button>
    </vs-col>
  </vs-sidebar>
</template>

<script>
import { updateHistory, getHistoryPersonSick,getHistoryPersonSickOther,updateHistoryother } from '@/api/user'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    disease_type:{
      type: String,
      required: true
    },
    type: {
      type: Number,
      required: true
    },
    userPid: {
      type: String,
      required: true
    }
  },
  watch: {
    // sidebar開關
    isSidebarActive(val) {
      var _self = this
      if(!val) return
      _self.$vs.loading()
      _self.listData = []
      // 個人病史
      getHistoryPersonSick({type: _self.type, spid: _self.userPid}).then((res) => {
        _self.listData = res.data.data
      })
      // 個人病史其他
      getHistoryPersonSickOther({type: _self.disease_type, spid: _self.userPid}).then((res) => {
        _self.listDataOther = res.data.item
      }).then(() => {
        if(_self.listDataOther.mh_other_value==null||_self.listDataOther.mh_other_value==""){
          _self.showOther = false
        }else if(_self.listDataOther.mh_other_value!=""){
          _self.showOther = true
          _self.othertext = _self.listDataOther.mh_other_value
        }
        _self.$vs.loading.close()
      })
    },
  },
  data() {
    return {
      name: "",
      listData: [],
      listDataOther:[],
      selectArray: [],
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 100,
        wheelSpeed: .80,
      },
      othertext: '',
      showOther: false,
    }
  },
  computed: {
    selectType() {
      var _self = this
      return _self.type
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    partnerid() {
      return this.$store.getters.partner
    },
  },
  methods: {
    // 更新
    async updateData() {
      var _self = this
      var tmplistData,tmplistDataOther;
      _self.$vs.loading()
      tmplistData = _self.listData
      tmplistDataOther = _self.othertext

      // 更新所有病史
      await updateHistory({type: _self.type, spid: _self.userPid, data: tmplistData}).then((res) => {})
      .catch((e) => { console.log('eee ', e) })

      // 更新所有病史其他
      await updateHistoryother({type: _self.disease_type, spid: _self.userPid, text: tmplistDataOther}).then((res) => {})
      .catch((e) => { console.log('eee ', e) })

      // 取病史資料
      await _self.$store.dispatch('fetchEMRDATA', {'userPid': _self.userPid, 'partnerid': _self.partnerid})
      .catch((error) => { console.log('error', error) })
      .then(() => {
        _self.$emit('closeSidebar')
        _self.$vs.loading.close()
      })
    },
  },
  components: {
    VuePerfectScrollbar,
  }
}
</script>

<style lang="scss">
  .scroll-area--data-list-add-new {
    height: calc(var(--vh, 1vh) * 100 - 180px);
  }
</style>
