<template>
  <div>
    <!-- 患部照片 -->
    <vs-row>
      <vs-col v-for="(item, index) in datalist" :key="index" vs-lg="4" vs-sm="6" vs-xs="12">
        <div class="cd-card-group text-center">
          <vs-card>
            <vs-row class="cd-card">
              <vs-col vs-type="flex" vs-justify="flex-end">
                <p>{{ item.date }}</p>
              </vs-col>
              <vs-divider />
              <vs-col>
                <el-image
                  class="cursor-pointer"
                  :style="windowWidth <= 375 ? 'width: '+(windowWidth-155)+'px; height: '+(windowWidth-155)+'px;' : 'width: 220px; height: 220px;'"
                  :id="'userimg_'+item.typeid"
                  @click="showimg(item.typeid, item.srcList)"
                  :src="item.url"
                  :close-on-click-modal="closeimg">
                </el-image>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" style="overflow: hidden;">
                <p class="cd-p-normal">{{ item.text | note }}</p>
              </vs-col>
              <vs-col v-if="item.name" vs-type="flex" vs-justify="center" style="overflow: hidden;">
                <p class=""  @click="showimg(item.typeid, item.srcList)">{{ item.name }}</p>
              </vs-col>
              <vs-divider />
              <vs-col vs-type="flex" vs-justify="space-around">
                <vs-button size="small" @click="consultdoctor(item.typeid)">{{$t('ConsultBtn')}}</vs-button>
              </vs-col>
            </vs-row>
          </vs-card>
        </div>
      </vs-col>
    </vs-row>
    <!-- 分頁 -->
    <vs-row v-show="datalist.length!=0">
      <vs-col vs-type="flex" vs-justify="center">
        <vs-pagination :max="windowWidth<768?5:7" :total="Math.ceil(this.getphotototal/12)" v-model="photopage" @change="getPhotoPage(photopage)"></vs-pagination>
      </vs-col>
    </vs-row>
    <!-- 沒資料 -->
    <div class="cd-nodata" v-show="photoempty==true">
      {{$t('nodata')}}
    </div>
    <!-- 邀請會診醫師 視窗 -->
    <consult-doctor :consultdoctorbutton="consultdoctorbutton"></consult-doctor>
    <!-- 開啟圖片 視窗 -->
    <general-photo-template :generalphototemplatebutton="generalphototemplatebutton"></general-photo-template>
  </div>
</template>

<script>
import { medicalFileSync } from '@/api/user'
import ConsultDoctor from './Consultdoctor.vue'
import GeneralPhotoTemplate from './Generalphototemplate'
export default {
  components: {
    ConsultDoctor,
    GeneralPhotoTemplate,
  },
  props: {
    userPid: {
      type: String,
      required: true
    },
    tabName: {
      type: String,
      required: true
    },
    i18n: {
      type: String,
      required: true
    },
  },
  watch: {
    // 醫療影像同步
    syncTriggle(v) {
      if(v.substring(0,10)=='pageChange'){
        var stringValue = v.split('_')
        if(stringValue[1]==this.tabName){
          try{
            var intValue = parseInt(stringValue[2])
          }catch(e){
            console.log(e)
          }
          this.getPhotoPage(intValue,1)
        }
      }
    },
    photopage() {
      this.getPhoto()
    }
  },
  computed: {
    syncSwitch() {
      return this.$store.getters.syncSwitch
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
    syncTriggle: {
      get() {
          return this.$store.getters.syncTriggle
        },
      set(val) {
        this.$store.commit('SET_SYNC_TRIGGLE', val)
      }
    },
    qid: {
      get() {
        return this.$store.getters.qid
      },
      set(val) {
        this.$store.commit('SET_QUEUE_ID', val)
      }
    },
  },
  data() {
    return {
      generalphototemplatebutton: null,
      photopage: 1,
      getphotototal: null,
      datalist: [],
      photoempty: false,
      consultdoctorbutton: false,
    }
  },
  filters:{
    // 備註說明
    note(note){
      if(note==""||note==null){
        return "--"
      }
      else{
        return note
      }
    }
  },
  mounted() {
    this.getPhoto()
  },
  methods: {
    // 換頁 (設立旗子以防止收到socket後，無限迴圈執行程式)
    getPhotoPage(page = 1, flag = 0) {
      // 醫療影像同步
      if(this.syncSwitch==true && flag == 0){
        medicalFileSync({qid: this.qid, trigger: 'pageChange_'+this.tabName+'_'+page})
        // this.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'pageChange_'+this.tabName+'_'+page})
      }
      this.photopage = page
      window.scrollTo(0,0);
    },
    // 開啟會診醫生視窗
    consultdoctor(typeid){
      let data = {
        tabName: this.tabName,
        typeid: typeid,
        spid: this.userPid,
        status: true
      }
      console.log("data", data)
      this.consultdoctorbutton = data
    },
    // 開啟圖片
    showimg(msg, src) {
      var _self = this
      // 醫療影像同步 開啟視窗
      if(_self.syncSwitch==true){
        // _self.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'userimg_'+msg})
        medicalFileSync({qid: this.qid, trigger: 'userimg_'+msg})
      }
      let data = {
        title: _self.i18n,
        subtitle: '',
        date: '',
        pdfurl: src,
        status: true
      }
      _self.generalphototemplatebutton= data
    },
    // 關閉圖片
    closeimg() {
      // 醫療影像同步 關閉視窗
      if(this.syncSwitch==true){
        // this.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'closedialog'})
        medicalFileSync({qid: this.qid, trigger: 'closedialog'})
      }
    },
    // 取照片list
    getPhoto(){
      if(this.tabName=="userimg"){
        // 患部照片
        var url = process.env.VUE_APP_UPLOAD+'/generalimage/'
        this.getPhotoList(0, url)
      }else if(this.tabName=="ultraSound"){
        // 超音波照片
        var url = process.env.VUE_APP_UPLOAD+'/ultrasound/'
        this.getPhotoList(4, url)
      }else if(this.tabName=="funduscopy"){
        // 眼底鏡
        var url = process.env.VUE_APP_UPLOAD+'/funduscopy/'
        this.getPhotoList(3, url)
      }else if(this.tabName=="earTab"){
        // 耳道
        var url = process.env.VUE_APP_UPLOAD+'/ear/'
        this.getPhotoList(6, url)
      }else if(this.tabName=="noseTab"){
        // 鼻腔
        var url = process.env.VUE_APP_UPLOAD+'/nasal/'
        this.getPhotoList(7, url)
      }else if(this.tabName=="throatTab"){
        // 咽喉
        var url = process.env.VUE_APP_UPLOAD+'/throat/'
        this.getPhotoList(8, url)
      }else if(this.tabName=="mouthTab"){
        // 口腔
        var url = process.env.VUE_APP_UPLOAD+'/oral/'
        this.getPhotoList(9, url)
      }else if(this.tabName=="skinTab"){
        // 皮膚
        var url = process.env.VUE_APP_UPLOAD+'/skin/'
        this.getPhotoList(10, url)
      }
    },
    // 取患部照片list
    getPhotoList(type, url){
      var _self = this
      let payload = {
        'spid': _self.userPid,
        'type': type,
        'page': _self.photopage
      }
      _self.datalist = []
      _self.$store.dispatch('fetchEmrImage', payload).then((res) => {
        var imagepath = res.data.items
        _self.getphotototal = res.data.total
        if(imagepath.length==0){
          _self.photoempty = true
        }
        imagepath.forEach(function(v,i){
          var slist = []
          slist.push(url+v.imagefile)
          _self.datalist.push({
            'spid': _self.userPid,
            'url': url+v.imagefile,
            'text': v.remark,
            'date': v.createdata,
            'srcList': slist[0],
            'typeid': v.id,
            'name':v.imagefile_origin
          })
        });
      })
    },
  },
}
</script>