<template>
    <div class="cd-body">
        <!-- navbar -->
        <div class="flex flex-wrap justify-between items-center p-4" v-if="selectedPatient">
            <div class="my-1 flex flex-wrap">
                <div class="flex items-end">
                    <feather-icon class="sm:inline-flex cursor-pointer mr-4" icon="ListIcon" @click.stop="showSidebar" v-if="windowWidth <= 600"></feather-icon>
                    <span class="mr-2 text-big whitespace-no-wrap" v-if="qid != 0 && queue" style="line-height: 1em">#{{ queue.num }}</span>
                    <span class="mr-2 text-big whitespace-no-wrap" style="line-height: 1em">{{ selectedPatient.name }}</span>
                    <span class="mr-2">{{ selectedPatient.sex == 1 ? $t('emr.male') : $t('emr.female') }}</span>
                    <span class="mr-2">{{ $common.getAge(selectedPatient.birthday) }}</span>
                </div>
                <div class="flex items-end" v-if="userBaseData.e_name">
                    <span class="cd-button-group whitespace-no-wrap" v-for="(item, index) in userBaseData.e_name.split(',')" :key="index">
                        <feather-icon svgClasses="h-3 w-3" icon="HashIcon"></feather-icon>
                        {{ item }}
                    </span>
                </div>
            </div>
            <div class="my-1 flex flex-wrap">
                <!-- 匯出處方籤 -->
                <!-- <div class="mr-4 whitespace-no-wrap" v-if="apptConfig.exportRX.show && emrIndex=='queue' && queue && queue.closed==0 && doctortype!=2 && doctortype!=4">
                    <vs-dropdown class="cd-p-pointer text-primary" vs-custom-content>
                        <span class="items-end">
                            {{ geti18n(apptConfig.exportRX.i18n) }}
                            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"></feather-icon>
                        </span>
                        <vs-dropdown-menu class="vx-navbar-dropdown">
                            <ul style="min-width: 11rem"> -->
                <!-- view -->
                <!-- <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="openRxPopup" v-if="mrid">
                                    <feather-icon icon="EyeIcon" svgClasses="w-4 h-4" />
                                    <span class="ml-2">{{$t('emr.appt.checkPrescription')   }}</span>
                                </li> -->
                <!-- txt -->
                <!-- <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="exportTxt">
                                    <feather-icon icon="FileTextIcon" svgClasses="w-4 h-4" />
                                    <span class="ml-2">txt</span>
                                </li> -->
                <!-- email -->
                <!-- <li class="flex justify-between py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="emailCollapse=!emailCollapse">
                                    <div class="flex">
                                        <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />
                                        <span class="ml-2">Email</span>
                                    </div>
                                    <feather-icon icon="ChevronRightIcon" svgClasses="w-4 h-4" />
                                </li> -->
                <!-- <ul style="min-width: 11rem" v-if="emailCollapse==true">
                                    <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"  v-for="(item, index) in pharmacyList" :key="index" @click="exportEmail(item.pharmacy, item.email)">
                                        <span class="ml-4">{{item.pharmacy}}</span>
                                    </li>
                                </ul> -->
                <!-- QRcode -->
                <!-- <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="exportQRCode">
                                    <feather-icon icon="SquareIcon" svgClasses="w-4 h-4" />
                                    <span class="ml-2">QRcode</span>
                                </li> -->
                <!-- API -->
                <!-- <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
                                    <feather-icon icon="CodeIcon" svgClasses="w-4 h-4" />
                                    <span class="ml-2">API</span>
                                </li> -->
                <!-- </ul>
                        </vs-dropdown-menu>
                    </vs-dropdown> -->
                <!-- </div> -->
                <!-- 通話 -->
                <vs-button class="cd-button-2w flex items-center cursor-pointer hover:font-semibold" @click.stop="call()" v-if="qid == 0">
                    {{ $t('apptMgmt.call') }}
                </vs-button>
                <!-- 邀請家屬 -->
                <div class="whitespace-no-wrap" v-if="pInfo && calling && qid == p2p_queue_id">
                    <vs-button class="cd-button-2w" @click="openInviteFamilyPopup">邀請家屬</vs-button>
                </div>
                <!-- 轉接醫師 -->
                <div class="whitespace-no-wrap" v-if="calling && qid != 0 && qid == p2p_queue_id">
                    <vs-dropdown class="cursor-pointer" :disabled="phone_status == 1">
                        <vs-button class="cd-button-4w-icon" icon-pack="feather" icon="icon-chevron-down" icon-after>{{ $t('emr.transfer') }}</vs-button>
                        <vs-dropdown-menu class="vx-navbar-dropdown" style="width: 300px">
                            <vs-dropdown-item v-for="(item, index) in contacts" :key="index" :disabled="!item.num" @click="diverDoctor(item.did)" @touchstart="diverDoctor(item.did)">
                                <!-- <li style="white-space: nowrap;overflow: hidden;"> -->
                                <span style="white-space: nowrap; overflow: hidden">{{ item.name }} {{ truncateString(item.name, item.di_name) }}</span>
                                <!-- <span>{{truncateString(item.di_name)}}</span> -->
                                <!-- </li> -->
                            </vs-dropdown-item>
                        </vs-dropdown-menu>
                    </vs-dropdown>
                </div>
                <!-- 通話中 在非通話中掛號會出現 -->
                <div v-else-if="calling">
                    <vs-button class="cd-button-4w-icon" @click="backToCallingQueue">{{ $t('emr.returnToCallingQueue') }}</vs-button>
                </div>
                <!-- 返回本次看診 -->
                <div v-if="emrIndex != 'queue' && qid != 0">
                    <vs-button class="cd-button-4w" @click="selectMenu('queue')">{{ $t('emr.backToAppt') }}</vs-button>
                </div>
                <div @click="ExportRX = false">
                    <vs-dropdown class="cursor-pointer" vs-custom-content vs-trigger-click>
                        <vs-button class="" type="flat" icon-pack="feather" icon="icon-more-vertical"></vs-button>
                        <vs-dropdown-menu>
                            <div class="flex whitespace-no-wrap p-2" style="min-width: 11rem">
                                <ul class="w-full">
                                    <!-- 代理掛號 -->
                                    <li class="flex p-2" :class="'cursor-pointer hover:bg-primary hover:text-white'" @click="showApptPopup(0, selectedPatient.pid, selectedPatient.name)">
                                        <span>{{ $t('membersMgmt.agent') }}</span>
                                    </li>
                                    <!-- 特約掛號 -->
                                    <li class="flex p-2" :class="'cursor-pointer hover:bg-primary hover:text-white'" @click="showApptPopup(1, selectedPatient.pid, selectedPatient.name)">
                                        <span>{{ $t('membersMgmt.special') }}</span>
                                    </li>
                                    <!-- 會診掛號 -->
                                    <li class="flex p-2" :class="'cursor-pointer hover:bg-primary hover:text-white'" @click="showApptPopup(2, selectedPatient.pid, selectedPatient.name)">
                                        <span>{{ $t('apptMgmt.consultation') }}</span>
                                    </li>
                                    <!-- 訊息 -->
                                    <li class="flex p-2" :class="'cursor-pointer hover:bg-primary hover:text-white'" @click.stop="showChatPopup(selectedPatient.mid, selectedPatient.name)">
                                        <span>{{ $t('components.msg.msg') }}</span>
                                    </li>
                                    <!-- 匯出處方籤 -->
                                    <li class="flex p-2" :class="'cursor-pointer hover:bg-primary hover:text-white'" @click.stop="showExportRX()">
                                        <span>{{ $common.getLang(apptConfig.exportRX.i18n) }}</span>
                                    </li>
                                    <li class="flex p-2" :class="'cursor-pointer hover:bg-primary hover:text-white'" @click="showUploadPopup()">
                                        <span>{{ $t('membersMgmt.upload') }}</span>
                                    </li>
                                    <div class="pl-2 ml-2 cd-dropdown-children" v-if="ExportRX == true">
                                        <ul style="min-width: 11rem">
                                            <!-- view -->
                                            <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="openRxPopup" v-if="mrid">
                                                <feather-icon icon="EyeIcon" svgClasses="w-4 h-4" />
                                                <span class="ml-2">{{ $t('emr.appt.checkPrescription') }}</span>
                                            </li>
                                            <!-- txt -->
                                            <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="exportTxt">
                                                <feather-icon icon="FileTextIcon" svgClasses="w-4 h-4" />
                                                <span class="ml-2">txt</span>
                                            </li>
                                            <!-- email -->
                                            <li class="flex justify-between py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="emailCollapse = !emailCollapse">
                                                <div class="flex">
                                                    <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />
                                                    <span class="ml-2">Email</span>
                                                </div>
                                                <feather-icon icon="ChevronRightIcon" svgClasses="w-4 h-4" />
                                            </li>
                                            <ul style="min-width: 11rem" v-if="emailCollapse == true">
                                                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" v-for="(item, index) in pharmacyList" :key="index" @click="exportEmail(item.pharmacy, item.email)">
                                                    <span class="ml-4">{{ item.pharmacy }}</span>
                                                </li>
                                            </ul>
                                            <!-- QRcode -->
                                            <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="exportQRCode">
                                                <feather-icon icon="SquareIcon" svgClasses="w-4 h-4" />
                                                <span class="ml-2">QRcode</span>
                                            </li>
                                            <!-- API -->
                                            <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
                                                <feather-icon icon="CodeIcon" svgClasses="w-4 h-4" />
                                                <span class="ml-2">API</span>
                                            </li>
                                        </ul>
                                    </div>
                                </ul>
                            </div>
                        </vs-dropdown-menu>
                    </vs-dropdown>
                </div>
            </div>
        </div>
        <vs-row id="mainArea" class="bg-white mb-base">
            <!-- 目錄 -->
            <vs-col vs-w="2" vs-xs="12" v-if="menuActive == true || windowWidth > 600">
                <vs-sidebar class="cd-emr-sidebar h-full" :static-position="menuActive" hidden-background v-model="menuActive">
                    <div class="mb-base">
                        <div :id="data[0]" class="p-4 cd-menu-action" v-for="(data, index) in emrConfig" :key="index" @click="selectMenu(data[0])" v-show="data[1].show">
                            <span>{{ $common.getLang(data[1].i18n) }}</span>
                        </div>
                    </div>
                    <div class="grid grid-cols-1 gap-4 whitespace-no-wrap">
                        <p class="px-4 py-2 font-bold">
                            {{ $t('emr.appt.apptHistory') }}
                        </p>
                        <div class="px-4" v-for="(data, index) in queueHistory" :key="index">
                            <div class="mb-2 flex items-center text-sm font-semibold" v-if="index == 0 || data.queue_id != queueHistory[index - 1].queue_id">
                                <feather-icon class="mx-1" icon="CalendarIcon" svgClasses="w-4 h-4"></feather-icon>
                                <p>
                                    {{ data.date }}
                                    {{ $common.getInterval(data.interval) }}
                                </p>
                            </div>
                            <div class="p-1 cd-menu-action" @click="openQueueHistoryPopup(data)">
                                <div class="flex justify-between text-sm">
                                    <span>{{ data.dname }}</span>
                                    <span class="subtitle">{{ data.json_p ? '藥囑(' + data.json_p.length + ')' : '' }}</span>
                                </div>
                                <div v-if="data.json_a">
                                    <p class="text-sm subtitle overflow-hidden" v-for="(item, index) in data.json_a" :key="index">{{ item.disease_code }} {{ item.ename }}</p>
                                </div>
                                <div v-else>
                                    <p class="text-sm subtitle">({{ $t('emr.appt.ICD10NoData') }})</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </vs-sidebar>
            </vs-col>
            <!-- main area -->
            <vs-col class="p-4" vs-w="10" vs-xs="12" v-if="pid && (menuActive == false || windowWidth > 600)">
                <soap v-if="emrIndex == 'queue'"></soap>
                <!-- 基本資料 -->
                <emr-base :userPid="pid" v-if="emrIndex == 'base'"></emr-base>
                <!-- 生命與健康數值 -->
                <health-data :userPid="pid" :loadingFlag="lifeLoadingFlag" v-if="emrIndex == 'life'"></health-data>
                <!-- 健康聯網-生化檢驗 -->
                <bio-data :userPid="pid" :loadingFlag="bioDataLoadingFlag" v-if="emrIndex == 'bioData'"></bio-data>
                <!-- 振興-生化檢驗 -->
                <bio-data2 :loadingFlag="bioData_chghLoadingFlag" v-if="emrIndex == 'bioData_chgh'"></bio-data2>
                <!-- 屏基-標準版-生化檢驗 -->
                <bio-data-standard :loadingFlag="bioData_standardLoadingFlag" v-if="emrIndex == 'bioData_standard'"></bio-data-standard>
                <!-- 醫療影像 -->
                <dicom-data :userPid="pid" v-if="emrIndex == 'dicomData'"></dicom-data>
                <!-- 屏基-藥歷查詢 -->
                <medication :patientAccount="selectedPatient.account" v-if="emrIndex == 'medication'"></medication>
                <!-- 華育生殖檢驗報告 -->
                <list-form :reports="fertilityReport" v-if="emrIndex == 'fertility'"></list-form>
                <!-- 飲食管理 -->
                <diet-report v-if="emrIndex == 'dietReport'"></diet-report>
                <!-- 健康報告 -->
                <health-report v-if="emrIndex == 'healthReport'" :userPid="pid"></health-report>
                <!-- 患部照片 -->
                <photo-graph v-if="emrIndex == 'userimg'" :userPid="pid"></photo-graph>
                <!-- 客服系統 -->
                <service-record :userPid="pid" v-if="emrIndex == 'crm'"></service-record>
                <!-- 上傳醫療影像視窗 -->
                <upload-popup :uploadPopupActive="openUploadPopup" :spid="parseInt(pid)" :spname="nameUpload" @closePopup="closeUploadPopup"></upload-popup>
                <!-- test -->
                <vs-tabs v-if="emrIndex == 'tab'">
                    <vs-tab class="px-0" :label="data.name" v-for="(data, index) in tierSettingArray" :key="index">
                        <vs-tabs v-if="data.form == 'tab'">
                            <vs-tab class="px-0" :label="item.name" v-for="(item, index) in data.subtab" :key="index">
                                <table-form :data="item.reports" v-if="item.form == 'table'"></table-form>
                                <list-form :data="item.reports" v-else-if="item.form == 'list'"></list-form>
                                <img-form :data="item.reports" v-else-if="item.form == 'img'"></img-form>
                            </vs-tab>
                        </vs-tabs>
                        <table-form :data="data.reports" v-else-if="data.form == 'table'"></table-form>
                        <list-form :data="data.reports" v-else-if="data.form == 'list'"></list-form>
                        <img-form :data="data.reports" v-else-if="data.form == 'img'"></img-form>
                    </vs-tab>
                </vs-tabs>
                <table-form :data="reports" v-else-if="emrIndex == 'table'"></table-form>
                <list-form :data="reports" v-else-if="emrIndex == 'list'"></list-form>
                <img-form :data="reports" v-else-if="emrIndex == 'img'"></img-form>
            </vs-col>
        </vs-row>
        <!-- 看診紀錄 -->
        <vs-popup fullscreen :title="$t('emr.appt.apptHistory') + ' ' + queueHistoryParams.date + ' ' + $common.getInterval(queueHistoryParams.interval) + ' ＃' + queueHistoryParams.num" :active.sync="queueHistoryPopup">
            <div class="cd-form-group text-right" v-if="qid != 0 && doctortype != 2 && doctortype != 4">
                <vs-button @click="importHistory">{{ $t('emr.appt.copyToCurrentNote') }}</vs-button>
            </div>
            <!-- 醫師主訴 -->
            <div class="cd-form-group cd-history" v-if="apptConfig.O.show">
                <span v-if="qid == 0 || doctortype == 2 || doctortype == 4">{{ $common.getLang(apptConfig.O.i18n) }}</span>
                <vs-checkbox class="cd-form-group" v-model="historyApply.memo_o" v-else>{{ $common.getLang(apptConfig.O.i18n) }}</vs-checkbox>
                <textarea class="w-full cd-emr-textarea" rows="5" disabled v-model="queueHistoryParams.memo_o"></textarea>
            </div>
            <!-- ICD -->
            <div class="cd-form-group cd-history" v-if="apptConfig.A.show">
                <span v-if="qid == 0 || doctortype == 2 || doctortype == 4">{{ $common.getLang(apptConfig.A.i18n) }}</span>
                <vs-checkbox class="cd-form-group" v-model="historyApply.json_a" v-else>{{ $common.getLang(apptConfig.A.i18n) }}</vs-checkbox>
                <ag-grid-vue style="height: 200px" class="ag-theme-balham" :columnDefs="columnICDHistory" :rowData="historyICD"> </ag-grid-vue>
            </div>
            <!-- 藥囑 -->
            <div class="cd-form-group cd-history" v-if="apptConfig.P.show">
                <span v-if="qid == 0 || doctortype == 2 || doctortype == 4">{{ $common.getLang(apptConfig.P.i18n) }}</span>
                <vs-checkbox class="cd-form-group" v-model="historyApply.json_p" v-else>{{ $common.getLang(apptConfig.P.i18n) }}</vs-checkbox>
                <ag-grid-vue style="height: 200px" class="ag-theme-balham" :columnDefs="columnMedicineHistory" :rowData="historyMedicine"> </ag-grid-vue>
            </div>
            <!-- 建議紀錄 -->
            <div class="cd-form-group cd-history" :style="'height: ' + heightStyle / 4 + 'px'" v-if="apptConfig.suggestion.show">
                <span v-if="qid == 0 || doctortype == 2 || doctortype == 4">{{ $common.getLang(apptConfig.suggestion.i18n) }}</span>
                <vs-checkbox class="cd-form-group" v-model="historyApply.memo_p" v-else>{{ $common.getLang(apptConfig.suggestion.i18n) }}</vs-checkbox>
                <textarea class="w-full cd-emr-textarea" rows="5" disabled v-model="queueHistoryParams.memo_p"></textarea>
            </div>
        </vs-popup>
        <!-- 處方籤 QRCode -->
        <vs-popup title="處方籤 QRCode" :active.sync="QRCodePopup">
            <div class="text-center">
                <img :src="imgSrc" alt="" />
            </div>
        </vs-popup>
        <!-- 醫師未按完診卻想離開病歷頁才出現的提示訊息 -->
        <vs-popup title="" :active.sync="doctorLeavePopup">
            <div class="cd-form-group text-center" v-if="selectedPatient">
                {{ $t('emr.exitTip', { name: selectedPatient.name }) }}
            </div>
            <div class="flex justify-center">
                <vs-button class="mr-4" @click="updateFinishStatus(to)">{{ $t('emr.finish') }}</vs-button>
                <vs-button color="grey" @click="exitUnfinish()">{{ $t('emr.unfinish') }}</vs-button>
            </div>
        </vs-popup>
        <!-- 顯示處方籤 -->
        <rx-popup :mr_id="mrid" :RXPopupActive="RXPopup" @closePopup="closeRxPopup"></rx-popup>
        <!-- 選擇邀請對象視窗 -->
        <vs-popup title="選擇邀請對象" :active.sync="inviteFamilyActive" @close="closeInviteFamilyPopup">
            <vs-row class="px-6" v-if="pInfo">
                <vs-col v-if="pInfo.careGiver">
                    <div class="flex justify-between items-center" v-for="(item, index) in pInfo.careGiver" :key="index">
                        <div class="whitespace-no-wrap truncate w-48">
                            <span class="mr-1 text-lg" style="color: #2d9b32" v-if="item.token != null">●</span>
                            <span class="mr-1 text-lg" style="color: #626262" v-if="item.token == null">●</span>
                            <span>{{ item.relationship }}</span>
                        </div>
                        <div class="whitespace-no-wrap truncate w-full">
                            <span>{{ item.name }}</span>
                        </div>
                        <div class="flex items-center text-primary hover:font-semibold whitespace-no-wrap" v-if="item.token != null" @click="callCareGiver(item.p_mid)">
                            <feather-icon class="mx-1" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
                            <span>轉接</span>
                        </div>

                        <div class="flex items-center text-gray whitespace-no-wrap" v-if="item.inviteFlag == true">
                            <feather-icon class="mx-1" icon="CheckIcon" svgClasses="w-5 h-5"></feather-icon>
                            <span>已邀請</span>
                        </div>
                        <div class="flex items-center text-gray whitespace-no-wrap" v-else-if="!item.token && partnerInfo.sms_invite == 0">
                            <span>離線</span>
                        </div>
                        <div class="flex items-center text-success cursor-pointer hover:font-semibold whitespace-no-wrap" @click="inviteAndNotifyFamily(item.pid, pInfo.name, index)" v-else>
                            <feather-icon class="mx-1" icon="LinkIcon" svgClasses="w-5 h-5"></feather-icon>
                            <span>邀請</span>
                        </div>
                    </div>
                </vs-col>
            </vs-row>
        </vs-popup>
    </div>
</template>
<script>
import { sendPrescription, gethospitalbiodata, getHospitalPtchBioData, getFertilityReport, updateFinishStatus, diverDoctor, QueryDoctorListByPartner, call_p2p, getCallType, getMidByDid, sendNotifyAndVideoLink, getPatientInfoByPid } from '@/api/user'
import soap from '@/components/Emr/Soap.vue'
import call from '@/components/call'
import EmrBase from '@/views/apps/emrcomponents/base.vue'
import HealthData from '@/views/apps/emrcomponents/healthdata.vue'
import BioData from '@/views/apps/emrcomponents/biodata.vue'
import BioData2 from '@/views/apps/emrcomponents/biodata2.vue'
import BioDataStandard from '@/views/apps/emrcomponents/biodataStandard.vue'
import DicomData from '@/views/apps/emrcomponents/dicomdata.vue'
import dietReport from '@/views/dietReport.vue'
import importSetWord from '@/components/importSetWord'
import medication from '@/views/apps/emrcomponents/medication.vue'
import listForm from '@/views/apps/emrcomponents/listForm.vue'
import healthReport from '@/views/healthReport.vue'
import photoGraph from '@/views/PhotoGraph.vue'
import _ from 'lodash'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import { AgGridVue } from 'ag-grid-vue'
import FeatherIcon from '@/components/FeatherIcon.vue'
import stringToTxt from '@/utils/stringToTxt'
import EnterBtn from '@/components/enterBtn.vue'
import InviteBtn from '@/components/inviteBtn'
import serviceRecord from '@/views/serviceRecord.vue'
import rxPopup from '@/components/rxPopup'
import uploadPopup from '@/components/uploadPopup'
export default {
    data() {
        return {
            tabIndex: '',
            pid: 0,
            qid: 0,
            proxy_call: [],
            lifeLoadingFlag: true,
            bioDataLoadingFlag: true,
            bioData_chghLoadingFlag: true,
            bioData_standardLoadingFlag: true,
            demand: '',
            queueHistory: [],
            queueHistoryPage: 1,
            // 目錄索引位置
            emrIndex: 'base',
            // 備註
            notes: '',
            queue: null,
            mr: null,
            ICD: [],
            resultICD: [],
            medicine: [],
            resultMedicine: [],
            quantity: 0,
            // 去掉下拉式選單箭頭
            OpenIndicator: {
                render: (createElement) => createElement('span', { class: { toggle: true } }),
            },
            // 判斷有輸入資料時才打開下拉式選單
            queueHistoryPopup: false,
            ICDFeeback: null,
            medicineFeeback: null,
            queueHistoryParams: [],
            historyICD: [],
            historyMedicine: [],
            historyApply: {
                memo_o: false,
                json_a: false,
                json_p: false,
                memo_p: false,
            },
            imgSrc: '',
            QRCodePopup: false,
            pharmacyList: [],
            emailCollapse: false,
            fertilityReport: [],
            patientNotesPopup: false,
            inRoom: 0,
            phone_status: 0,
            menu: false,
            doctorLeavePopup: false,
            to: '',
            passFlag: false,
            apptMode: 0,
            patient: {
                pid: null,
                name: null,
            },
            contacts: [],
            RXPopup: false,
            is_app: false,
            is_p2p: false,
            inviteFamilyActive: false,
            pInfo: [],
            ExportRX: false,
            checkin: false,
            pmid: 0,
            autoUpdateApptInterval: null,
            openUploadPopup: false,
            nameUpload: '',
        }
    },
    watch: {
        '$route.params'(n, o) {
            if ((o.pid || o.qid) && (n.pid != o.pid || n.qid != o.qid)) {
                this.reload()
            }
        },
        // 醫療影像同步
        syncTriggle(v) {
            console.log('v', v)
            if (v == 'dicomData' || v == 'healthReport' || v == 'userimg') {
                console.log('click')
                document.getElementById(v).click()
            }
        },
    },
    inject: ['reload'],
    components: {
        soap,
        call,
        EmrBase,
        HealthData,
        BioData,
        BioData2,
        BioDataStandard,
        DicomData,
        dietReport,
        importSetWord,
        medication,
        listForm,
        AgGridVue,
        FeatherIcon,
        EnterBtn,
        InviteBtn,
        serviceRecord,
        rxPopup,
        healthReport,
        photoGraph,
        uploadPopup,
    },
    async mounted() {
        this.is_p2p = localStorage.getItem('is_p2p')
        this.pid = this.$route.params.pid
        this.qid = this.$route.params.qid
        if (this.qid > 0) {
            this.emrIndex = 'queue'
            this.autoUpdateAppt()
        }
        this.getPatientInfoByPid(this.pid)
        if (localStorage.getItem('doc_access_phoneType') != 3) {
            this.is_app = true
        }
        //會員管理導向各頁籤
        if (this.$route.query.index) {
            this.toDicomTabs()
        }
        if (this.$route.query && !this.$route.query.index) {
            this.proxy_call = this.$route.query
        }
        this.$store.commit('SET_QUEUE_ID', this.qid)
        await this.$store.dispatch('setMR', { qid: this.qid })
        // 取看診紀錄
        await this.getQueueHistory({
            pid: this.pid,
            partnerid: this.partnerid,
            page: 1,
        })
        // 取基本資料
        await this.$store.dispatch('fetchEMRDATA', {
            userPid: this.pid,
            partnerid: this.partnerid,
        })
        // 取生命與健康數值
        if (
            _.find(this.emrConfig, function (o) {
                return o[0] == 'life' && o[1].show
            })
        ) {
            await this.getPersonSign()
        }
        // 取生化檢驗數據 (振興)
        if (
            _.find(this.emrConfig, function (o) {
                return o[0] == 'bioData_chgh' && o[1].show
            })
        ) {
            await this.fetchChghBioData()
        }
        // 取生化檢驗數據 (屏基)
        if (
            _.find(this.emrConfig, function (o) {
                return o[0] == 'bioData_standard' && o[1].show
            })
        ) {
            await this.fetchPtchBioData()
        }
        // 取生化檢驗數據 (default)
        if (
            _.find(this.emrConfig, function (o) {
                return o[0] == 'bioData' && o[1].show
            })
        ) {
            await this.fetchCurdrBioData()
        }
        // 取華育生殖檢驗報告
        if (
            _.find(this.emrConfig, function (o) {
                return o[0] == 'fertility' && o[1].show
            })
        ) {
            await this.getFertilityReport(this.selectedPatient.mid)
        }
        if (localStorage.getItem('selectedpid') == undefined) {
            localStorage.setItem(
                'selectedpid',
                JSON.stringify({
                    name: this.userBaseData.name,
                    pid: this.pid,
                    account: this.userBaseData.account,
                })
            )
        }
        this.DoctorListByPartner()
        // 病人是否在房間
        // this.mcuApiSetInterval = setInterval(function () {
        //     let payload = { mid: _self.selectedPatient.mid, qid: _self.qid }
        //     _self.$store.dispatch('PatientStateFromMcu', payload).then((res) => {
        //         if (res.data.in_room) _self.inRoom = res.data.in_room
        //         if (res.data.room) {
        //             _self.$store.dispatch('setRoomId', res.data.room)
        //         }
        //     })
        // }, 10000)
    },
    computed: {
        emrConfig() {
            let data = JSON.parse(localStorage.getItem('his_config')).emr
            _.pull(data, _.find(data, { show: false }))
            let map = new Map()
            for (let k in data) {
                map.set(k, data[k])
            }
            let ArrayObj = Array.from(map)
            ArrayObj.sort((a, b) => {
                return a[1]['order'] - b[1]['order']
            })
            return ArrayObj
        },
        apptConfig() {
            return JSON.parse(localStorage.getItem('his_config')).appt
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        heightStyle() {
            return window.innerHeight - 240
        },
        columnICDHistory() {
            var nameWidth = this.windowWidth - 270 - 90
            nameWidth = nameWidth < 150 ? 150 : nameWidth
            let rs = [
                {
                    headerName: this.$t('emr.appt.code_ICD'),
                    field: 'code',
                    width: 100,
                },
                {
                    headerName: this.$t('emr.appt.name_ICD'),
                    field: 'label',
                    width: nameWidth,
                },
                {
                    headerName: this.$t('emr.appt.remarks_ICD'),
                    field: 'notes',
                    width: 200,
                },
            ]
            return rs
        },
        columnMedicineHistory() {
            var nameWidth = this.windowWidth - 810 - 70
            nameWidth = nameWidth < 150 ? 150 : nameWidth
            let rs = [
                {
                    headerName: this.$t('emr.appt.code_p'),
                    field: 'med_info_id',
                    width: 100,
                },
                {
                    headerName: this.$t('emr.appt.name_p'),
                    field: 'medical_en_name',
                    width: nameWidth,
                },
                {
                    headerName: this.$t('emr.appt.unit_p'),
                    field: 'unit',
                    width: 80,
                },
                {
                    headerName: this.$t('emr.appt.dose_p'),
                    field: 'dose',
                    width: 80,
                },
                {
                    headerName: this.$t('emr.appt.frequency_p'),
                    field: 'med_freq_id',
                    width: 80,
                },
                {
                    headerName: this.$t('emr.appt.route_p'),
                    field: 'med_route_id',
                    width: 80,
                },
                {
                    headerName: this.$t('emr.appt.days_p'),
                    field: 'days',
                    width: 80,
                },
                {
                    headerName: this.$t('emr.appt.total_p'),
                    field: 'total_dose',
                    width: 80,
                },
                {
                    headerName: this.$t('emr.appt.daily_p'),
                    field: 'daily_dose',
                    width: 80,
                },
                {
                    headerName: this.$t('emr.appt.notes_p'),
                    field: 'notes',
                    width: 150,
                },
            ]
            return rs
        },
        partnerid() {
            return this.$store.getters.partner
        },
        selectedPatient() {
            return this.$store.state.selectedPatient
        },
        mrid() {
            return this.$store.getters.mrid
        },
        doctortype() {
            return this.$store.state.doctortype
        },
        did() {
            return this.$store.getters.did
        },
        // 醫師姓名
        name() {
            return this.$store.getters.name
        },
        partnerInfo() {
            return JSON.parse(localStorage.getItem('partner_info'))
        },
        title() {
            return this.$store.state.title
        },
        dname() {
            return this.$store.getters.name
        },
        dmid() {
            return this.$store.state.mid
        },
        // 建議紀錄
        memo_p: {
            get() {
                return this.$store.getters.memoP
            },
            set(val) {
                this.$store.commit('SET_MEMO_P', val)
            },
        },
        // 病患主觀自覺徵候
        memo_s: {
            get() {
                return this.$store.getters.memoS
            },
            set(val) {
                this.$store.commit('SET_MEMO_S', val)
            },
        },
        // 醫療人員客觀診斷評估
        memo_o: {
            get() {
                return this.$store.getters.memoO
            },
            set(val) {
                this.$store.commit('SET_MEMO_O', val)
            },
        },
        // 會員資料
        userBaseData() {
            return this.$store.getters.userEMRBaseData[0]
        },
        // 個人病史
        userEMRPersonSickData() {
            if (this.$i18n.locale == 'en') {
                return this.$store.getters.userEMRPersonSickDataE
            } else {
                return this.$store.getters.userEMRPersonSickData
            }
        },
        // 家族慢性病史
        userEMRParentsSickData() {
            if (this.$i18n.locale == 'en') {
                return this.$store.getters.userEMRParentsSickDataE
            } else {
                return this.$store.getters.userEMRParentsSickData
            }
        },
        // 家族癌症病史
        userEMRParentsCancerData() {
            if (this.$i18n.locale == 'en') {
                return this.$store.getters.userEMRParentsCancerDataE
            } else {
                return this.$store.getters.userEMRParentsCancerData
            }
        },
        // 過敏事項
        userEMRSensitiveMattersData() {
            if (this.$i18n.locale == 'en') {
                return this.$store.getters.userEMRSensitiveMattersDataE
            } else {
                return this.$store.getters.userEMRSensitiveMattersData
            }
        },
        // 是否在通話中
        calling() {
            if (this.$store.getters.videoPath) {
                this.$store.commit('SET_CALLING', true)
            }
            return this.$store.getters.calling
        },
        // 完診狀態
        finishStatus() {
            return this.$store.getters.finishStatus
        },
        // 主選單開關
        menuActive: {
            get() {
                if (this.$store.state.windowWidth > 600) return true
                return this.menu
            },
            set(val) {
                this.menu = val
            },
        },
        device() {
            return this.$store.getters.device
        },
        room_id() {
            return this.$store.getters.roomid
        },
        p2p_queue_id() {
            return localStorage.getItem('p2p_queueid')
        },
        syncTriggle: {
            get() {
                return this.$store.getters.syncTriggle
            },
            set(val) {
                this.$store.commit('SET_SYNC_TRIGGLE', val)
            },
        },
    },
    methods: {
        // 取看診紀錄 {pid: this.pid, partnerid: this.partnerid, page: this.queueHistoryPage}
        getQueueHistory(payload) {
            this.$store.dispatch('getQueueHistory', payload).then((res) => {
                if (res.data.status === 'OK') {
                    this.queueHistory = _.concat(this.queueHistory, res.data.data)
                }
            })
        },
        // 開啟看診紀錄
        openQueueHistoryPopup(data) {
            this.queueHistoryParams = data
            this.historyICD = []
            this.historyMedicine = []
            this.historyApply.memo_o = false
            this.historyApply.json_a = false
            this.historyApply.json_p = false
            this.historyApply.memo_p = false
            if (data.json_a) {
                data.json_a.forEach((v) => {
                    this.historyICD.push({
                        code: v.disease_code,
                        label: v.ename,
                        notes: v.notes,
                    })
                })
            }
            if (data.json_p) {
                data.json_p.forEach((v) => {
                    this.historyMedicine.push({
                        med_info_id: v.med_info_id,
                        medical_en_name: v.medical_en_name,
                        unit: v.unit,
                        dose: v.dose,
                        med_freq_id: v.med_freq_id,
                        med_route_id: v.med_route_id,
                        days: v.days,
                        total_dose: v.total_dose,
                        daily_dose: v.daily_dose,
                        notes: v.notes,
                    })
                })
            }
            this.queueHistoryPopup = true
        },
        getPatientInfoByPid(pid) {
            const payload = {
                spid: pid,
            }
            getPatientInfoByPid(payload).then((res) => {
                if (res.data.status == 'OK') {
                    this.pInfo = res.data.data
                }
            })
        },
        // 取 生命健康數值
        getPersonSign() {
            var _self = this
            axios
                .get(process.env.VUE_APP_API_URL + '/admin/device_updatedata_by_pid/' + parseInt(_self.partnerid) + '/' + _self.pid)
                .then((res) => {
                    if (res.data.status == 'Success') {
                        fetch(process.env.VUE_APP_API_URL + '/admin/personalsign/' + _self.pid)
                            .then((res) => {
                                return res.json()
                            })
                            .then((jsonData) => {
                                let array = []
                                array.push(jsonData.sign1)
                                array.push(jsonData.sign2)
                                array.push(jsonData.sign3)
                                _self.$store.commit('SET_LIFE_HEALTH_DATA', array)
                            })
                            .catch((err) => {
                                console.log('健康數據錯誤:', err)
                            })
                    } else {
                        console.log('amor err > ', res.data.message)
                    }
                })
                .catch((error) => {
                    console.log('health err ', error)
                })
                .then(() => {
                    _self.lifeLoadingFlag = false
                })
        },
        // 取醫生馬上看生化檢驗
        fetchCurdrBioData() {
            var _self = this
            _self.$store
                .dispatch('fetchPersonBaseSign', { spid: _self.pid })
                .then((res) => {
                    let array = []
                    array.push(res.data.data.sign5)
                    array.push(res.data.data.sign6)
                    array.push(res.data.data.sign7)
                    array.push(res.data.data.sign8)
                    array.push(res.data.data.sign9)
                    array.push(res.data.data.sign10)
                    array.push(res.data.data.sign11)
                    array.push(res.data.data.sign12)
                    _self.$store.commit('SET_ALL_BIO_DATA', array)
                })
                .then(() => {
                    _self.bioDataLoadingFlag = false
                })
        },
        // 取振興生化檢驗
        fetchChghBioData() {
            var _self = this
            gethospitalbiodata(_self.pid)
                .then((res) => {
                    if (res.data.items[0] != '此身分證(居留證、護照號碼)的病患無權限進行查詢') {
                        var all_bio_data = JSON.parse(res.data.items[0])
                        var codename = []
                        // 取得所有codename
                        all_bio_data.forEach((all_bio_data_codename) => {
                            codename.push(all_bio_data_codename.chapcodename)
                        })
                        // 篩選重複的codename filter
                        var codename_result = codename.filter(function (element, index, arr) {
                            return arr.indexOf(element) === index
                        })
                        _self.$store.commit('SET_ALL_BIO_DATA_CODENAME', codename_result)
                        _self.$store.commit('SET_ALL_BIO_DATA', all_bio_data)
                    } else {
                        _self.$store.commit('SET_ALL_BIO_DATA_CODENAME', [])
                        _self.$store.commit('SET_ALL_BIO_DATA', [])
                    }
                })
                .then(() => {
                    _self.bioData_chghLoadingFlag = false
                })
        },
        // 取屏基生化檢驗
        fetchPtchBioData() {
            var _self = this
            getHospitalPtchBioData({ spid: _self.pid })
                .then((res) => {
                    if (res.data.status == 'OK') {
                        let array = _.cloneDeep(res.data.items)
                        _self.$store.commit('SET_ALL_BIO_DATA', array)
                    }
                })
                .then(() => {
                    _self.bioData_standardLoadingFlag = false
                })
        },
        // 取華育生殖檢驗報告
        getFertilityReport(mid) {
            let payload = {
                mid: mid,
            }
            getFertilityReport(payload).then((res) => {
                if (res.data.status == 200) {
                    this.fertilityReport = _.cloneDeep(res.data.data.data)
                } else {
                    console.log('取華育生殖檢驗報告失敗')
                }
            })
        },
        // 匯入看診紀錄
        importHistory() {
            if (this.historyApply.memo_o) this.$bus.$emit('importHistory', { key: 'memo_o', value: this.queueHistoryParams.memo_o })
            if (this.historyApply.memo_p) this.$bus.$emit('importHistory', { key: 'memo_p', value: this.queueHistoryParams.memo_p })
            if (this.historyApply.json_a) this.$bus.$emit('importHistory', { key: 'json_a', value: this.historyICD })
            if (this.historyApply.json_p) this.$bus.$emit('importHistory', { key: 'json_p', value: this.historyMedicine })
            this.queueHistoryPopup = false
        },
        // 視窗通知
        notify(color, title, text) {
            this.$vs.notify({
                color: color,
                title: title,
                text: text,
                position: 'top-center',
            })
        },
        // 匯出處方籤 TXT檔
        exportTxt() {
            // 取QRCode內容
            this.$store.dispatch('getRxString', { mr_id: this.mrid }).then((res) => {
                stringToTxt.getTxt(res.data.data, 'test')
            })
        },
        // 匯出處方籤 Email
        exportEmail(name, email) {
            // 取QRCode內容
            this.$store.dispatch('getRxString', { mr_id: this.mrid }).then((res) => {
                let payload = {
                    mr_id: this.mrid,
                    toEmail: email,
                    toName: name,
                    contents: res.data.data,
                }
                // 發送電子郵件
                sendPrescription(payload).then(() => {
                    this.notify('grey', this.$t('message.send_success'), '')
                })
            })
        },
        // 匯出處方籤 QRCode
        exportQRCode() {
            // 取QRCode內容
            this.$store.dispatch('getRxString', { mr_id: this.mrid }).then((res) => {
                this.imgSrc = this.QRCode(res.data.data)
                this.QRCodePopup = true
            })
        },
        // 產生QRCode
        QRCode(content, width = 120, height = 120) {
            content = encodeURIComponent(content)
            return 'http://chart.apis.google.com/chart?cht=qr&chl=' + content + '&chs=' + width + 'x' + height
        },
        // 開啟處方簽
        openRxPopup() {
            this.RXPopup = true
            document.body.style.overflow = 'hidden'
        },
        // 關閉處方簽
        closeRxPopup() {
            this.RXPopup = false
            document.body.style.overflow = 'visible'
        },
        // 開啟訊息視窗
        showChatPopup(mid, name) {
            const data = {
                smid: mid,
                sname: name,
            }
            this.$bus.$emit('chat', data)
        },
        // 轉接醫生
        diverDoctor(did) {
            var _self = this
            _self.selectdid = did
            _self.notify('grey', _self.$t('emr.transferTip'))
            getCallType(this.partnerid).then((res) => {
                if (res.data.is_p2p == 1) {
                    getMidByDid(did).then((res) => {
                        this.call_p2p(1, this.selectedPatient.mid, res.data.data.mid)
                    })
                } else {
                    if (_self.phone_status == 0) {
                        let payload = {
                            devicetype: _self.device,
                            todid: _self.selectdid,
                            frompid: _self.pid,
                            qid: _self.qid,
                            partnerid: _self.partnerid,
                        }
                        diverDoctor(payload).then(() => {
                            _self.selectdid = null
                        })
                        _self.phone_status = 1
                    }
                    setTimeout(() => {
                        _self.phone_status = 0
                    }, 5000)
                }
            })
        },
        callCareGiver(mid) {
            this.call_p2p(0, mid)
        },
        // p2p通話 is_doctor 0=民眾 1=醫事人員
        async call_p2p(is_doctor, pmid, dmid = 0) {
            let route = _.replace(_.lowerCase(_.split(this.$route.fullPath, '/', 2)[1]), ' ', '')
            var payload = {
                partner_id: this.partnerid,
                is_doctor: is_doctor,
                to_mid: pmid,
                src: route,
                queue_id: this.qid,
            }

            if (dmid > 0) {
                payload['doc_mid'] = dmid
                var mid = dmid
            } else {
                var mid = pmid
            }

            if (this.snParams) payload.sn_id = this.snParams.id
            await call_p2p(payload).then((res) => {
                if (res.data.status == 'OK') {
                    // $('#p2p').show();
                    this.$store.commit('SET_CALL_ID', res.data.callId)
                    this.$store.commit('SET_LOCAL_ID', 'curdr_' + this.partnerid + '_' + this.dmid + '_1')
                    this.$store.commit('SET_REMOTE_ID', 'curdr_' + this.partnerid + '_' + mid + '_' + is_doctor)
                    this.$store.commit('SET_CALLING', true)
                    this.$store.commit('SET_ROOM_ID', res.data.room_Id)
                    this.$store.commit('SET_QUEUE_ID', res.data.queue_id)
                    remoteID = res.data.remote_Id
                    console.log('Emr.vue call_p2p function callID => ', callID)
                    callID = res.data.callId
                    console.log('Emr.vue call_p2p function remoteID => ', remoteID)
                    // p2p_call(false, res.data.room_Id, res.data.callId, res.data.remote_Id, false,res.data.caller, true, this.UUID);
                    localStorage.setItem('p2p_queueid', res.data.queue_id)
                    localStorage.setItem('p2p_tomid', this.dmid)
                    let send_data = {
                        queue_id: res.data.queue_id,
                    }
                    p2pCallData = send_data
                    this.$emit('closePopup')
                    if (this.$router.currentRoute.fullPath != '/emr2/' + this.pid + '/' + res.data.queue_id) {
                        this.$router.push('/emr2/' + this.pid + '/' + res.data.queue_id)
                    }
                } else {
                    console.log('call ERROR: ' + res.data.msg)
                }
            })
        },
        // 更新 完診狀態
        updateFinishStatus(to = '/apptmgmt/1') {
            this.$store.dispatch('updateDoctorStatus', 1000)
            let payload = { qid: this.qid, finish: 1 }
            updateFinishStatus(payload).then((res) => {
                if (res.data.status === 'OK') {
                    this.notify('grey', this.$t('FinishTxt'), '')
                    this.passFlag = true
                    this.doctorLeavePopup = false
                    this.$router.push(to)
                }
            })
        },
        // 通話中 如果點到別的掛號會出現這個按鈕 返回通話中掛號
        backToCallingQueue() {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('di_tokenId')
            axios
                .get(process.env.VUE_APP_API_URL_HIS + '/api/getPatientInfoByQid/' + this.p2p_queue_id)
                .then((res) => {
                    let pid = res.data.data.pid
                    this.$router.push('/emr2/' + pid + '/' + this.p2p_queue_id)
                })
                .catch((error) => {
                    console.error('Error fetching patient info:', error)
                })
        },
        // 開關目錄 (手機版)
        showSidebar() {
            this.menu = !this.menu
        },
        // 選擇目錄
        selectMenu(tab) {
            //換主選單時關閉
            this.$store.commit('SET_SYNC_SWITCH', false)
            this.emrIndex = tab
            this.menu = false
        },
        // 離開 (回掛號管理)
        exit() {
            this.$router.push('/apptmgmt/1')
        },
        // 醫師未完診離開
        exitUnfinish() {
            this.passFlag = true
            this.doctorLeavePopup = false
            this.$router.push(this.to)
        },
        //從會員管理到病歷頁籤
        toDicomTabs() {
            var _self = this
            this.emrIndex = this.$route.query.index
            if (this.$route.query.index == 'dicomData') {
                let shortcut = setInterval(function () {
                    document.getElementById(_self.$route.query.tab).click()
                    clearInterval(shortcut)
                }, 500)
            }
        },
        // 開啟 掛號視窗
        showApptPopup(index, pid = null, pname = null) {
            const data = {
                apptMode: index,
                patient: {
                    pid: pid ? parseInt(pid) : null,
                    name: pname,
                },
            }
            this.$bus.$emit('appt', data)
        },
        // 轉接搜尋合作醫事人員
        DoctorListByPartner() {
            var _self = this
            var payload = {
                did: this.did,
                partnerid: localStorage.getItem('partnerid'),
                query: '',
            }
            QueryDoctorListByPartner(payload).then((res) => {
                if (res.data.status == 'OK') {
                    _self.contacts = res.data.data
                }
            })
        },
        // 轉接醫師
        truncateString(dname, str) {
            if (!str) {
                return '(無科別)'
            }
            if (str.length <= 15) {
                return str
            } else {
                return str.slice(0, 15) + '...'
            }
        },
        autoUpdateAppt() {
            var _self = this
            _self.autoUpdateApptInterval = setInterval(function () {
                _self.checkPatientInRoom()
            }, 5000)
        },
        // 定期去呼叫房間裡有沒有病人（只監聽有無病人，醫事人員不會回應！！）
        checkPatientInRoom() {
            if (this.room_id == 0) return
            const payload = {
                room_id: this.room_id,
                partner_id: this.partnerid,
                mid: this.selectedPatient.mid,
            }
            this.$store.dispatch('checkPatientInRoom', payload)
        },
        // 開啟 選擇邀請對象視窗
        openInviteFamilyPopup() {
            this.inviteFamilyActive = true
        },
        // 關閉 選擇邀請對象視窗
        closeInviteFamilyPopup() {
            this.inviteFamilyActive = false
        },
        // 通知並邀請民眾與民眾家屬加入視訊
        inviteAndNotifyFamily(pid, pname, index = null) {
            const payload = {
                qid: this.qid,
                spid: pid,
                partner_id: this.partnerid,
                notify_title: this.$t('stream.notify_title', {
                    dname: this.dname + this.title.cname,
                }),
                notify_content: this.$t('stream.notify_content', {
                    dname: this.dname + this.title.cname,
                    pname: pname,
                }),
                msg: this.$t('stream.msg', {
                    dname: this.dname + this.title.cname,
                    pname: pname,
                }),
            }
            sendNotifyAndVideoLink(payload).then((res) => {
                if (res.data.status == 'OK') {
                    if (index == null) {
                        this.pInfo.inviteFlag = true
                    } else {
                        this.pInfo.careGiver[index].inviteFlag = true
                    }
                    this.$store.dispatch('notify', {
                        color: 'grey',
                        title: '邀請成功',
                    })
                    this.$forceUpdate()
                }
            })
            this.closeInviteFamilyPopup()
        },
        showExportRX() {
            this.ExportRX = true
        },
        // 前往某一筆掛號的掛號主頁(目前沒用)
        toMainQueue(pid, queue_id) {
            this.$router.push('/emr2/' + pid + '/' + queue_id)
        },
        // 開啟 醫療影像上傳視窗
        showUploadPopup() {
            document.body.style.overflow = 'hidden'
            this.nameUpload = this.selectedPatient.name
            this.openUploadPopup = true
        },
        // 關閉 醫療影像上傳視窗
        closeUploadPopup() {
            document.body.style.overflow = 'visible'
            this.openUploadPopup = false
        },
        // 通話
        call(ring = 1, direction = 1) {
            const payload = { ring: ring, direction: direction }
            if (this.queue) {
                payload.queue_id = this.queue.queue_id
                if (this.queue.cs_id > 0 && this.queue.proxy_call_did != this.did) {
                    // 會診 打給另一個醫師
                    payload.mid = this.queue.callback_doctor.mid
                    payload.name = this.queue.callback_doctor.family_name + this.queue.callback_doctor.name
                    payload.is_doctor = 1
                } else if (this.queue.cs_id > 0 && this.queue.did != this.did) {
                    // 會診 打給另一個醫師
                    payload.mid = this.queue.doctor_info.mid
                    payload.name = this.queue.doctor_info.family_name + this.queue.doctor_info.name
                    payload.is_doctor = 1
                } else if (this.queue.cs_id == 0 && this.queue.proxy_call_did > 0) {
                    // 代理掛號 打給掛號時指定的醫事人員
                    payload.mid = this.queue.callback_doctor.mid
                    payload.name = this.queue.callback_doctor.family_name + this.queue.callback_doctor.name
                    payload.is_doctor = 1
                } else {
                    // 打給民眾
                    payload.mid = this.queue.patient_info.mid
                    payload.name = this.queue.patient_info.name
                    payload.is_doctor = 0
                }
            } else {
                // 打給民眾
                payload.mid = this.selectedPatient.mid
                payload.name = this.selectedPatient.name
                payload.is_doctor = 0
            }
            this.$store.dispatch('call_p2p', payload)
        },
        // 開啟 邀請視窗
        showInvitePopup(qid, pid, pmid) {
            const data = {
                qid: qid,
                pid: pid,
                mid: pmid,
            }
            this.$bus.$emit('invite', data)
        },
    },
    beforeRouteLeave(to, from, next) {
        this.queueHistoryPopup = false
        this.QRCodePopup = false
        this.patientNotesPopup = false
        console.log('to', to.path)
        this.to = ''
        if (this.doctortype == 1 && this.queue && this.queue.finish == 0 && this.queue.closed == 0 && this.passFlag == false) {
            this.doctorLeavePopup = true
            this.to = to.path
        } else {
            this.$store.dispatch('getTodayQueuesUndoneQuantity')
            next()
        }
    },
    destroyed() {
        // clearInterval(this.mcuApiSetInterval)
        clearInterval(this.autoUpdateApptInterval)
        this.$store.commit('SET_SELECTED_PATIENT_DATA', null)
        this.$store.commit('SET_ACTIVE_PATIENT_INFO', null)
        this.$store.commit('SET_LIFE_HEALTH_DATA', [])
        this.$store.commit('SET_ALL_BIO_DATA_CODENAME', [])
        this.$store.commit('SET_ALL_BIO_DATA', [])
        this.$store.commit('SET_MEMO_P', '')
        this.$store.commit('SET_MEMO_S', '')
        this.$store.commit('SET_MEMO_O', '')
        this.$store.commit('SET_MR_ID', 0)
        this.$store.commit('SET_QUEUE_ID', 0)
        this.$store.commit('SET_ROOM_ID', 0)
        this.$store.commit('SET_QUEUE_DETAIL_PMEMO', '')
        localStorage.removeItem('calling')
    },
}
</script>
<style lang="scss">
@import '@/assets/scss/vuexy/apps/chat.scss';
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-balham.css';
</style>
