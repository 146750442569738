<template>
    <div>
        <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                <div class="vx-card sm:p-4 p-2">
                    <!-- 資料載入中 -->
                    <h5 v-if="loadingFlag" class="my-2 text-center">{{$t('loading')}}</h5>
                    <!-- 資料 -->
                    <vs-tabs class="tabs-shadow-none" v-else-if="!loadingFlag && bioData.length>0">
                        <vs-tab class="flex" :label="data.tab_name" v-for="(data,index) in bioData" :key="index">
                            <!-- 螢幕寬度大於等於768px :subtitle="item.name" -->
                            <vs-list v-if="data.item && windowWidth>=768">
                                <vs-list-item :title="item.title" :subtitle="item.subtitle" v-for="(item,index) in data.item" :key="index">
                                    <vs-row>
                                        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
                                            <p class="cd-date">{{ (item.datetime=='' || item.datetime==null) ? '' : item.datetime }}</p>
                                            <p class="ml-2" :class="item.value_class">{{ (item.value=='' || item.value==null) ? '-' : item.value }}</p>  
                                            <vs-button class="ml-2" icon="show_chart" @click="updateChart(item.title, item.data)"></vs-button>
                                        </vs-col>
                                    </vs-row>
                                </vs-list-item>
                            </vs-list>
                            <!-- 螢幕寬度小於768px -->
                            <vs-list v-else-if="data.item && windowWidth<768">
                                <vs-list-item class="cd-list-item-sm w-full" :title="item.title" :subtitle="item.subtitle" v-for="(item,index) in data.items" :key="index">
                                    <vs-row>
                                        <vs-col class="mb-2" vs-type="flex" vs-justify="flex-end" vs-align="center">
                                            <p class="ml-2" :class="item.value_class">{{ (item.value=='' || item.value==null) ? '-' : item.value }}</p>
                                        </vs-col>
                                        <vs-col vs-type="flex" vs-justify="space-between" vs-align="center">
                                            <p class="cd-date">{{ (item.datetime=='' || item.datetime==null) ? '' : item.datetime }}</p>
                                            <vs-button class="sm:ml-3" icon="show_chart" @click="updateChart(item.title, item.data)"></vs-button>
                                        </vs-col>
                                    </vs-row>
                                </vs-list-item>
                            </vs-list>
                        </vs-tab>
                    </vs-tabs>
                    <!-- 沒資料 -->
                    <div class="cd-nodata" v-else>
                        {{$t('nodata')}}
                    </div>
                </div>
            </vs-col>
        </vs-row>
        <!-- 圖表 視窗 -->
        <vs-popup :title="$t('Chart')" :active.sync="popupActivChart">
            <vue-apex-charts type="area" height="400vh" ref="lineChart" :options="chart.chartOptions" :series="chart.series"></vue-apex-charts>
        </vs-popup>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        VueApexCharts,
    },
    props: {
        loadingFlag: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            popupActivChart: false,
            chart: {
                series: [{
                    name: '身高(CM)',
                    data: []
                }],
                chartOptions: {
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    colors: this.themeColors,
                    xaxis: {
                        type: 'datetime',
                        categories: [],
                    },
                    tooltip: {
                        x: {
                            format: 'yyyy-MM-dd'
                        },
                    }
                }
            }
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.windowWidth
        },
        // emrSettingArray() {
        //     return this.$store.getters.emrTabSetting[2].contents
        // },
        bioData() {
            return this.$store.getters.bioData
        },
    },
    methods: {
        // 開啟 圖表 視窗
        updateChart(title, data){
            var _self = this
            if(data.length < 1){
                _self.$vs.dialog({
                    color: 'primary',
                    title: _self.$t('DataNotEnough'),
                    text: _self.$t('CannotDraw'),
                    accept:_self.acceptAlert
                })
            }else{
                _self.chart.series[0].name = title
                const newData = data.map((item) => {
                    return item.value
                })
                const newOption = data.map((item) => {
                    return item.datetime
                })
                _self.chart.series = [{
                    data: newData
                }]
                _self.chart.chartOptions = {
                    xaxis: { 
                        categories: newOption
                    }
                }
                _self.popupActivChart = true
            }
        },
    },
}
</script>