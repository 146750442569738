<template>
  <vs-popup fullscreen :title="title" :active.sync="popupPdfCommon" style="margin:0px;padding:0px;overflow:hidden">
    <!-- 圖片 -->
    <img :src="pdfurl" class="w-full">
  </vs-popup>
</template>

<script>
import { medicalFileSync } from '@/api/user'
export default {
  props: ['generalphototemplatebutton'],
  watch: {
    // 開啟視窗 帶入變數
    generalphototemplatebutton(n,o){
      this.title = n.title
      this.popupPdfCommon = n.status
      //判斷pdf是否為大陸與台灣伺服器
      var url = n.pdfurl
      var url_cn = 'https://www.curdoctor.cn/upload/report/'+n.pdfurl[0]
      var request = new XMLHttpRequest();
      var _self = this
      request.open('GET', url, true);
      request.onreadystatechange = function(){
          if (request.readyState === 4){
            if(request.status==200){
              _self.pdfurl = url
            }else{
              _self.pdfurl = url_cn
            }
          }
      };
      request.send();
    },
    // 醫療影像同步 關閉視窗
    syncTriggle(v) {
      if(v=='closedialog'){
        this.popupPdfCommon = false
      }
    },
    // 開啟照片視窗時 關閉背景的scrollbar
    popupPdfCommon(v){
      // 醫療影像同步 關閉視窗
      if(v==false && this.syncSwitch==true){
        // this.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'closedialog'})
        medicalFileSync({qid: this.qid, trigger: 'closedialog'})
      }
      this.backgroundScrollbarSwitch(v)
    },
  },
  data(){
    return{
      title:null,
      pdfurl:null,
      popupPdfCommon: false,
    }
  },
  methods: {
    // 背景scrollbar開關
    backgroundScrollbarSwitch(val){
      if(val==true){
        document.body.style.overflow = 'hidden'
      }else{
        document.body.style.overflow = 'scroll'
      }
    },
  },
  computed: {
    syncTriggle(){
      return this.$store.getters.syncTriggle
    },
    syncSwitch: {
      get() {
        return this.$store.getters.syncSwitch
      },
    },
    device() {
      return this.$store.getters.device
    },
    qid: {
      get() {
        return this.$store.getters.qid
      },
      set(val) {
        this.$store.commit('SET_QUEUE_ID', val)
      }
    },
  },
}
</script>