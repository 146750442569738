<template>
  <vs-popup :title="$t('ConsultBtn')" :active.sync="popupOpinionCommon">
    <vx-card>
      <vs-row>
        <vs-col>
          <v-select class=" mb-4 w-full" label="name" v-model="selectdid" :options="contacts" :placeholder="$t('choiceDoctorMember')">
            <template v-slot:option="option">
              {{ option.family_name }}{{ option.name }} ({{ option.did }})
            </template>
          </v-select>
          <vs-textarea class="w-full" id="textareaObject" :placeholder="$t('ConsultDescription')" style="resize:none; overflow:hidden;" v-model="message" @keyup="autogrow()"/>
          <vs-button :size="windowWidth > 576 ? '' :'small'" color="primary" :disabled="selectdid ? false : true" @click="sendOpinion">{{$t('Submit')}}</vs-button>
        </vs-col>
      </vs-row>
    </vx-card>
  </vs-popup>
</template>

<script>
import vSelect from 'vue-select'
import {SaveOpinion} from '../../../api/user'
export default {
  components: {
    vSelect
  },
  props: ['consultdoctorbutton'],
  watch: {
    consultdoctorbutton(n,o){
      this.getType(n.tabName)
      this.selecttypeid = n.typeid
      this.selectpid = n.spid
      this.popupOpinionCommon = n.status
      if(n.type=="12"){
        this.selectTime = n.Time
      }
      if(n.ext){
        this.ext = n.ext
      }
    },
  },
  data(){
    return{
      selectdid: '',
      selecttype:0,
      selecttypeid: 0,
      selectpid:'',
      message: '',
      selectTime:'',
      popupOpinionCommon: false,
      ext:''
    }
  },
  methods: {
    // 送出會診邀請
    sendOpinion() {
      let _self = this
      var data = {
        to_did: _self.selectdid.did,
        spid:_self.selectpid,
        type: _self.selecttype,
        typeid: _self.selecttypeid,
        message: _self.message,
      }
      console.log('data1',data)
      // _self.selecttype=="12" ? data.selectTime = _self.selectTime : console.log(_self.selecttype)
      // 新版DICOM已不使用 _self.type=="2" ? data.modality = _self.modality : console.log(_self.modality)
      _self.selecttype=="5"&&_self.ext=='cha' ? data.modality = _self.ext : console.log(_self.ext)
      SaveOpinion(data).then((res)=>{
        if(res.data.status=='OK'){
          _self.$vs.notify({
            color: 'grey',
            title: _self.$t('message.send_success'),
            text: _self.$t('sendInvitationSuccess'),
            position:'top-center'
          })
        }else{
          _self.$vs.notify({
            color: 'danger',
            title: _self.$t('message.send_error'),
            text: _self.$t('CheckField'),
            position:'top-center'
          })
        }
      }).catch((error) => { 
        console.log(error)
      }).then(() => {
        _self.selectdid = ''
        _self.selecttypeid = 0
        _self.spid = ''
        _self.message = ''
        _self.popupOpinionCommon = false
      })
    },
    // textarea自動增長
    autogrow(){
      var textarea = document.getElementById('textareaObject')
      var adjustedHeight = textarea.clientHeight;
      adjustedHeight = Math.max(textarea.scrollHeight, adjustedHeight);
      if (adjustedHeight > textarea.clientHeight){
        textarea.style.height = adjustedHeight + 'px';
      }
    },
    // 取醫療影像類型 0:患部  1:報告  2:DICOM 3:眼底鏡  4:超音波  5:心電圖  6:耳朵  7:鼻腔  8:咽喉  9:口腔  10:皮膚 11:一般影片 12:心電心音 13:工研院_AI
    getType(name){
      if(name=="userimg"){
        this.selecttype = 0
      }else if(name=="report"){
        this.selecttype = 1
      }else if(name=="dicom"){
        this.selecttype = 2
      }else if(name=="funduscopy"){
        this.selecttype = 3
      }else if(name=="ultraSound"){
        this.selecttype = 4
      }else if(name=="ectrocarDiogram"){
        this.selecttype = 5
      }else if(name=="earTab"){
        this.selecttype = 6
      }else if(name=="noseTab"){
        this.selecttype = 7
      }else if(name=="throatTab"){
        this.selecttype = 8
      }else if(name=="mouthTab"){
        this.selecttype = 9
      }else if(name=="skinTab"){
        this.selecttype = 10
      }else if(name=="video"){
        this.selecttype = 11
      }else if(name=="heartSound"){
        this.selecttype = 12
      }else if(name=="itri"){
        this.selecttype = 13
      }
    },
  },
  computed: {
    contacts() {
      return this.$store.getters.contactsall
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
  },
}
</script>