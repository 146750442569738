<template>
    <div>
        <!-- 飲食管理視窗 -->
        <vs-popup  fullscreen :active.sync="popupActive" @close="close" :title="$t('dietmgmt.DietAnalyze')">
            <div style="padding-bottom:1rem">
                <h5 style="font-size:25px;padding:1rem">{{ $t('dietmgmt.DietInformation') }}</h5>
            </div>
            <vs-col vs-type="flex" vs-justify="flex-end">
            </vs-col>
            <!-- 第幾餐  -->
            <div v-for="(item,index) in nutrients" :key="index"> 
                <span class="px-3" style="font-size:20px">{{numberToWord(index) }}</span>
                <span class="px-3" > {{$t('dietmgmt.MealDescription') }}{{ "：" }} &nbsp;{{ item.descr }}</span> 
                <span class="px-3" >{{$t('dietmgmt.MealTime')  }}{{ "：" }}  {{ item.meal_time }}</span>
                <vs-row style="padding:1.5rem">
                    <!-- 每餐飲食圖片 -->
                    <vs-col class=" mb-base" vs-w="6" vs-xs="12">
                        <div class="inline-block" style="overflow: scroll;" v-for="(data, index2) in item.imgfiles" :key="index2">
                            <img :src="uploadurl+data.new_name" @click="changephoto(index,index2)" class="mr-2" style="height:190px;width:190px;white-space: nowrap;"/>
                        </div>
                    </vs-col>
                    <!-- 每餐十二類食物 -->
                    <vs-col  class=" mb-base" vs-w="6" vs-xs="12">
                        <vs-row>
                            <vs-col  class=" mb-base  px-10" vs-w="3" vs-xs="12"  style="white-space: nowrap;margin: 1rem;">
                                <div class="centerx">
                                {{$t('dietmgmt.WholeMilk')}}<span class="text-danger">{{item.whole_milk }}</span>{{$t('dietmgmt.Units')}}<br>
                                    {{$t('dietmgmt.LowMilk')}}<span class="text-danger">  {{item.low_fat_milk }}</span>{{$t('dietmgmt.Units')}}<br>
                                    {{$t('dietmgmt.SkimMilk')}}<span class="text-danger">  {{item.skim_milk }}</span>{{$t('dietmgmt.Units')}}<br>
                                    {{$t('dietmgmt.Fruit')}}<span class="text-danger">  {{item.fruit }}</span>{{$t('dietmgmt.Units')}}<br>
                                    {{$t('dietmgmt.Vegetables')}}<span class="text-danger">  {{item.vegetables }}</span>{{$t('dietmgmt.Units')}}<br>
                                    {{$t('dietmgmt.Staple')}}<span class="text-danger">  {{item.staple }}</span>{{$t('dietmgmt.Units')}}
                                </div>
                            </vs-col>
                            <vs-col  class=" mb-base px-10" vs-w="3" vs-xs="12" style="white-space: nowrap;margin: 1rem;">
                                <div class="centerx">
                                    {{$t('dietmgmt.HighFatMeat')}}<span class="text-danger">  {{item.high_fat_meat }}</span>{{$t('dietmgmt.Units')}}<br>
                                    {{$t('dietmgmt.MediumFatMeat')}}<span class="text-danger">  {{item.medium_fat_meat }}</span>{{$t('dietmgmt.Units')}}<br>
                                    {{$t('dietmgmt.LowFatMeat')}}<span class="text-danger">  {{item.low_fat_meat }}</span>{{$t('dietmgmt.Units')}}<br>
                                    {{$t('dietmgmt.MediumLegumes')}}<span class="text-danger">  {{item.medium_fat_legumes_product }}</span>{{$t('dietmgmt.Units')}}<br>
                                    {{$t('dietmgmt.LowLegumes')}}<span class="text-danger">  {{item.low_fat_legumes_product }}</span>{{$t('dietmgmt.Units')}}<br>
                                    {{$t('dietmgmt.Grease')}}<span class="text-danger">  {{item.grease }}</span>{{$t('dietmgmt.Units')}}
                                </div>
                            </vs-col>
                        </vs-row>
                    </vs-col>
                </vs-row>
            </div>
            <!-- 分析 -->
            <div style="background-color: #eee;padding:1.5rem">
                <h5 style="font-size:25px;padding:1rem">{{ $t('dietmgmt.analyze') }}</h5><br>
                <span class="text-lg cursor-pointer text-success" @click="OpenStandardPopup()"             
                        style=" solid #003f8e;">{{$t('dietmgmt.UpdateRecomm')}}</span><span style="white-space: nowrap;" class="px-3">{{$t('dietmgmt.Unit')}}</span>
                <!-- 分析後修改 -->
                    <div v-if="dietdoctor.id" style="padding:2rem">
                        <vs-row>
                            <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
                                {{$t('dietmgmt.Carbohydrate')}}   <span style="font-size:20px;white-space: nowrap;padding:1em" class="text-danger">{{ChangeCarbohydrate}}</span>{{ $t('dietmgmt.Gram') }}
                        </vs-col>
                        <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
                            {{$t('dietmgmt.Protein') }}    <span style="font-size:20px;white-space: nowrap;padding:1em" class="text-danger">{{ ChangeProtein }}</span>{{ $t('dietmgmt.Gram') }}
                        </vs-col>
                        <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
                            {{ $t('dietmgmt.Fat')}}    <span style="font-size:20px;white-space: nowrap;padding:1em" class="text-danger">{{ ChangeFat }}</span>{{ $t('dietmgmt.Gram') }}
                        </vs-col>
                        <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
                            {{$t('dietmgmt.Calorie')}}   <span style="font-size:20px;white-space: nowrap;padding:1em" class="text-danger">{{ ChangeCal }}</span>{{ $t('dietmgmt.kcal') }}
                        </vs-col>
                        </vs-row>
                    </div>
                    <!-- 新增資料 -->
                    <div class="flex justify-between" v-else style="padding:2rem">
                    <vs-row>
                        <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
                            {{$t('dietmgmt.Carbohydrate')}}  <span style="font-size:20px;white-space: nowrap;padding:1em" class="text-danger">{{SumCarbohydrate}}</span>{{ $t('dietmgmt.Gram') }}
                        </vs-col>
                        <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
                            {{$t('dietmgmt.Protein')}}    <span style="font-size:20px;white-space: nowrap;padding:1em" class="text-danger">{{ SumProtein }}</span>{{ $t('dietmgmt.Gram') }}
                        </vs-col>
                        <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
                            {{$t('dietmgmt.Fat')}}   <span style="font-size:20px;white-space: nowrap;padding:1em" class="text-danger">{{ SumFat }}</span>{{ $t('dietmgmt.Gram') }}
                        </vs-col>
                        <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
                            {{$t('dietmgmt.Calorie')}}   <span style="font-size:20px;white-space: nowrap;padding:1em" class="text-danger">{{ SumCal }}</span>{{ $t('dietmgmt.kcal') }}
                        </vs-col>
                    </vs-row>
                </div>
                <!--分析後修改  -->
                <div v-if="dietdoctor.id">
                    <vs-row>
                        <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
                            <span class="px-3">{{$t('dietmgmt.WholeMilk')}}</span>
                            <vs-input-number v-model="dietdoctor.whole_milk" class="cd-input-number"></vs-input-number>&nbsp;<span v-if="standard.id" style="white-space: nowrap;"  class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.whole_milk }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span><br>
                            <span class="px-3">{{$t('dietmgmt.LowMilk')}}</span>
                            <vs-input-number v-model="dietdoctor.low_fat_milk"   class="cd-input-number"/>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.low_fat_milk }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span><br>
                            <span class="px-3">{{$t('dietmgmt.SkimMilk')}}</span>
                            <vs-input-number v-model="dietdoctor.skim_milk"  class="cd-input-number"/>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.skim_milk }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span><br>
                            <span class="px-5">{{$t('dietmgmt.Fruit')}}</span>
                            <vs-input-number v-model="dietdoctor.fruit"   class="cd-input-number"/>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.fruit }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span>
                        </vs-col>
                        <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
                            <span class="px-5">{{$t('dietmgmt.Vegetables')}}</span>
                            <vs-input-number v-model="dietdoctor.vegetables"   class="cd-input-number"/>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.vegetables }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span><br>
                            <span class="px-5">{{$t('dietmgmt.Staple')}}</span>
                            <vs-input-number v-model="dietdoctor.staple"   class="cd-input-number"/>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.staple }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span><br>
                            <span class="px-2">{{$t('dietmgmt.HighFatMeat')}}</span>
                            <vs-input-number v-model="dietdoctor.high_fat_meat"  class="cd-input-number"/>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.high_fat_meat }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span><br>
                            <span class="px-2">{{$t('dietmgmt.MediumFatMeat')}}</span>
                            <vs-input-number v-model="dietdoctor.medium_fat_meat"  class="cd-input-number"/>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.medium_fat_meat }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span>
                        </vs-col>
                        <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
                            <span class="px-2">{{$t('dietmgmt.LowFatMeat')}}</span>
                            <vs-input-number v-model="dietdoctor.low_fat_meat"  class="cd-input-number"/>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.low_fat_meat }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span><br>
                            <span class="">{{$t('dietmgmt.MediumLegumes')}}</span>
                            <vs-input-number v-model="dietdoctor.medium_fat_legumes_product"   class="cd-input-number"/>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.medium_fat_legumes_product }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span><br>
                            <span class="">{{$t('dietmgmt.LowLegumes')}}</span>
                            <vs-input-number v-model="dietdoctor.low_fat_legumes_product"  class="cd-input-number"/>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.low_fat_legumes_product }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span><br>
                            <span class="px-5">{{$t('dietmgmt.Grease')}}</span>
                            <vs-input-number v-model="dietdoctor.grease"  class="cd-input-number"></vs-input-number>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.grease  }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span>&nbsp;&nbsp;&nbsp;
                            <span style="white-space: nowrap;">{{$t('dietmgmt.Unit')}}</span>
                        </vs-col>
                        <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
                        <div class="flex justify-between">
                        <span>{{$t('dietmgmt.Suggest')}}</span>
                        <importSetWord :dataType="'diet/all'" @importMsg="importMsg($event,'update')" @closeDietPopup="close"></importSetWord>
                        </div>    
                        <textarea class="w-full cd-diet-textarea" rows="6"  v-model="dietdoctor.notes" ></textarea>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-end">
                            <vs-button  class="cd-button-2w" type="border"  icon-pack="feather" icon="icon-send" @click="insertDietDoctor(2)">{{"儲存"}}</vs-button>
                        </vs-col> 
                    </vs-row>
                </div>
                <!-- 新增資料 預設帶入民眾填寫-->
                <div v-else>
                    <vs-row>
                        <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
                            <span class="px-3" style="white-space: nowrap;">{{$t('dietmgmt.WholeMilk')}}</span>
                            <vs-input-number v-model="total.whole_milk" class="cd-input-number"></vs-input-number>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }}  {{ standard.whole_milk }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span><br>
                            <span class="px-3" style="white-space: nowrap;">{{$t('dietmgmt.LowMilk')}}</span>
                            <vs-input-number v-model="total.low_fat_milk"   class="cd-input-number"/>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.low_fat_milk }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span><br>
                            <span class="px-3" style="white-space: nowrap;">{{$t('dietmgmt.SkimMilk')}}</span>
                            <vs-input-number v-model="total.skim_milk"  class="cd-input-number"/>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.skim_milk }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span><br>
                            <span class="px-5" style="white-space: nowrap;">{{$t('dietmgmt.Fruit')}}</span>
                            <vs-input-number v-model="total.fruit"   class="cd-input-number"/>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.fruit }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span>
                        </vs-col>
                        <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
                            <span class="px-5" style="white-space: nowrap;">{{$t('dietmgmt.Vegetables')}}</span>
                            <vs-input-number v-model="total.vegetables"   class="cd-input-number"/>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.vegetables }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span><br>
                            <span class="px-5" style="white-space: nowrap;">{{$t('dietmgmt.Staple')}}</span>
                            <vs-input-number v-model="total.staple"   class="cd-input-number"/>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.staple }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span><br>
                            <span class="px-2" style="white-space: nowrap;">{{$t('dietmgmt.HighFatMeat')}}</span>
                            <vs-input-number v-model="total.high_fat_meat"  class="cd-input-number"/>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.high_fat_meat }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span><br>
                            <span class="px-2" style="white-space: nowrap;">{{$t('dietmgmt.MediumFatMeat')}}</span>
                            <vs-input-number v-model="total.medium_fat_meat"  class="cd-input-number"/>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.medium_fat_meat }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span>
                        </vs-col>
                        <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
                            <span class="px-2" style="white-space: nowrap;">{{$t('dietmgmt.LowFatMeat')}}</span>
                            <vs-input-number v-model="total.low_fat_meat"  class="cd-input-number"/>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.low_fat_meat }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span><br>
                            <span  style="white-space: nowrap;">{{$t('dietmgmt.MediumLegumes')}}</span>
                            <vs-input-number v-model="total.medium_fat_legumes_product"   class="cd-input-number"/>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.medium_fat_legumes_product }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span><br>
                            <span  style="white-space: nowrap;">{{$t('dietmgmt.LowLegumes')}}</span>
                            <vs-input-number v-model="total.low_fat_legumes_product"  class="cd-input-number"/>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.low_fat_legumes_product }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span><br>
                            <span class="px-5" style="white-space: nowrap;">{{$t('dietmgmt.Grease')}}</span>
                            <vs-input-number v-model="total.grease"  class="cd-input-number"></vs-input-number>&nbsp;<span v-if="standard.id" style="white-space: nowrap;" class="text-success">{{ $t('dietmgmt.Recomm') }} {{ standard.grease }}</span><span class="text-success" v-else>{{ $t('dietmgmt.notSetRecomm') }}</span>&nbsp;&nbsp;
                            
                        </vs-col>
                        <!-- 建議 -->
                        <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
                        <div class="flex justify-between">
                        <span>{{$t('dietmgmt.Suggest')}}</span>
                        <importSetWord :dataType="'diet/all'" @importMsg="importMsg($event,'insert')"></importSetWord>
                        </div>    
                        <textarea class="w-full cd-diet-textarea" rows="6"  v-model="notes" ></textarea>
                        </vs-col>
                        <!-- 儲存 -->
                        <vs-col vs-type="flex" vs-justify="flex-end">
                            <vs-button  class="cd-button-2w" type="border"  icon-pack="feather" icon="icon-send" @click="insertDietDoctor(1)">{{ $t('dietmgmt.Save') }}</vs-button>
                        </vs-col> 
                    </vs-row>
                </div>
            </div>
        </vs-popup>
        <!-- 修改建議值視窗 -->
        <vs-popup :active.sync="standardPopupActive" :title="$t('dietmgmt.UpdateRecomm')" @close="closeStandardPopup" style="z-index:200001 !important;">
            <!-- 修改 -->
        <div v-if="standard.id">
            <vs-row>
                <vs-col vs-lg="4" vs-sm="6" vs-xs="12">
                    <span class="px-5">{{$t('dietmgmt.WholeMilk')}}</span>
                    <vs-input-number v-model="standard.whole_milk" class="cd-input-number"></vs-input-number><br>
                    <span class="px-5">{{$t('dietmgmt.LowMilk')}}</span>
                    <vs-input-number v-model="standard.low_fat_milk"   class="cd-input-number"/><br>
                    <span class="px-5">{{$t('dietmgmt.SkimMilk')}}</span>
                    <vs-input-number v-model="standard.skim_milk"  class="cd-input-number"/><br>
                    <span class="px-6">{{$t('dietmgmt.Fruit')}}</span>
                    <vs-input-number v-model="standard.fruit"   class="cd-input-number"/><br>
                </vs-col>

                <vs-col vs-lg="4" vs-sm="6" vs-xs="12">
                    <span class="px-6">{{$t('dietmgmt.Vegetables')}}</span>
                    <vs-input-number v-model="standard.vegetables"   class="cd-input-number"/><br>
                    <span class="px-6">{{$t('dietmgmt.Staple')}}</span>
                    <vs-input-number v-model="standard.staple"   class="cd-input-number"/><br>
                    <span class="px-5">{{$t('dietmgmt.HighFatMeat')}}</span>
                    <vs-input-number v-model="standard.high_fat_meat"  class="cd-input-number"/><br>
                    <span class="px-5">{{$t('dietmgmt.MediumFatMeat')}}</span>
                    <vs-input-number v-model="standard.medium_fat_meat"  class="cd-input-number"/><br>
                </vs-col>

                <vs-col vs-lg="4" vs-sm="6" vs-xs="12">
                    <span class="px-5">{{$t('dietmgmt.LowFatMeat')}}</span>
                    <vs-input-number v-model="standard.low_fat_meat"  class="cd-input-number"/><br>
                    <span class="px-5">{{$t('dietmgmt.MediumLegumes')}}</span>
                    <vs-input-number v-model="standard.medium_fat_legumes_product"   class="cd-input-number"/><br>
                    <span class="px-5">{{$t('dietmgmt.LowLegumes')}}</span>
                    <vs-input-number v-model="standard.low_fat_legumes_product"  class="cd-input-number"/><br>
                    <span class="px-5">{{$t('dietmgmt.Grease')}}</span>
                    <vs-input-number v-model="standard.grease"  class="cd-input-number"></vs-input-number>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-end">
                    <vs-button  class="cd-button-2w"    @click="insertDietStandard()">{{$t('dietmgmt.Save')}}</vs-button>
                </vs-col>
            </vs-row>
        </div>
        <!-- 新增 -->
        <div v-else>
            <vs-row>
                <vs-col vs-lg="4" vs-sm="6" vs-xs="12">
                    <span class="px-5">{{$t('dietmgmt.WholeMilk')}}</span>
                    <vs-input-number v-model="whole_milk" class="cd-input-number"></vs-input-number><br>
                    <span class="px-5">{{$t('dietmgmt.LowMilk')}}</span>
                    <vs-input-number v-model="low_fat_milk"   class="cd-input-number"/><br>
                    <span class="px-5">{{$t('dietmgmt.SkimMilk')}}</span>
                    <vs-input-number v-model="skim_milk"  class="cd-input-number"/><br>
                    <span class="px-6">{{$t('dietmgmt.Fruit')}}</span>
                    <vs-input-number v-model="fruit"   class="cd-input-number"/><br>
                </vs-col>

                <vs-col vs-lg="4" vs-sm="6" vs-xs="12">
                    <span class="px-6">{{$t('dietmgmt.Vegetables')}}</span>
                    <vs-input-number v-model="vegetables"   class="cd-input-number"/><br>
                    <span class="px-6">{{$t('dietmgmt.Staple')}}</span>
                    <vs-input-number v-model="staple"   class="cd-input-number"/><br>
                    <span class="px-5">{{$t('dietmgmt.HighFatMeat')}}</span>
                    <vs-input-number v-model="high_fat_meat"  class="cd-input-number"/><br>
                    <span class="px-5">{{$t('dietmgmt.MediumFatMeat')}}</span>
                    <vs-input-number v-model="medium_fat_meat"  class="cd-input-number"/><br>
                </vs-col>

                <vs-col vs-lg="4" vs-sm="6" vs-xs="12">
                    <span class="px-5">{{$t('dietmgmt.LowFatMeat')}}</span>
                    <vs-input-number v-model="low_fat_meat"  class="cd-input-number"/><br>
                    <span class="px-5">{{$t('dietmgmt.MediumLegumes')}}</span>
                    <vs-input-number v-model="medium_fat_legumes_product"   class="cd-input-number"/><br>
                    <span class="px-5">{{$t('dietmgmt.LowLegumes')}}</span>
                    <vs-input-number v-model="low_fat_legumes_product"  class="cd-input-number"/><br>
                    <span class="px-5">{{$t('dietmgmt.Grease')}}</span>
                    <vs-input-number v-model="grease"  class="cd-input-number"></vs-input-number>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-end">
                    <vs-button  class="cd-button-2w"    @click="insertDietStandard()">{{$t('dietmgmt.Save')}}</vs-button>
                </vs-col>
            </vs-row>
        </div>
        </vs-popup>
    </div>
</template>
<script>
import { fetchDietInfo,fetchdietnutrients,insertDietDoctor,getDietStandard,insertDietStandard } from '@/api/user'
import importSetWord from '@/components/importSetWord'
export default {
    components:{
        importSetWord,
    },
    props: {
        dietPopupActive: {
            type: Boolean,
            default: false,
            required: true
        },
        payload: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            dietData: null,
            descr: null,
            cal: null,
            notes: null,
            descrFeeback: null,
            calFeeback: null,
            notesFeeback: null,
            CarbohydrateFeeback : null,
            ProteinFeeback : null,
            FatFeeback : null,
            WaterFeeback : null,
            num:0,
            imagenum:'',
            photo:[],
            imageUrl:'',
            uploadurl:process.env.VUE_APP_UPLOAD+'/diet/',
            settings : {
                maxScrollbarLength : 60,
                wheelSpeed         : 0.30,
            },
            number:0,
            date:'',
            nutrients:[],
            total:[],
            suggest_carbohydrate:0,
            suggest_protein:0,
            suggest_fat:0,
            suggest_cal:0,
            suggesst_cal:0,
            dietdoctor:{},
            standardPopupActive :false,
            whole_milk:1,
            low_fat_milk:1,
            skim_milk:1,
            fruit:1,
            vegetables:1,
            staple:1,
            high_fat_meat:1,
            medium_fat_meat:1,
            low_fat_meat:1,
            medium_fat_legumes_product:1,
            low_fat_legumes_product:1,
            grease:1,
            standard:[],
            notes: '',
        }
    },
    watch: {
        descrFeeback(v){
            if(v == false){
                setTimeout(() => {
                    this.descrFeeback = null
                }, 5000)
            }
        },
        calFeeback(v){
            if(v == false){
                setTimeout(() => {
                    this.calFeeback = null
                }, 5000)
            }
        },
        notesFeeback(v){
            if(v == false){
                setTimeout(() => {
                    this.notesFeeback = null
                }, 5000)
            }
        },
        CarbohydrateFeeback(v){
            if(v == false){
                setTimeout(() => {
                    this.CarbohydrateFeeback = null
                }, 5000)
            }
        },
        ProteinFeeback(v){
            if(v == false){
                setTimeout(() => {
                    this.ProteinFeeback = null
                }, 5000)
            }
        },
        FatFeeback(v){
            if(v == false){
                setTimeout(() => {
                    this.FatFeeback = null
                }, 5000)
            }
        },
        WaterFeeback(v){
            if(v == false){
                setTimeout(() => {
                    this.WaterFeeback = null
                }, 5000)
            }
        },
    },
    mounted() {
    },
    computed: {
        popupActive: {
            get() {
                if(this.dietPopupActive) {
                    this.fetchdietnutrients() 
                    this.getDietStandard(this.payload.mid)
                }
                return this.dietPopupActive
            },
            set(v) {
                return v
            },
        },
        windowWidth() {
        return this.$store.state.windowWidth
        },
        partnerid() {
        return this.$store.getters.partner
        },
        SumWholeMilk(){
        let total = 0;this.nutrients.map((item) => {total += item.whole_milk});return total
        },
        SumLowFatMilk(){
        let total = 0;this.nutrients.map((item) => {total += item.low_fat_milk});return total
        },  
        SumSkimMilk(){
        let total = 0;this.nutrients.map((item) => {total += item.skim_milk});return total
        },  
        SumFruit(){
        let total = 0;this.nutrients.map((item) => {total += item.fruit});return total
        },  
        SumVegetables(){
        let total = 0;this.nutrients.map((item) => {total += item.vegetables});return total
        },  
        SumStaple(){
        let total = 0;this.nutrients.map((item) => {total += item.staple});return total
        },  
        SumHighFatMeat(){
        let total = 0;this.nutrients.map((item) => {total += item.high_fat_meat});return total
        },  
        SumMediumFatMeat(){
        let total = 0;this.nutrients.map((item) => {total += item.medium_fat_meat});return total
        },  
        SumLowFatMeat(){
        let total = 0;this.nutrients.map((item) => {total += item.low_fat_meat});return total
        },  
        SumMediumLegumes(){
        let total = 0;this.nutrients.map((item) => {total += item.medium_fat_legumes_product});return total
        },  
        SumLowLegumes(){
        let total = 0;this.nutrients.map((item) => {total += item.low_fat_legumes_product});return total
        },  
        SumGrease(){
        let total = 0;this.nutrients.map((item) => {total += item.grease});return total
        },
        SumCarbohydrate(){
            let total = 0;total += (this.total.whole_milk+this.total.low_fat_milk+this.total.skim_milk)*12+(this.total.fruit+this.total.staple)*15+this.total.vegetables*5;
            return total
        },
        SumProtein(){
            let total = 0 ; total += (this.total.high_fat_meat+this.total.medium_fat_meat+this.total.low_fat_meat+this.total.medium_fat_legumes_product+this.total.low_fat_legumes_product)*7+this.total.fruit+(this.total.staple)*2+(this.total.whole_milk+this.total.low_fat_milk+this.total.skim_milk)*8;
            return total
        },
        SumFat(){
            let total = 0 ;  total += this.total.whole_milk*8+this.total.low_fat_milk*4+this.total.high_fat_meat*10+(this.total.medium_fat_meat+this.total.medium_fat_legumes_product+this.total.grease)*5+(this.total.low_fat_meat+this.total.low_fat_legumes_product)*3;
            return total
        },
        SumCal(){
            let total = 0;total += this.total.whole_milk*150+this.total.low_fat_milk*120+this.total.skim_milk*80+this.total.fruit*60+this.total.vegetables*25+this.total.staple*70+this.total.high_fat_meat*120+this.total.medium_fat_meat*75+this.total.low_fat_meat*55+this.total.medium_fat_legumes_product*75+this.total.low_fat_legumes_product*55+this.total.grease*45;
            return total
        },
        ChangeCarbohydrate(){
            let total = 0;total += (this.dietdoctor.whole_milk+this.dietdoctor.low_fat_milk+this.dietdoctor.skim_milk)*12+(this.dietdoctor.fruit+this.dietdoctor.staple)*15+this.dietdoctor.vegetables*5;
            return total
        },
        ChangeProtein(){
            let total = 0 ; total += (this.dietdoctor.high_fat_meat+this.dietdoctor.medium_fat_meat+this.dietdoctor.low_fat_meat+this.dietdoctor.medium_fat_legumes_product+this.dietdoctor.low_fat_legumes_product)*7+this.dietdoctor.fruit+(this.dietdoctor.staple)*2+(this.dietdoctor.whole_milk+this.dietdoctor.low_fat_milk+this.dietdoctor.skim_milk)*8;
            return total
        },
        ChangeFat(){
            let total = 0 ;  total += this.dietdoctor.whole_milk*8+this.dietdoctor.low_fat_milk*4+this.dietdoctor.high_fat_meat*10+(this.dietdoctor.medium_fat_meat+this.dietdoctor.medium_fat_legumes_product+this.dietdoctor.grease)*5+(this.dietdoctor.low_fat_meat+this.dietdoctor.low_fat_legumes_product)*3;
            return total
        },
        ChangeCal(){
            let total = 0;total += this.dietdoctor.whole_milk*150+this.dietdoctor.low_fat_milk*120+this.dietdoctor.skim_milk*80+this.dietdoctor.fruit*60+this.dietdoctor.vegetables*25+this.dietdoctor.staple*70+this.dietdoctor.high_fat_meat*120+this.dietdoctor.medium_fat_meat*75+this.dietdoctor.low_fat_meat*55+this.dietdoctor.medium_fat_legumes_product*75+this.dietdoctor.low_fat_legumes_product*55+this.dietdoctor.grease*45;
            return total
        },
        lang() {
            return this.$i18n.locale
        },
    },

    methods: {
    // 取飲食log詳細資料
    fetchDietInfo(){
        fetchDietInfo(this.payload).then(res => {
        this.dietData = _.cloneDeep(res.data.data[0])
        if(this.dietData.doctor_diagnosis){
            this.descr = this.dietData.doctor_diagnosis[0].descr
            this.cal = this.dietData.doctor_diagnosis[0].cal
            this.carbohydrate = this.dietData.doctor_diagnosis[0].carbohydrate
            this.protein = this.dietData.doctor_diagnosis[0].protein
            this.water = this.dietData.doctor_diagnosis[0].water
            this.fat = this.dietData.doctor_diagnosis[0].fat
        }
        this.imagenum=this.dietData.imgFiles.length
        this.photo=this.dietData.imgFiles
        })
    },
        // 匯入常用字句
        importMsg(msg,type){
            // 判斷最後一個字元是不是換行符號
            if(type=="insert"){
                let lastWord = this.notes.split("")[this.notes.split("").length-1]
                if(lastWord != '\n' && lastWord != undefined) {
                    this.notes = this.notes + '\n'
                }
                this.notes += msg
            }else if(type=="update"){
                let lastWord = this.dietdoctor.notes.split("")[this.dietdoctor.notes.split("").length-1]
                if(lastWord != '\n' && lastWord != undefined) {
                    this.dietdoctor.notes = this.dietdoctor.notes + '\n'
                }
                this.dietdoctor.notes += msg
            }
        },
        //關閉dietpopup
        close(){
            this.descr = null
            this.cal = null
            this.notes = ''
            this.descrFeeback = null
            this.calFeeback = null
            this.notesFeeback = null
            this.CarbohydrateFeeback = null
            this.ProteinFeeback = null
            this.FatFeeback = null
            this.WaterFeeback = null
            this.imageUrl = null
            this.imagenum = 0
            this.num = 0
            this.$emit("closePopup", false)
            this.dietdoctor={}
            this.total = []
            this.standard=[]
        },
    //取圖片url
    geturl(index){
            return process.env.VUE_APP_UPLOAD+'/diet/'+this.nutrients[index].imgfiles[0].new_name
    },
    //切換圖片
    changephoto(num,num2){
        this.geturl(num,num2)
    },
    // 視窗通知
    notify(color, title, text){
        this.$vs.notify({
        color: color,
        title: title,
        text: text,
        position:'top-center'
      })
    },
    //取一日飲食資料
    fetchdietnutrients(){
        this.Today = new Date()
        this.partner_id = parseInt(this.partnerid)
        this.date=this.$moment(this.Today).format('YYYY-MM-DD')
        let payload={
            "meal_date":this.payload.date,
            "mid":this.payload.mid,
            "partner_id":this.partner_id
        }
        fetchdietnutrients(payload).then(res => {
        if(res.status==200){
            this.total = res.data.data.total[0]
            this.nutrients = res.data.data.data
            this.pid = res.data.data.pid
            if(res.data.data.dietdoctor){
                this.dietdoctor=res.data.data.dietdoctor
                if(this.dietdoctor.notes==null){
                    this.dietdoctor.notes=''
                }
            }
        }
        })
    },
    //新增修改醫師分析
    insertDietDoctor(num){
        this.suggest_cal=parseInt(this.suggest_cal)
        this.suggest_carbohydrate=parseInt(this.suggest_carbohydrate)
        this.suggest_protein=parseInt(this.suggest_protein)
        this.suggest_fat=parseInt(this.suggest_fat)
        this.partner_id = parseInt(this.partnerid)
        //新增
        if(num==1){
                let payload ={
                "meal_date": this.payload.date,
                "mid":this.payload.mid,
                "partner_id":this.partner_id,
                "notes": this.notes,
                "cal":this.SumCal,
                "carbohydrate":this.SumCarbohydrate,
                "protein":this.SumProtein,
                "fat":this.SumFat,
                "suggest_cal":this.suggest_cal,
                "suggest_carbohydrate":this.suggest_carbohydrate,
                "suggest_protein":this.suggest_protein,
                "suggest_fat":this.suggest_fat,
                    "data":{
                    "whole_milk": this.total.whole_milk,
                    "low_fat_milk": this.total.low_fat_milk,
                    "skim_milk": this.total.skim_milk,
                    "fruit": this.total.fruit,
                    "vegetables": this.total.vegetables,
                    "staple": this.total.staple,
                    "high_fat_meat": this.total.high_fat_meat,
                    "medium_fat_meat": this.total.medium_fat_meat,
                    "low_fat_meat": this.total.low_fat_meat,
                    "medium_fat_legumes_product": this.total.medium_fat_legumes_product,
                    "low_fat_legumes_product": this.total.low_fat_legumes_product,
                    "grease":this.total.grease
                }
            }
        let payload2={ spid:this.pid }
        insertDietDoctor(payload).then(res => {
        if(res.data.status=="OK"){
            this.close()
            this.notify('grey', this.$t('message.send_success'))
            this.$store.dispatch('fetchDietChartData',payload2)
            

        }else{
            this.close()
            this.notify('grey', this.$t('message.send_error'))
            this.$store.dispatch('fetchDietChartData',payload2)
        }
        })

        //修改
        }else if(num==2){
            this.dietdoctor.cal = parseInt(this.dietdoctor.cal)
            this.dietdoctor.carbohydrate = parseInt(this.dietdoctor.carbohydrate)
            this.dietdoctor.protein = parseInt(this.dietdoctor.protein)
            this.dietdoctor.fat = parseInt(this.dietdoctor.fat)
            let payload ={
            "meal_date": this.payload.date,
            "mid":this.payload.mid,
            "partner_id":this.partner_id,
            "notes": this.dietdoctor.notes,
            "cal":this.ChangeCal,
            "carbohydrate":this.ChangeCarbohydrate,
            "protein":this.ChangeProtein,
            "fat":this.ChangeFat,
            "suggest_cal":this.suggest_cal,
            "suggest_carbohydrate":this.suggest_carbohydrate,
            "suggest_protein":this.suggest_protein,
            "suggest_fat":this.suggest_fat,
            "data":{
            "whole_milk": this.dietdoctor.whole_milk,
            "low_fat_milk": this.dietdoctor.low_fat_milk,
            "skim_milk": this.dietdoctor.skim_milk,
            "fruit": this.dietdoctor.fruit,
            "vegetables": this.dietdoctor.vegetables,
            "staple": this.dietdoctor.staple,
            "high_fat_meat": this.dietdoctor.high_fat_meat,
            "medium_fat_meat": this.dietdoctor.medium_fat_meat,
            "low_fat_meat": this.dietdoctor.low_fat_meat,
            "medium_fat_legumes_product": this.dietdoctor.medium_fat_legumes_product,
            "low_fat_legumes_product": this.dietdoctor.low_fat_legumes_product,
            "grease":this.dietdoctor.grease
            }
        }
        let payload2={ spid:this.pid }
            insertDietDoctor(payload).then(res => {
            if(res.data.status=="OK"){
                this.close()
                this.notify('grey', this.$t('message.send_success'))
                this.$store.dispatch('fetchDietChartData',payload2)
    
            }else{
                this.close()
                this.notify('grey', this.$t('message.send_error'))
                this.$store.dispatch('fetchDietChartData',payload2)
            }
            })
        }
    },
    //取建議值
    getDietStandard(mid){
        let payload = {
                "mid":mid,
                "partner_id":this.partnerid
            }
            getDietStandard(payload).then(res => {
            if(res.data[0]){
                this.standard = res.data[0]
                this.mid = mid
            }else{
                this.mid = mid
            }
            })
     },
     //開啟建議值視窗
     OpenStandardPopup(){
        this.standardPopupActive = true 
     },
    //  寫建議值
     insertDietStandard(){
        this.partner_id = parseInt(this.partnerid)
        if(this.standard.id){
            let payload = {
            "mid":this.mid,
            "partner_id":this.partnerid,
            "data":{
                "whole_milk": this.standard.whole_milk,
                "low_fat_milk": this.standard.low_fat_milk,
                "skim_milk": this.standard.skim_milk,
                "fruit": this.standard.fruit,
                "vegetables": this.standard.vegetables,
                "staple": this.standard.staple,
                "high_fat_meat": this.standard.high_fat_meat,
                "medium_fat_meat": this.standard.medium_fat_meat,
                "low_fat_meat": this.standard.low_fat_meat,
                "medium_fat_legumes_product": this.standard. medium_fat_legumes_product,
                "low_fat_legumes_product": this.standard.low_fat_legumes_product,
                "grease": this.standard.grease,
            }    
            }
            insertDietStandard(payload).then(res => {
            if(res.data.status=="OK"){
                this.getDietStandard(this.mid)
                this.closeStandardPopup()
                this.notify('grey', this.$t('message.send_success'))
            }else{
                this.getDietStandard(this.mid)
                this.closeStandardPopup()
                this.notify('grey', this.$t('message.send_error'))
            }
            })
        }else{
            let payload = {
                "mid":this.mid,
                "partner_id":this.partnerid,
                "data":{
                    "whole_milk": this.whole_milk,
                    "low_fat_milk": this.low_fat_milk,
                    "skim_milk": this.skim_milk,
                    "fruit": this.fruit,
                    "vegetables": this.vegetables,
                    "staple": this.staple,
                    "high_fat_meat": this.high_fat_meat,
                    "medium_fat_meat": this.medium_fat_meat,
                    "low_fat_meat": this.low_fat_meat,
                    "medium_fat_legumes_product": this.medium_fat_legumes_product,
                    "low_fat_legumes_product": this.low_fat_legumes_product,
                    "grease": this.grease,
                        }    
            }
            console.log(payload)
            insertDietStandard(payload).then(res => {
            if(res.data.status=="OK"){
                this.getDietStandard(this.mid)
                this.closeStandardPopup()
                this.notify('grey', this.$t('message.send_success'))
            }else{
                this.getDietStandard(this.mid)
                this.closeStandardPopup()
                this.notify('grey', this.$t('message.send_error'))
            }
            })
        }
     },
    //  關閉建議值視窗
     closeStandardPopup(){
         this.whole_milk = 1
         this.low_fat_milk = 1
         this.skim_milk = 1 
         this.fruit = 1 
         this.staple = 1 
         this.vegetables = 1
         this.high_fat_meat = 1
         this.medium_fat_meat = 1
         this.low_fat_meat = 1
         this.medium_fat_legumes_product = 1
         this.low_fat_legumes_product = 1
         this.grease = 1
         this.mid = 0
         this.standardPopupActive =false
     },
    //  第幾餐中英文對照
     numberToWord(number){
        if(number+1==1){
            if(this.lang=='en'){
                return 'First Meal'
            }else{
                number=number+1
                return '第'+number+'餐'
            }
        }else if(number+1==2){
            if(this.lang=='en'){
                return 'Second Meal'
            }else{
                number=number+1
                return '第'+number+'餐'
            }
        }else if(number+1==3){
            if(this.lang=='en'){
                return 'Third Meal'
            }else{
                number=number+1
                return '第'+number+'餐'
            }
        }else if(number+1==4){
            if(this.lang=='en'){
                return 'Fourth Meal'
            }else{
                number=number+1
                return '第'+number+'餐'
            }
        }else if(number+1==5){
            if(this.lang=='en'){
                return 'Fifth Meal'
            }else{
                number=number+1
                return '第'+number+'餐'
            }
        }else if(number+1==6){
            if(this.lang=='en'){
                return 'Sixth Meal'
            }else{
                number=number+1
                return '第'+number+'餐'
            }
        }else if(number+1==7){
            if(this.lang=='en'){
                return 'Seventh Meal'
            }else{
                number=number+1
                return '第'+number+'餐'
            }
        }else if(number+1==8){
            if(this.lang=='en'){
                return 'Eighth Meal'
            }else{
                number=number+1
                return '第'+number+'餐'
            }
        }else if(number+1==9){
            if(this.lang=='en'){
                return 'Ninth Meal'
            }else{
                number=number+1
                return '第'+number+'餐'
            }
        }else if(number+1==10){
            if(this.lang=='en'){
                return 'Tenth Meal'
            }else{
                number=number+1
                return '第'+number+'餐'
            }
        }else{
            if(this.lang=='en'){
                number=number+1
                return 'Number'+number+'Meal'
            }else{
                number=number+1
                return '第'+number+'餐'
            }
        }
     }
    },
}
</script>