<template>
  <div>
    <!-- DICOM -->
    <vs-row v-show="type==2" v-if="dicomData.length!=0">
      <vs-col vs-lg="4" vs-sm="6" vs-xs="12" v-for="(item, index) in dicomData" :key="index">
        <div class="cd-card-group">
          <vs-card>
            <vs-row class="cd-card">
              <vs-col vs-type="flex" vs-justify="flex-end">
                <!-- <p>{{ item.StudyDate.substr(0,4) + '-' + item.StudyDate.substr(4,2) + '-' + item.StudyDate.substr(6,2) }}</p> -->
                <p>{{ item.createdata }}</p>
              </vs-col>
              <vs-divider />
              <vs-col>
                <img
                  src="/images/file/dicom.png"
                  alt="hangup"
                  :style="windowWidth <= 375 ? 'width: '+(windowWidth-155)+'px; height: '+(windowWidth-155)+'px;' : 'width: 210px; height: 220px;'"
                  style="display: grid;align-content: center;margin: 0 auto; "
                  @click.stop="viewDicom(index, item)"
                />
                <!-- <i class="el-icon-document el-icon-large" style="font-size: 13em;display: grid;align-content: center;margin: 0 auto; " @click="openReportPdfmobile(item)"></i> -->
              </vs-col>
              <vs-col>
                <p class="cd-p-normal">Modality : {{ item.modality }}</p>
                <p class="cd-p-normal">Remark : {{ item.remark }}</p>
              </vs-col>

              <p v-if="item.name" @click="viewDicom(index, item)">DicomName : {{ item.name }}</p>
              <vs-divider />
              <vs-col vs-type="flex" vs-justify="space-around">
                <!-- 詳細資訊 按鈕 -->
                <vs-button size="small" type="filled" @click="viewDicom(index, item)" :id="'viewDicomBtn_'+index">{{$t('DetailBtn')}}</vs-button>
                <!-- 會診醫生 按鈕 -->
                <vs-button size="small" type="filled" @click="consultdoctor(item.id)">{{ $t('ConsultBtn') }}</vs-button>
              </vs-col>
            </vs-row>
          </vs-card>
        </div>
      </vs-col>
    </vs-row>
    <!-- DICOM 沒資料 -->
    <div class="cd-nodata" v-show="type==2" v-else>
      {{$t('nodata')}}
    </div>
    <!-- 眼底鏡AI -->
    <vs-row v-show="type==13">
      <vs-col vs-lg="4" vs-sm="6" vs-xs="12" v-for="(item, index) in eyeData" :key="index">
        <div class="cd-card-group">
          <vs-card>
            <vs-row class="cd-card">
              <vs-col vs-type="flex" vs-justify="space-between">
                <h4>{{getName(item.position)}}</h4>
                <p>{{item.create_date}}</p>
              </vs-col>
              <vs-divider />
              <vs-col>
                <!-- No -->
                <vs-col vs-type="flex" vs-justify="flex-end"    vs-w="6"><p class="cd-p-normal">No</p></vs-col>
                <vs-col vs-type="flex" vs-justify="center"      vs-w="1"><p class="cd-p-normal">|</p></vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start"  vs-w="5"><p class="cd-p-normal">{{ item.ai_no }}</p></vs-col>
                <!-- Mild -->
                <vs-col vs-type="flex" vs-justify="flex-end"    vs-w="6"><p class="cd-p-normal">Mild</p></vs-col>
                <vs-col vs-type="flex" vs-justify="center"      vs-w="1"><p class="cd-p-normal">|</p></vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start"  vs-w="5"><p class="cd-p-normal">{{ item.ai_mild }}</p></vs-col>
                <!-- Moderate -->
                <vs-col vs-type="flex" vs-justify="flex-end"    vs-w="6"><p class="cd-p-normal">Moderate</p></vs-col>
                <vs-col vs-type="flex" vs-justify="center"      vs-w="1"><p class="cd-p-normal">|</p></vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start"  vs-w="5"><p class="cd-p-normal">{{ item.ai_moderate }}</p></vs-col>
                <!-- Severe -->
                <vs-col vs-type="flex" vs-justify="flex-end"    vs-w="6"><p class="cd-p-normal">Severe</p></vs-col>
                <vs-col vs-type="flex" vs-justify="center"      vs-w="1"><p class="cd-p-normal">|</p></vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start"  vs-w="5"><p class="cd-p-normal">{{ item.ai_severe }}</p></vs-col>
                <!-- Proliferative -->
                <vs-col vs-type="flex" vs-justify="flex-end"    vs-w="6"><p class="cd-p-normal">Proliferative</p></vs-col>
                <vs-col vs-type="flex" vs-justify="center"      vs-w="1"><p class="cd-p-normal">|</p></vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start"  vs-w="5"><p class="cd-p-normal">{{ item.ai_proliferative }}</p></vs-col>
                <!-- Referral Rate -->
                <vs-col vs-type="flex" vs-justify="flex-end"    vs-w="6"><p class="cd-p-normal" :style="{color: getRefColor(item.ai_dr)}">Referral Rate</p></vs-col>
                <vs-col vs-type="flex" vs-justify="center"      vs-w="1"><p class="cd-p-normal" :style="{color: getRefColor(item.ai_dr)}">|</p></vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start"  vs-w="5"><p class="cd-p-normal" :style="{color: getRefColor(item.ai_dr)}">{{ item.ai_dr }}</p></vs-col>
              </vs-col>
              <vs-divider />
              <vs-col vs-type="flex" vs-justify="space-around">
                <!-- 詳細資訊 按鈕 -->
                <vs-button size="small" @click="viewEye(index, item.id)" :id="'viewEve_'+index">{{$t('DetailBtn')}}</vs-button>
                <!-- 會診醫生 按鈕 -->
                <vs-button size="small" @click="consultdoctor(item.id)">{{$t('ConsultBtn')}}</vs-button>
              </vs-col>
            </vs-row>
          </vs-card>
        </div>
      </vs-col>
      <!-- 沒資料 -->
      <div class="cd-nodata" v-show="eyeData.length==0">
        {{$t('nodata')}}
      </div>
      <!-- 分頁 -->
      <vs-row v-show="eyeData.length!=0">
        <vs-col vs-type="flex" vs-justify="center">
          <vs-pagination :max="windowWidth<768?5:7" :total="Math.ceil(this.gettotal/6)" v-model="photopage" @change="getPhotoPage(photopage)"></vs-pagination>
        </vs-col>
      </vs-row>
    </vs-row>
    <!-- 心電心音 -->
    <vs-row v-show="type==12" >
      <vs-col vs-lg="4" vs-sm="6" vs-xs="12" v-for="(item, index) in stiData" :key="index">
        <div class="cd-card-group">
          <vs-card>
            <vs-row class="cd-card">
              <vs-col vs-type="flex" vs-justify="space-between">
                <h4>{{$t('Pulse')+item.HeartRate}}</h4>
                <p>{{item.Time}}</p>
              </vs-col>
              <vs-divider />
              <!-- 電活期 -->
              <vs-col class="cd-form-group mx-2">
                <vs-tooltip :text="$t('dicomdescription2')">
                  <h5>{{$t('dicomdescription3')}} | {{item.PercentEMAT}}</h5>
                  <span class="percent" :style="{width:S1(item.PercentEMAT)}"></span>
                  <span class="percent-left" :style="{width:S1(100-item.PercentEMAT)}"></span>
                </vs-tooltip>
              </vs-col>
              <!-- 縮縮不全指數 -->
              <vs-col class="cd-form-group mx-2">
                <vs-tooltip :text="$t('dicomdescription4')">
                  <h5>{{$t('dicomdescription5')}} | {{ item.SDI }}</h5>
                  <span class="box1"></span>
                  <span class="box4"></span>
                  <svg height="10" width="100%">
                    <path :d="S3(item.SDI)"/>
                  </svg>
                </vs-tooltip>
              </vs-col>
              <!-- 第三心音 -->
              <vs-col class="cd-form-group mx-2">
                <vs-tooltip :text="$t('dicomdescription6')">
                  <h5>{{$t('dicomdescription7')}} | {{item.S3Strength}}</h5>
                  <span class="box1"></span>
                  <span class="box4"></span>
                  <svg height="10" width="100%">
                    <path :d="S3(item.S3Strength)"/>
                  </svg>
                </vs-tooltip>
              </vs-col>
              <!-- 第四心音 -->
              <vs-col class="mx-2">
                <vs-tooltip :text="$t('dicomdescription8')">
                  <h5>{{$t('dicomdescription9')}} | {{item.S4Strength}}</h5>
                  <span class="box1"></span>
                  <span class="box4"></span>
                  <svg height="10" width="100%">
                    <path :d="S3(item.S4Strength)"/>
                  </svg>
                </vs-tooltip>
              </vs-col>
              <vs-divider />
              <!-- 會診醫生 按鈕 -->
              <vs-col vs-type="flex" vs-justify="space-around">
                <vs-button size="small" @click="consultdoctor(item.id, item.Time)">{{$t('ConsultBtn')}}</vs-button>
              </vs-col>
            </vs-row>
          </vs-card>
        </div>
      </vs-col>
      <!-- 沒資料 -->
      <div class="cd-nodata" v-show="stiData.length==0">
        {{$t('nodata')}}
      </div>
      <!-- 分頁 -->
      <vs-row v-show="stiData">
        <vs-col vs-type="flex" vs-justify="center">
          <vs-pagination :max="windowWidth<768?5:7" :total="Math.ceil(this.gettotal/6)" v-model="photopage" @change="getPhotoPage(photopage)"></vs-pagination>
        </vs-col>
      </vs-row>
    </vs-row>
    <!-- 邀請會診醫師 視窗 -->
    <consult-doctor :consultdoctorbutton="consultdoctorbutton"></consult-doctor>
    <!-- DICOM 視窗 -->
    <vs-popup fullscreen :title="$t('medicalReport3')" :active.sync="popupActive" style="margin:0px;padding:0px;overflow:hidden">
      <iframe :src="selecturl" frameborder="0" style="overflow:hidden;height:80vh;width:120%" height="100vh" width="100%"></iframe>
    </vs-popup>
    <!-- 眼底鏡 視窗 -->
    <vs-popup fullscreen :title="$t('FunduscopyAIReportTxt')" :active.sync="popupFunduscopyActive" style="margin:0px;padding:0px;overflow:hidden">
      <vs-row>
        <!-- 眼底鏡圖片 -->
        <vs-col class="cd-form-group">
          <p type="danger">{{$t('AIReportTxt')}}</p>
          <div v-viewer class="images clearfix" style="display: flex;">
            <img
            :id="'itri_image_'+index"
            class="ml-2 cursor-pointer cd-funduscopy"
            width="170"
            height="160"
            :src="'https://upload.curdoctor.com.tw/funduscopy/'+image.uploadimg"
            v-for="(image, index) in imageurl"
            :key="index"
            @click.prevent="syncMe()">
          </div>
        </vs-col>
        <!-- 左圖 -->
        <vs-col vs-w="6">
          <div class="cd-card-group">
            <vs-card fixedHeight>
              <ve-gauge :data="chartData" :settings="chartSettings" height="300px" width="450px"></ve-gauge>
            </vs-card>
          </div>
        </vs-col>
        <!-- 右圖 -->
        <vs-col vs-w="6">
          <div class="cd-card-group">
            <vs-card fixedHeight>
              <ve-bar :data="aiData" :settings="aichartSettings" height="300px" width="450px"></ve-bar>
            </vs-card>
          </div>
        </vs-col>
        <!-- 左圖備註 -->
        <vs-col vs-w="6">
          <p class="cd-p-small" v-for="(text, index) in funduscopyAI2Txt" :key="index">{{text}}</p>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
import { medicalFileSync } from '@/api/user'
import axios from 'axios'
import vSelect from 'vue-select'
import ConsultDoctor from '../../views/apps/emrcomponents/Consultdoctor'
export default {
  components: {
		vSelect,ConsultDoctor
	},
  props: {
    userPid: {
      type: String,
      required: true
    },
    tabName: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
  },
  watch: {
    // 醫療影像同步
    syncTriggle(v) {
      if(v=='closedicom'){
        this.popupActive = false
      }
      if(v=='closeFunduscopy'){
        this.popupFunduscopyActive = false
      }
      else if(v.substring(0,10)=='pageChange'){
        var stringValue = v.split('_')
        // 眼底鏡圖片
        if(stringValue[1]=='itri'){
          try{
            var intValue = parseInt(stringValue[2])
          }catch(e){
            console.log(e)
          }
          this.getPhotoPage(intValue,1)
        }
      }
    },
    // 關閉DICOM彈出視窗
    popupActive(v){
      if(!v){
        if(this.syncSwitch==true){
          medicalFileSync({qid: this.qid, trigger: 'closedicom'})
        }
      }
    },
    // 關閉眼底鏡彈出視窗
    popupFunduscopyActive(v){
      if(!v){
        if(this.syncSwitch==true){
          medicalFileSync({qid: this.qid, trigger: 'closeFunduscopy'})
        }
      }
    },
    // 換頁
    photopage(v){
      this.$store.dispatch('fetchEmrImage', {'spid': this.userPid, 'type': this.type,'page':v})
    },
  },
  data() {
    this.chartSettings = {
      dataType: {
        'speed': 'percent'
      },
      dataName: {
        'speed': this.$t('TransferTxt')
      },
      seriesMap: {
        'speed': {
          min: 0,
          max: 1,
          startAngle: 180,
          endAngle: 0,
          axisLine: {
            show: true,
            lineStyle: {
              color: [[0.2, '#006400'],[0.5, '#FFD700'],[0.8, '#FF8C00'],[1, '#DC143C']],
              width: 30
            }
          }
        }
      }
    }
    this.aichartSettings = {
      dimension: ['type'],
      metrics: ['value']
    }
    return {
      photopage:1,
      gettotal:null,
      heartRateList: [],
      referralcolor: 'green',
      popupActive:false,
      popupFunduscopyActive:false,
      selecturl: '',
      consultdoctorbutton:null,
      modality:null,
      imageurl: '',
      tempIndex: 0,
      tempId: 0,
      funduscopyAI2Txt: this.$t('FunduscopyAI2Txt').split(' || '),
      chartData: {
        columns: ['type', 'value'],
        rows: [{
          type: 'speed',
          value: 0
        }]
      },
      aiData: {
        columns: ['type', 'value'],
        rows: [
          { 'type': 'No', 'value': 0, 'itemStyle': {'color': 'blue'}, 'content': 'ABC' },
          { 'type': 'Mild', 'value': 0, 'itemStyle': {'color': 'yellow'}, 'content': 'QWE' },
          { 'type': 'Moderate', 'value': 0, 'itemStyle': {'color': 'yellow'}, 'content': '1234' },
          { 'type': 'Severe', 'value': 0, 'itemStyle': {'color': 'yellow'}, 'content': '' },
          { 'type': 'Proliferative', 'value': 0, 'itemStyle': {'color': 'yellow'}, 'content': '' }
        ]
      },
    }
  },
  computed: {
    syncSwitch() {
      return this.$store.getters.syncSwitch
    },
    syncTriggle: {
      get() {
        return this.$store.getters.syncTriggle
      },
      set(val) {
        this.$store.commit('SET_SYNC_TRIGGLE', val)
      }
    },
    dicomData() {
      console.log('dicom dicom')
      return this.$store.getters.userEMRDICOM
    },
    eyeData() {
      return this.$store.getters.userEMREye
    },
    stiData() {
      console.log('sti dicom')
      return this.$store.getters.userEMRSti
    },
    ectrocarDiogram() {
      return this.$store.getters.ectrocarDiogram
    },
    windowWidth() {
      return this.$store.getters.windowWidth
    },
    qid: {
      get() {
        return this.$store.getters.qid
      },
      set(val) {
        this.$store.commit('SET_QUEUE_ID', val)
      }
    },
  },
  mounted() {
    var _self = this
    _self.$vs.loading()
    let payload = {
      'spid': _self.userPid,
      'type': _self.type,
      'page': _self.photopage
    }
    _self.$store.dispatch('fetchEmrImage', payload).then((res) => {
      _self.gettotal = res.data.total
    }).catch((e) => { 
      console.log('send sms error ', e)
    }).then(() => {
      this.$vs.loading.close()
    })
  },
  methods: {
    // 眼底鏡換頁 (設立旗子以防止收到socket後，無限迴圈執行程式)
    getPhotoPage(page = 1, flag = 0) {
      var _self = this
      // if(_self.type==13){
        // 醫療影像同步
        if(this.syncSwitch==true && flag == 0){
          medicalFileSync({qid: this.qid, trigger: 'pageChange_itri_'+page})
          // this.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'pageChange_itri_'+page})
        }
      // }
        _self.photopage = page
        window.scrollTo(0,0);
    },
    // 數據處理 心電心音 電活期
    S1(a){
      return a+'%'
    },
    // 數據處理 心電心音 縮縮不全指數 & 第三心音 & 第四心音
    S3(point){
      return 'M '+point*16.9+' 0 L'+(point*17-5)+' 10 L '+(point*17+5)+' 10 Z'
    },
    // 開啟DICOM詳細資訊視窗
    viewDicom(index, item) {
      var _self = this
      _self.popupActive=true
      // _self.selecturl = 'https://doctorcare.ebmtech.com/html5/showImage.html?User=1&Password=1&PatientID='+item.uuid+'&Modality='+item.Modality+'&v='+(+new Date())
      _self.selecturl = 'https://ds-dev.curdoctor.com.tw/stone-webviewer/index.html?study='+item.studies+'&series='+item.series;
      if(_self.syncSwitch==true){
        medicalFileSync({qid: this.qid, trigger: 'viewDicomBtn_'+index})
      }
    },
    // 前往眼底鏡報告
    viewEye(index, id) {
      var _self = this
      _self.tempIndex = index
      _self.tempId = id
      _self.getImage(id)
      _self.getList(id)
      _self.popupFunduscopyActive=true
      if(_self.syncSwitch==true){
        medicalFileSync({qid: this.qid, trigger: 'viewEve_'+index})
      }
    },
    // 取眼底鏡圖片
    getImage(id) {
      let _self = this
      axios.get(process.env.VUE_APP_API_URL_HIS+'/api/viewfunduscopy/null/'+id).then(res => {
        if(res.data.status=='OK'){
          _self.imageurl = res.data.items
        }
      })
    },
    // 取圖表資料
    getList(id) {
      var _self = this
      _self.loading = true
      axios.get(process.env.VUE_APP_API_URL+'/admin/funduscopybyid/'+ id).then(function(res){
        if(res.data.status=='OK'){
          _self.chartData.rows.type='speed'
          _self.chartData.rows[0].value= res.data.result.ai_dr/100
          _self.aiData.rows[0].value = res.data.result.ai_no
          _self.aiData.rows[1].value= res.data.result.ai_mild
          _self.aiData.rows[2].value= res.data.result.ai_moderate
          _self.aiData.rows[3].value= res.data.result.ai_severe
          _self.aiData.rows[4].value= res.data.result.ai_proliferative
        }
      }).catch((error) => { 
        console.log(error)
        _self.loading = false
      }).then(() => {
        _self.loading = false
      })
    },
    // 取左眼右眼文字
    getName(type) {
      if(type==0){
        return this.$t('LeftEyeLabel')
      }else{
        return this.$t('RightEyeLabel')
      }
    },
    // 眼底鏡文字顏色
    getRefColor(rate) {
      var referralcolor = 'green'
      if(rate <= 20){
        referralcolor = 'green'
      }else if(rate > 20 && rate <=50){
        referralcolor = 'Indigo'
      }else if(rate > 51 && rate <=80){
        referralcolor = 'Orange'
      }else if(rate > 81 && rate <=100){
        referralcolor = 'red'
      }
      return referralcolor
    },
    // 開啟會診醫生視窗
    consultdoctor(typeid,date=''){
      let data = {
        tabName: this.tabName,
        typeid: typeid,
        spid: this.userPid,
        status: true,
      }
      if(this.type=="12") data.Time = date
      this.consultdoctorbutton = data
    },
  },
}
</script>
<style lang="scss">
	@import "vue-select/src/scss/vue-select.scss";
</style>
<style>
  .box1{
		width:50%;
		background-color:rgb(28, 186, 118);
		display:inline-block;
		height:5px;
	}
	.box2{
		width:25%;
		background-color:rgb(252, 186, 61);
		display:inline-block;
		height:5px;
	}
	.box3{
		width:25%;
		background-color:rgb(237, 85, 101);
		display:inline-block;
		height:5px;
	}
	.box4{
		width:50%;
		background-color:rgb(237, 85, 101);
		display:inline-block;
		height:5px;
	}
  .percent{
		background-color:rgb(28, 186, 118);
		display:inline-block;
		height:11px;
	}
	.percent-left{
		background-color:rgb(220,220,220);
		display:inline-block;
		height:10px;
	}
</style>