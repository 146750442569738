<template>
    <div>
        <!-- 看診  -->
        <vs-button :id="'enter-room-loading' + qid" :class="propClass" :style="getStyle()" :type="type" :color="color" :icon="icon" icon-pack="feather" :disabled="disabled" @click.stop="openLoadingContained">
            {{ $t('reservation.EnterRoom') }}
        </vs-button>
    </div>
</template>
<script>
import { call_p2p, getMidByPid } from '@/api/user'
export default {
    props: {
        propClass: {
            type: String,
            default: 'vs-con-loading__container cd-button-sm-2w-icon',
            required: false,
        },
        type: {
            type: String,
            default: 'filled',
            required: false,
        },
        color: {
            type: String,
            default: 'danger',
            required: false,
        },
        icon: {
            type: String,
            default: 'icon-log-in',
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        qid: {
            type: Number,
            required: true,
        },
        pid: {
            type: Number,
            required: true,
        },
        roomId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            phone_status: 0,
        }
    },
    computed: {
        mid() {
            return this.$store.state.mid
        },
        did() {
            return this.$store.getters.did
        },
        doctortype() {
            return this.$store.state.doctortype
        },
        device() {
            return this.$store.getters.device
        },
        partnerid() {
            return this.$store.getters.partner
        },
        dmid() {
            return this.$store.state.mid
        },
    },
    methods: {
        // 通話
        openLoadingContained() {
            this.phone_status = 1
            this.$vs.loading({
                background: 'danger',
                color: '#fff',
                container: '#enter-room-loading' + this.qid,
                scale: 0.45,
            })
            if (localStorage.getItem('is_p2p') == 0) {
                if (this.phone_status == 1) {
                    // 網頁版 先確認是否允許開啟鏡頭&麥克風
                    if (this.device == 3) {
                        navigator.mediaDevices
                            .getUserMedia({ video: true, audio: true })
                            .then((stream) => {
                                this.appointment()
                            })
                            .catch((error) => {
                                alert(this.$t('components.call.useMic'))
                            })
                    } else {
                        this.appointment()
                    }
                }
            } else {
                getMidByPid(this.pid).then((res) => {
                    let pmid = res.data.data.mid
                    this.call_p2p(0, pmid, '')
                })
            }
            setTimeout(() => {
                this.$vs.loading.close('#enter-room-loading' + this.qid + ' > .con-vs-loading')
                this.phone_status = 0
            }, 3000)
        },
        async call_p2p(is_doctor, pmid, dmid = 0) {
            let route = _.replace(_.lowerCase(_.split(this.$route.fullPath, '/', 2)[1]), ' ', '')
            var payload = {
                partner_id: this.partnerid,
                is_doctor: is_doctor,
                to_mid: pmid,
                src: route,
                queue_id: this.qid,
                ring: false,
                enter_room: true,
            }

            if (dmid > 0) {
                payload['doc_mid'] = dmid
                var mid = dmid
            } else {
                var mid = pmid
            }

            if (this.snParams) payload.sn_id = this.snParams.id
            await call_p2p(payload).then((res) => {
                if (res.data.status == 'OK') {
                    // $('#p2p').show();
                    this.$store.commit('SET_CALL_ID', res.data.callId)
                    this.$store.commit('SET_LOCAL_ID', 'curdr_' + this.partnerid + '_' + this.dmid + '_1')
                    this.$store.commit('SET_REMOTE_ID', 'curdr_' + this.partnerid + '_' + pmid + '_' + is_doctor)
                    this.$store.commit('SET_CALLING', true)
                    this.$store.commit('SET_ROOM_ID', res.data.room_Id)
                    this.$store.commit('SET_QUEUE_ID', res.data.queue_id)
                    remoteID = res.data.remote_Id
                    console.log('call.vue call_p2p function remoteID => ', remoteID)
                    this.$store.dispatch('updateDoctorStatus', 2000)
                    console.log(res.data.caller, 'caller')
                    console.log(res.data.remote_Id, 'remoteid')
                    p2p_call(false, res.data.room_Id, res.data.callId, res.data.remote_Id, false, res.data.caller, false, this.UUID)
                    localStorage.setItem('p2p_queueid', res.data.queue_id)
                    localStorage.setItem('p2p_tomid', this.dmid)
                    let send_data = {
                        queue_id: res.data.queue_id,
                    }
                    p2pCallData = send_data
                    this.$emit('closePopup')
                    // if(this.$router.currentRoute.fullPath!='/emr2/'+pid+'/'+res.data.queue_id){
                    // this.$router.push('/emr2/' + pid + '/' + res.data.queue_id )
                    // }
                } else {
                    console.log('call ERROR: ' + res.data.msg)
                }
            })
        },
        // 進入房間
        appointment() {
            var video = 'true'
            this.$store.commit('SET_CALLING', true)
            this.$store.dispatch('updateDoctorStatus', 2000)
            this.$store.dispatch('setRoomId', this.roomId)
            //網頁
            if (this.device == 3) {
                var videopath =
                    'https://mcu.curdoctor.com.tw:3004/?f=true&v=true' +
                    (window.location.hostname.indexOf('his-dev') > -1 ? '&test=true' : '') +
                    '&scheme_time=&room=' +
                    this.roomId +
                    '&queue_id=' +
                    this.qid +
                    '&token=' +
                    localStorage.getItem('doc_access_token') +
                    '&m=' +
                    this.doctortype +
                    '-' +
                    this.mid
                this.$store.dispatch('setVideoPath', videopath)
                this.$store.dispatch('toggleRightPanel', true)
            }
            // android
            else if (this.device == 2) {
                var jsonStr = JSON.stringify({
                    roomNumber: this.roomId,
                    queueID: this.qid,
                    doctorName: '',
                    did: this.did,
                    videoCall: video,
                    scheme_time: 0,
                })
                window.android.callRTC(jsonStr)
            }
            // ios
            else if (this.device == 1) {
                var jsonStr = JSON.stringify({
                    roomNumber: this.roomId,
                    queueID: this.qid.toString(),
                    patientName: '',
                    videoCall: video,
                    photo: '',
                    scheme_time: 0,
                })
                window.webkit.messageHandlers.CallPatient.postMessage(jsonStr)
            }
            if (this.$route.path != '/emr2/' + this.pid + '/' + this.qid) {
                this.$router.push('/emr2/' + this.pid + '/' + this.qid)
            }
        },
        // 取邊框顏色
        getStyle() {
            if (this.type != 'filled') return ''
            if (this.color == 'danger') {
                return 'border: 1px #e7441c solid;'
            } else if (this.color == 'success') {
                return 'border: 1px #104f2c solid;'
            } else if (this.color == 'primary') {
                return 'border: 1px #003f8e solid;'
            }
        },
    },
}
</script>
