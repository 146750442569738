import { Line, mixins } from 'vue-chartjs'
import * as moment from 'moment'
const { reactiveProp } = mixins
import axios from 'axios'

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null
    },
    time: {
      type: String,
      default: null
    },
    spid: {
      type: Number,
      default: null
    },
  },
  data: () => ({
    api_admin:'admin', //process.env.BASE_API1,
    options: {
      elements: {
        point:{
            radius: 0
        }
      },
      legend: {
        display:false,
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: false
      },
      scales: {
        xAxes: [{
          dispaly:false,
          gridLines : {
            display : true ,
            // color:"gray",
          } ,
          type: 'time',
          time: {
            // displayFormats: {
            //   quarter: 'day'
            // },
            // parser: 'MM HH:mm',
            // tooltipFormat: 'HH:mm.ss.SSS',
          },
          scaleLabel: {
            display: false,
            color:"red",
            labelString: 'Date'
          },
          ticks: {
            // stepSize:1000,
            maxRotation: 90,
            callback: function(value, index, values) {
              return ' ';
            }
          }
        }],
        yAxes: [{
          dislay:false,
          gridLines:{
            display:true
          },
          ticks: {
            // max: 30000000,
            // min: moment(this.time),
            // min:0,
            // maxTicksLimit:10,
            // stepSize:10 ,
            maxRotation: 90,
            callback: function(value, index, values) {
              return ' '   ;
            }
          }
        }],
      },
      plugins: {
        zoom: {
          pan: {
            enabled: true,
            mode: 'x',
            rangeMin: {
              // Format of min pan range depends on scale type
              x: null
            },
            rangeMax: {
                // Format of max pan range depends on scale type
                x: null
            },
            // On category scale, factor of pan v elocity
            // speed: 1,
            // Minimal pan distance required before actually applying pan
            // threshold: 10,
            onPan: function({chart}) { console.log(`I'm panning!!!`);
              if(chart.options.scales.xAxes[0].ticks.max==undefined){
                chart.options.scales.xAxes[0].ticks={
                  callback: function(value, index, values) {
                    return ' ';
                  }
                };
                chart.options.scales.yAxes[0].ticks={
                  callback: function(value, index, values) {
                    return ' ';
                  }
                };
                chart.options.plugins.zoom.pan.rangeMin.x=chart.data.labels[0];
                chart.options.plugins.zoom.pan.rangeMax.x=chart.data.labels[7499];
                chart.options.plugins.zoom.zoom.rangeMin.x=chart.data.labels[0];
                chart.options.plugins.zoom.zoom.rangeMax.x=chart.data.labels[7499];
                chart.resetZoom();
              }
            },
            // Function called once panning is completed
            onPanComplete: function({chart}) { console.log(`I was panned!!!`); }
          },
          zoom: {
            enabled: true,
            drag: false,
            mode: 'x',
            rangeMin: {
              // Format of min pan range depends on scale type
              // x: 3500
            },
            rangeMax: {
              // x: 10000
            },
            // speed: 0.1,
            // limit:{
            //   max:2,
            //   min:0.5
            // },
            // Function called while the user is zooming
            onZoom: function({chart}) {
              console.log(`I'm zooming!!!`);
              if(chart.options.scales.xAxes[0].ticks.max==undefined){
                chart.options.scales.xAxes[0].ticks={
                  callback: function(value, index, values) {
                    return ' ';
                  }
                };
                chart.options.scales.yAxes[0].ticks={
                  callback: function(value, index, values) {
                    return ' ';
                  }
                };
                chart.options.plugins.zoom.pan.rangeMin.x=chart.data.labels[0];
                chart.options.plugins.zoom.pan.rangeMax.x=chart.data.labels[7499];
                chart.options.plugins.zoom.zoom.rangeMin.x=chart.data.labels[0];
                chart.options.plugins.zoom.zoom.rangeMax.x=chart.data.labels[7499];
                chart.resetZoom();
              }
            },
            // Function called once zooming is completed
            onZoomComplete: function({chart}) { console.log(`I was zoomed!!!`); }
          }
        }
      }
    },
  }),
  created () {
    this.setchart();
  },
  methods: {
    x(){
      return this.time;
    },
    setchart() {
      let _this=this;
      var  ttt=new Date(_this.time);
      var rstime = _this.$moment(_this.time, "YYYY/MM/DD HH:mm:ss").valueOf()
      _this.$vs.loading()
      axios.get(process.env.VUE_APP_API_URL+'/'+_this.api_admin+'/amor_updatedata_ecg/'+_this.spid+'/'+rstime).then(function (response) {
        if(response.data.status=='Success'){
          var x = [];
          var y = [];
          for(var i=0;i<response.data.result.data.length;i++){
            x.push(moment(moment(moment(_this.time,"YYYY-MM-DD hh:mm:ss.SSS")+i*4).format("YYYY-MM-DD hh:mm:ss.SSS"),"YYYY-MM-DD HH:mm:ss.SSS"));
            y.push(a());
            function a(){
              if(response.data.result.data[i]>800){
                return 800;
              }else if(response.data.result.data[i]<-800){
                return -800;
              }else{
                return response.data.result.data[i];
              }
            }
          }
          _this.chartData.labels = x
          _this.chartData.datasets[0].data=y;
        }
        _this.options.scales.xAxes[0].ticks.min=moment(moment(ttt,"YYYY-MM-DD hh:mm:ss.SSS")+0).format("YYYY-MM-DD hh:mm:ss.SSS");
        _this.options.scales.xAxes[0].ticks.max=moment(moment(ttt,"YYYY-MM-DD hh:mm:ss.SSS")+3000).format("YYYY-MM-DD hh:mm:ss.SSS");
        _this.renderChart(_this.chartData, _this.options)
      }).catch((err) => {
        console.log('ecg chart err ', err)
      })
      .then(() => {
        _this.$vs.loading.close()
      })
    }
  }
}
