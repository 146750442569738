<template>
  <div>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center">
        <div class="vx-card sm:p-4 p-2">
          <!-- 資料載入中 -->
          <h5 v-if="loadingFlag" class="my-2 text-center">{{$t('loading')}}</h5>
          <!-- 資料 -->
          <vs-tabs class="tabs-shadow-none" id="profile-tabs2" v-if="!loadingFlag && bioDataCodenamearray && bioDataCodenamearray.length!=0">
            <vs-tab class="flex" :label="bioDataCodenamearrays" v-for="(bioDataCodenamearrays,tabIndex) in bioDataCodenamearray" :key="tabIndex">
              <vs-row>
                <!-- 日期篩選 下拉 -->
                <el-select class="sm:mr-4 mr-0 sm:w-auto w-full my-2" v-model="queryresdt" @change="filter_resdt(tabIndex)">
                  <el-option :label="$t('All')" value=""></el-option>
                  <el-option
                    v-for="(resdt,index) in resdt_result[tabIndex]"
                    :label="resdt.substring(0,4)+'-'+resdt.substring(4,6)+'-'+resdt.substring(6,8)+' '+resdt.substring(8,10)+':'+resdt.substring(10,12)"
                    :value="resdt"
                    :key="index">
                  </el-option>
                </el-select>
                <vs-col v-for="(resdt,index) in resdtData[tabIndex]" :key="index">
                  <!-- 日期 -->
                  <vs-divider class="mt-10 mb-0" border-style="solid" color="dark">
                    <strong>{{resdt.substring(0,4)+'-'+resdt.substring(4,6)+'-'+resdt.substring(6,8)+' '+resdt.substring(8,10)+':'+resdt.substring(10,12)}}</strong>
                  </vs-divider>
                  <!-- List -->
                  <vs-list>
                    <vs-list-item class="w-full" :title="item.testname+'('+item.c_testname+')'" v-for="(item, index) in allbiodatalist[tabIndex]" :key="index" v-show="(resdt==item.resdt)">
                      <vs-row>
                        <vs-col class="mt-2" vs-type="flex" vs-justify="flex-end">
                          <h4 :style="windowWidth>=768?'font-size:22px;':'font-size:16px;'" style="color: red;" v-if="item.resflag=='N' || item.resflag=='H'">{{ item.resflag }} , {{ item.result }}</h4>
                          <h4 :style="windowWidth>=768?'font-size:22px;':'font-size:16px;'" style="color: #000;" v-else-if="item.resflag=='*'">{{ item.resflag }} , {{ item.result }}</h4>
                        </vs-col>
                        <vs-col class="mb-2" vs-type="flex" vs-justify="flex-end">
                          <h6 v-if="item.resflag=='*'" style="color: #000;">{{ item.unit | allunit }} , </h6>
                          <h6 v-if="item.resflag=='*'" style="color: #000; font-weight:normal;">{{ item.normal_range | allnormal_range }}</h6>
                        </vs-col>
                      </vs-row>
                    </vs-list-item>
                  </vs-list>
                </vs-col>
              </vs-row>
            </vs-tab>
          </vs-tabs>
          <!-- 沒資料 -->
          <div class="cd-nodata" v-else-if="!loadingFlag">
            {{$t('nodata')}}
          </div>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
//所有檢驗數據tab
// import BioDataList from './biodatalist.vue'
export default {
  components: {
    // BioDataList,
  },
  props: {
    loadingFlag: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      resdt: [],
      resdtData: [],
      queryresdt:"",
    }
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth
    },
    bioDataCodenamearray(){
      return this.$store.getters.bioDataCodenamearray
    },
    bioData(){
      return this.$store.getters.bioData
    },
    allbiodatalist(){
      var allbiodatalist = []
      this.$store.getters.bioDataCodenamearray.forEach((v,i)=>{
        allbiodatalist[i] = this.$store.getters.bioData.filter(bioData=>bioData.chapcodename==v)
      })
      return allbiodatalist
    },
    resdt_result(){
      this.$store.getters.bioDataCodenamearray.forEach((v,i)=>{
        // 取得所有resdt,日期
        var resdtarray = []
        this.allbiodatalist[i].forEach((v)=>{
          resdtarray.push(v.resdt)
        })
        // 篩選重複的resdt filter
        this.resdt[i] = resdtarray.filter(function (element,index,arr) {
          return arr.indexOf(element)===index
        })
      })
      this.resdtData = _.clone(this.resdt)
      return this.resdt
    },
  },
  filters:{
    //unit轉換
    allunit(unit) {
      if(unit==""){
        return "--"
      }
      else{
        return unit
      }
    },
    //normal_range轉換
    allnormal_range(normal_range) {
      if(normal_range==""){
        return "--"
      }
      else{
        return normal_range
      }
    },
  },
  methods: {
    // 過濾日期查詢
    filter_resdt(tabIndex){
      // 搜尋結果【有日期】觸發
      if(this.queryresdt!=null){
        this.allbiodatalist[tabIndex] = this.bioData.filter(bioData=>bioData.chapcodename==this.bioDataCodenamearray[tabIndex])
        this.resdtData = _.clone(this.resdt)
        this.allbiodatalist[tabIndex] = this.allbiodatalist[tabIndex].filter(bioData=>bioData.resdt==this.queryresdt)
        this.resdtData[tabIndex] = []
        this.resdtData[tabIndex].push(this.queryresdt)
      }
      // 搜尋結果為【全部】觸發
      if(this.queryresdt==""){
        this.allbiodatalist[tabIndex] = this.bioData.filter(bioData=>bioData.chapcodename==this.bioDataCodenamearray[tabIndex])
        this.resdtData = _.clone(this.resdt)
      }
      // 搜尋結果等於【空陣列】觸發
      // if(this.allbiodatalist[tabIndex].length==0){
      //   this.filter_resdt(tabIndex)
      // }
    }
  },
}
</script>